import { DatePipe, ViewportScroller, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarOptions, DateSelectArg, DatesSetArg, DayCellContentArg, EventApi, EventClickArg, EventContentArg, EventMountArg, ToolbarInput } from '@fullcalendar/core';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { KeycloakService } from 'keycloak-angular';
import { CancellationDialogComponent } from 'src/app/shared/components/cancellation-dialog/cancellation-dialog.component';
import { EventStatusSelectorComponent } from 'src/app/shared/components/event-status-selector/event-status-selector.component';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { CustomNumberFormatDirective } from 'src/app/shared/directives/custom-number-format.directive';
import * as Enums from 'src/app/shared/enums';
import { ClinicMappingObject } from 'src/app/shared/interfaces/clinicMappingMapper';
import { EventSubmissionCoordinator } from 'src/app/shared/interfaces/event-submission-coordinator';
import { ProvincialCoordinator } from 'src/app/shared/interfaces/provincial-coordinator';
import { ListItemObject } from 'src/app/shared/interfaces/questionnaireMapper';
import { SiteCoordinator } from 'src/app/shared/interfaces/site-coordinator';
import { SAPSWellnessEventBookingForm } from 'src/app/shared/interfaces/wellness-event-booking';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { ClinicianService } from 'src/app/shared/services/clinician.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ListService } from 'src/app/shared/services/list.service';
import { SAPSWellnessDayEventBookingService } from 'src/app/shared/services/wellness-day-event-booking.service';
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator';
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator';

//import { INITIAL_EVENTS, createEventId } from './event-utils';



























interface ExistingEvents {
  id: string,
  title: string,
  start: string,
  end: string,
  eventStatusId: string
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class WellnessBookingComponent implements OnInit, AfterViewInit {
  upComingEvents: EventInput[] = [];
  bookingStage: string = '';
  pageYoffset = 0;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }
  @ViewChild(FormGroupDirective)
  formDirective!: FormGroupDirective;
  calendarVisible = true;
  calendarStartDate: Date = new Date();
  calendarMaxDate: Date = new Date();

  calendarOptions: CalendarOptions;
  eventTimeSelection: string = "event";
  shiftWorkerTimeSelection: string = "shiftWorker";
  currentEvents: EventApi[] = [];
  eventFormMain: FormGroup;
  //expansion panels
  @ViewChild('eventDetailsPanel') eventDetailsPanel: MatExpansionPanel;
  @ViewChild('eventVenuePanel') eventVenuePanel: MatExpansionPanel;
  @ViewChild('provincialCoordinatorPanel') provincialCoordinatorPanel: MatExpansionPanel;
  @ViewChild('siteCoordinatorPanel') siteCoordinatorPanel: MatExpansionPanel;
  expandProvincialCoordinator: boolean = false;
  expandEventVenue: boolean = false;
  expandEventDetails: boolean = false;
  p
  provinces: ListItemObject[];
  ranks: ListItemObject[];
  selectedProvinceTownsAndStations: ListItemObject[];
  serviceProviders: ClinicMappingObject[];
  serviceTypes: ListItemObject[];
  eventTypes: ListItemObject[];
  healthPractitionerTypes: ListItemObject[];
  eventStatuses: ListItemObject[];
  siteCoordinator: SiteCoordinator = new SiteCoordinator();
  provincialCoordinator: ProvincialCoordinator = new ProvincialCoordinator();
  submissionCoordinator: EventSubmissionCoordinator = new EventSubmissionCoordinator();
  allocatedEquipment: ListItemObject[];

  model: SAPSWellnessEventBookingForm = new SAPSWellnessEventBookingForm();
  ToggleForm: boolean = false;
  isSubmit: boolean = false;
  isLoading: boolean = false;
  showError: boolean = false;
  showStation: boolean = false;
  validEventTimeInputs: boolean = null;
  validShiftWorkerTimeInputs: boolean = null;
  showTown: boolean = false;
  showForm = false;
  siteCoordinatorValidation: boolean = false;
  invalidForm: boolean = false;
  validIDLength: boolean = true;
  filteredOptions: ListItemObject[] = [];
  allUpcomingWellnessEvents: SAPSWellnessEventBookingForm[] = [];
  showEvents: boolean = false;
  disableEventTimeInput: boolean = false;
  existingEvents: ExistingEvents[] = [];
  captureCoordinatorGUID: string = '';
  startTime: string;
  endTime: string;
  editMode: boolean = false;
  myBookings: boolean = false;

  filterOption: string[] = [];

  ngAfterViewInit(): void {
    this.setupEventHarnessObserver();
  }

  get cancellable(): boolean {
    if (!this.editEvent) { return false }
    return [Enums.EVENT_STATUS_IDS.CANCELLED, Enums.EVENT_STATUS_IDS.EXECUTED].indexOf(this.editEvent.eventStatusId) == -1;
  }

  // cancelBooking() {

  //   var _popup2 = this.dialog.open(PopupComponent, {
  //     width: '55%',
  //     data: {
  //       title: 'Would you like to cancel the booking?',
  //       lButTitle: 'No',
  //       rButTitle: 'Yes',
  //       lButNavUrl: 'back`',
  //       rButNavUrl: 'cancel',
  //     }
  //     //height: '50%'
  //   });
  //   _popup2.afterClosed().subscribe((data) => {
  //     if (data == 'cancel') {
  //       // this.editBooking(this.editEvent);
  //       this.eventFormMain.get('eventStatusId').setValue('377d722b-7400-4160-98e8-f7084ce2a7e6');
  //       this.onSubmit();
  //     }
  //   });



  // }

  eventDescription(stationDesc): string | null {
    let eventDesc: string;
    let splitStation: string[];
    if (stationDesc.indexOf(';') > -1) {
      splitStation = stationDesc.split(';');
      eventDesc = `${splitStation[0]} station, ${splitStation[1]}`;
    }
    else if (stationDesc.indexOf('|') > -1) {
      splitStation = stationDesc.split(' | ');
      eventDesc = `${splitStation[0]}, ${splitStation[1]}`;
    }
    return eventDesc;
  }

  get bookedEvents(): ExistingEvents[] {
    const matchingEvents: ExistingEvents[] = [];

    for (const event of this.events) {
      if (event.eventStatusId.toLowerCase() == 'ddbb6282-ecaa-455b-9357-42ce9ca44325') {
        matchingEvents.push(event);
      }
    }
    return matchingEvents;
  }

  get cancelledEvents(): ExistingEvents[] {
    const matchingEvents: ExistingEvents[] = [];

    for (const event of this.events) {
      if (event.eventStatusId.toLowerCase() == '377d722b-7400-4160-98e8-f7084ce2a7e6') {
        matchingEvents.push(event);
      }
    }

    return matchingEvents;
  }

  get plannedEvents(): ExistingEvents[] {
    const matchingEvents: ExistingEvents[] = [];
    for (const event of this.events) {
      if (event.eventStatusId.toLowerCase() == '256b8a8e-77fc-4e9d-ac3d-43302c442192') {
        matchingEvents.push(event);
      }
    }

    return matchingEvents;
  }

  get executedEvents(): ExistingEvents[] {
    const matchingEvents: ExistingEvents[] = [];

    for (const event of this.events) {
      if (event.eventStatusId.toLowerCase() == '120ad178-d3c5-4361-9547-484d8e6b3a35') {
        matchingEvents.push(event);
      }
    }

    return matchingEvents;
  }



  get events(): ExistingEvents[] {
    if (this.allUpcomingWellnessEvents.length > 0) {
      let ev = this.allUpcomingWellnessEvents;
      if (this.filterOption.length && this.filterOption.every(d => d != 'All')) {
        ev = ev.filter(d => this.filterOption.map(fo => fo.toLowerCase()).indexOf(d.eventStatusId?.toLowerCase()) > -1)
      }

      return ev.map((e: any) => ({
        id: e.id,
        title: `${e.provinceName} - ${this.eventDescription(e.stationName)}`,
        start: this.dateFormat(e.eventDate, e.eventStartTime),
        end: new Date(e.eventDate).getMonth() == 0 && new Date(e.eventDate).getDate() == 2 ? this.dateFormat(new Date(new Date(e.eventDate).setDate(new Date(e.eventDate).getDate() + 1)).toDateString(), e.eventEndTime) : this.dateFormat(e.eventDate, e.eventEndTime),
        eventStatusId: e.eventStatusId?.toLowerCase()
      }))
    }
    return [];
  }

  get headerToolbar(): ToolbarInput {
    return {
      left: 'next today prev',
      center: 'title',
      right: `${this.bookingStage == 'create' ? `editButton` : `searchButton`} ${this.bookingStage == 'create' ? `filterButton` : `filterButton`} dayGridMonth ${this.bookingStage == 'create' ? `timeGridDay` : `listWeek`}`
    };
  }


  calendarAPI: any;

  constructor(private http: HttpClient,
    private el: ElementRef<Element>,
    private snackBar: MatSnackBar,
    private wellnessDayEventBookingService: SAPSWellnessDayEventBookingService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    private listService: ListService,
    private scroll: ViewportScroller,
    private globalData: GlobalDataService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private clinicService: ClinicService,
    private keycloak: KeycloakService,
    private clinicianService: ClinicianService
  ) {
    this.globalData.userIdVal.subscribe(val => { this.captureCoordinatorGUID = val })
    this.createForm();
    this.bookingStage = this.router.getCurrentNavigation().extras.state.mode;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.listService.get(Enums.LISTS.ALLOCATED_EQUIPMENT).subscribe((data) => {
      this.allocatedEquipment = data.listItems;
    })
    this.listService.get(Enums.LISTS.PROVINCES).subscribe((data) => {
      this.provinces = data.listItems;
      this.provinces.sort((a, b) => a.description.localeCompare(b.description));
    })
    this.listService.get(Enums.LISTS.SAPS_RANKS).subscribe((data) => {
      this.ranks = data.listItems;
    })
    this.listService.get(Enums.LISTS.EVENT_ATMOSPHERES).subscribe((data) => {
      this.eventTypes = data.listItems;
    })
    this.listService.get(Enums.LISTS.SERVICE_TYPES).subscribe((data) => {
      this.serviceTypes = data.listItems;
    })
    this.listService.get(Enums.LISTS.EVENT_STATUSES).subscribe((data) => {
      this.eventStatuses = data.listItems;
    })
    this.listService.get(Enums.LISTS.HEALTH_PRACTITIONER_TYPES).subscribe((data) => {
      this.healthPractitionerTypes = data.listItems;
    })
    this.clinicService.getServiceProviders().subscribe((data) => {
      this.serviceProviders = data;
    })
    this.globalData.updateBackRoute('/wellness-event/saps/landing');
    this.showEvents = false;

    if (!this.captureCoordinatorGUID) {
      this.keycloak.loadUserProfile().then(userProfile => {
        let instance = this.keycloak.getKeycloakInstance();
        this.clinicianService.getClinicianByKeycloakId(instance.subject.toString()).subscribe(clinician => {
          this.captureCoordinatorGUID = clinician.id;
          this.wellnessDayEventBookingService.getAllWellnessDayEvents(this.captureCoordinatorGUID).subscribe((data) => {
            this.loadEvents(data);
          })
        })
      })
    }
    else {
      this.wellnessDayEventBookingService.getAllWellnessDayEvents(this.captureCoordinatorGUID).subscribe((data) => {

        this.loadEvents(data);
      })
    }
    //this.isLoading = false;
  }

  private eventHarnessObserver!: MutationObserver;
  @ViewChild('calendar') calendarEl!: ElementRef;

  get totalEvents() { return this.events.length; };

  populateData(data: any) {
    data.forEach(res => {
      let today = new Date();

      //if (new Date(res.eventDate) > new Date(today.setDate(today.getDate() - 21))) {
      if (this.bookingStage != 'create') {
        if ([Enums.EVENT_STATUS_IDS.PLANNED, Enums.EVENT_STATUS_IDS.EXECUTED].indexOf(res.eventStatusId) > -1) {
          this.allUpcomingWellnessEvents.push(res);
        }

      }
      else {
        this.allUpcomingWellnessEvents.push(res);
      }
      //}
    });

  }

  loadEvents(data: any) {

    this.populateData(data);;

    if (this.allUpcomingWellnessEvents.length != 0) {
      this.calendarOptions = {
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
        ],
        customButtons: {
          searchButton: {
            text: 'Search',
            hint: 'Search for event',
            click: () => this.searchWellnessEvents()
          },
          editButton: {
            text: 'Search',
            hint: 'Search for booking',
            click: () => this.editWellnessEvents()
          },
          filterButton: {
            text: 'Filter',
            hint: 'Filter by event Status',
            click: () => this.filterWellnessEvents()
          }
        },
        headerToolbar: this.headerToolbar,
        initialView: 'dayGridMonth',
        //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        weekends: true,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        eventContent: function (arg: EventContentArg) {
          let start = new DatePipe('en-US').transform(arg.event.start, 'hh:mm a');
          let end = new DatePipe('en-US').transform(arg.event.end, 'hh:mm a');

          let className = "";
          if (arg.event.extendedProps.eventStatusId) {
            switch (arg.event.extendedProps.eventStatusId.toUpperCase()) {
              case "DDBB6282-ECAA-455B-9357-42CE9CA44325": {
                className = 'event-booked';
                break;
              }
              case "256B8A8E-77FC-4E9D-AC3D-43302C442192": {
                className = 'event-planned';
                break;
              }
              case "120AD178-D3C5-4361-9547-484D8E6B3A35": {
                className = 'event-executed';
                break;
              }
              case "377D722B-7400-4160-98E8-F7084CE2A7E6": {
                className = 'event-cancelled';
                break;
              }
              default: {
                className = "";
                break;
              }
            }
          }

          let html = `
              <div class="fc-event-main ${className}">
                <div class="fc-event-time">${start} - ${end}</div>
                <div class="fc-event-title">${arg.event.title}</div>
              </div>
            `;




          return { html: html };
        },
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        events: this.events,
        validRange: {
          start: formatDate(this.calendarStartDate.setDate(this.calendarStartDate.getDate() - 200), 'yyyy-MM-dd', 'en').toString(),
          end: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'yyyy-MM-dd', 'en').toString()
        }
      };
    } else {
      this.calendarOptions = {
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
        ],
        customButtons: {
          filterButton: {
            text: 'Filter',
            hint: 'Filter by event status',
            click: () => this.filterWellnessEvents()
          },
          searchButton: {
            text: 'Search',
            hint: 'Search for event',
            click: () => this.searchWellnessEvents()
          },
          editButton: {
            text: 'Search',
            hint: 'Search for booking',
            click: () => this.editWellnessEvents()
          }
        },
        headerToolbar: this.headerToolbar,
        initialView: 'dayGridMonth',
        //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        weekends: true,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,

        eventContent: function (arg: EventContentArg) {
          let start = new DatePipe('en-US').transform(arg.event.start, 'hh:mm a');
          let end = new DatePipe('en-US').transform(arg.event.end, 'hh:mm a');

          let className = "";
          if (arg.event.extendedProps.eventStatusId) {
            switch (arg.event.extendedProps.eventStatusId.toUpperCase()) {
              case "DDBB6282-ECAA-455B-9357-42CE9CA44325": {
                className = 'event-booked';
                break;
              }
              case "256B8A8E-77FC-4E9D-AC3D-43302C442192": {
                className = 'event-planned';
                break;
              }
              case "120AD178-D3C5-4361-9547-484D8E6B3A35": {
                className = 'event-executed';
                break;
              }
              case "377D722B-7400-4160-98E8-F7084CE2A7E6": {
                className = 'event-cancelled';
                break;
              }
              default: {
                className = "";
                break;
              }
            }
            arg.event.setProp('classNames', [className]);
          }


          let html = `
              <div class="fc-event-main ${className}">
                <div class="fc-event-time">${start} - ${end}</div>
                <div class="fc-event-title">${arg.event.title}</div>
              </div>
            `;
          return { html: html };

        },
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        eventSources: [
          { events: this.bookedEvents },
          {
            events: this.cancelledEvents,
            color: 'black',     // an option!
            textColor: 'yellow' // an option!
          },
          {
            events: this.plannedEvents,
            color: 'blush',     // an option!
          },
          { events: this.executedEvents, color: '#a2d298' }
        ],
        validRange: {
          start: formatDate(this.calendarStartDate.setDate(this.calendarStartDate.getDate() - 200), 'yyyy-MM-dd', 'en').toString(),
          end: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'yyyy-MM-dd', 'en').toString()
        },
        /* you can update a remote database when these fire:
        eventAdd:
        },
        */
      };
    }
    this.isLoading = false;
    this.showEvents = true;
    this.setupEventHarnessObserver();
  }
  private setupEventHarnessObserver(): void {
    // Create a new MutationObserver
    this.eventHarnessObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((addedNode) => {
            if (addedNode instanceof HTMLElement && (addedNode.classList.contains('fc-daygrid-event-harness') || addedNode.classList.contains('fc-h-event'))) {
              // Handle the newly added .fc-daygrid-event-harness element
              addedNode.classList.remove('event-booked', 'event-planned', 'event-executed', 'event-cancelled');
              ['event-booked', 'event-planned', 'event-cancelled', 'event-executed'].forEach(d => {
                if (addedNode.children[0].children[0].classList.contains(d)) {
                  addedNode.classList.add(d);
                }
              });
            }
          });
        }
        if (mutation.type === 'attributes' && mutation.target instanceof HTMLElement && mutation.target.classList.contains('fc-event-main')) {
          const element = mutation.target;
          const grandad = element.parentElement.parentElement;
          grandad.classList.remove('event-booked', 'event-planned', 'event-executed', 'event-cancelled');

          // Check for changes in class names
          ['event-booked', 'event-planned', 'event-cancelled', 'event-executed'].forEach(d => {
            if (element.classList.contains(d)) {
              grandad.classList.add(d);
            }
          });
        }
        if (mutation.type === 'attributes' && mutation.target instanceof HTMLElement && mutation.target.classList.contains('fc-h-event')) {
          const element = mutation.target;
          element.classList.remove('event-booked', 'event-planned', 'event-executed', 'event-cancelled');

          // Check for changes in class names
          ['event-booked', 'event-planned', 'event-cancelled', 'event-executed'].forEach(d => {
            if (element.children[0].children[0].classList.contains(d)) {
              element.classList.add(d);
            }
          });
        }
      });
    });

    // Start observing changes in the container element (where FullCalendar events are rendered)
    this.eventHarnessObserver.observe(this.el.nativeElement, {
      childList: true, // Watch for changes in the child nodes
      subtree: true,   // Watch for changes in all descendant nodes
      attributes: true,
      attributeFilter: ['class']
    });
  }
  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9\s]/g, '');
  }
  refreshBookings(useFilter: boolean) {
    this.myBookings = useFilter;

    // this.calendarOptions.eventSources = [
    //   { events: this.bookedEvents },
    //   {
    //     events: this.cancelledEvents,
    //     color: 'black',     // an option!
    //     textColor: 'yellow' // an option!
    //   },
    //   {
    //     events: this.plannedEvents,
    //     color: 'black',     // an option!
    //     textColor: 'yellow' // an option!
    //   },
    //   { events: this.executedEvents, color: '#a2d298' }
    // ];

    this.calendarOptions.events = this.events;
    this.calendarOptions.headerToolbar = this.headerToolbar;
  }
  dateFormat(day: string, time: string): string {
    return new DatePipe('en-US').transform(new Date(`${formatDate(day, 'yyyy-MM-dd', 'en')}T${time}`), 'yyyy-MM-ddTHH:mm');
  }
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    if (this.bookingStage == 'create') {
      this.editMode = false;
      this.showForm = true;
      this.calendarAPI = selectInfo.view.calendar;
      this.showEvents = false;
      document.getElementById("eventForm").style.display = "block";

      if (selectInfo.allDay) {
        //month selection
        this.model.eventDate = new DatePipe('en-US').transform(selectInfo.startStr, "yyyy-MM-ddTh:mm:ss.sss'Z'");
        this.eventFormMain.get("date").setValue(formatDate(this.model.eventDate, 'yyyy-MM-dd', 'en'));
        this.eventFormMain.get("eventStartTime").patchValue("07:00:00");
        this.eventFormMain.get("eventEndTime").patchValue("17:00:00");
        this.eventFormMain.get("date").disable();
      } else {
        this.disableEventTimeInput = true;
        this.startTime = new DatePipe('en-US').transform(selectInfo.startStr, "HH:mm");
        this.endTime = new DatePipe('en-US').transform(selectInfo.endStr, "HH:mm");
        //day + time selection
        this.datePipe.transform(selectInfo.startStr, 'yyyy-MM-dd');
        this.model.eventDate = new DatePipe('en-US').transform(selectInfo.startStr, "yyyy-MM-ddTHH:mm:ss.sss'Z'");
        this.eventFormMain.get("date").patchValue(formatDate(selectInfo.startStr, 'yyyy-MM-dd', 'en'));
        this.eventFormMain.get("eventStartTime").patchValue(this.startTime);
        this.eventFormMain.get("eventEndTime").patchValue(this.endTime);
        this.eventFormMain.get("eventStartTime").disable();
        this.eventFormMain.get("eventEndTime").disable();
      }
      this.scroll.scrollToPosition([0, 0]);
    }

  }
  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }
  searchWellnessEvents() {
    var _temp = this.dialog.open(PopupComponent, {
      width: '55%',
      data: {
        title: 'Register members',
        rButTitle: 'Continue',
        lButTitle: 'Back to calendar',
        lButNavUrl: 'calendar',
        mainData: this.allUpcomingWellnessEvents,//try the events listed under the person
        eventSearch: true,
      }
    })
    _temp.afterClosed().subscribe(data => {
      if (data.selectedSearchDropdownID) {
        this.router.navigate(['../registration', data.selectedSearchDropdownID, data.selectedSearchDropdownName], { relativeTo: this.route });
      }
    })
  }
  // Your calling component

  filterWellnessEvents() {
    const dialogRef = this.dialog.open(EventStatusSelectorComponent, {
      width: '55%',
    });

    dialogRef.componentInstance.statusSelected.subscribe((selectedStatus: string[]) => {
      // Use the selectedStatus in your logic
      this.filterOption = selectedStatus;
      this.refreshBookings(true)


      dialogRef.close(selectedStatus);
      // Perform additional logic or method calls using the selectedStatus
    });

    dialogRef.afterClosed().subscribe(() => {
      // Handle dialog closure if needed
    });
  }



  editWellnessEvents() {
    var _temp = this.dialog.open(PopupComponent, {
      width: '55%',
      data: {
        title: 'Edit booking',
        rButTitle: 'Continue',
        lButTitle: 'Back to calendar',
        lButNavUrl: 'calendar',
        mainData: this.allUpcomingWellnessEvents,//this should be returning filtered events
        eventSearch: true,
        eventEdit: true
      }
    })
    _temp.afterClosed().subscribe(data => {
      if (data && data.id) {
        this.editBooking(data);
        this.showEvents = false;
        document.getElementById("eventForm").style.display = "block";
      }
    })
  }
  //reload events after the filter
  editEvent: any;

  private editBooking(data: any) {
    this.editMode = true;
    this.isLoading = true;
    this.editEvent = data;
    [
      "id",
      "date",
      "eventStartTime",
      "eventEndTime",
      "eventStatusId",
      "eventProvince.provinceID",
      "eventProvince.provinceName",
      "eventTownAndStationID",
      "eventTownAndStaionName",
      "serviceType.serviceTypeID",
      "serviceType.serviceTypeName",
      "shiftWorkersStartTime",
      "shiftWorkersEndTime",
      "address",
      "specialLandmarks",
      "employeeDetails.amountOfEmployeesOnSite",
      "employeeDetails.amountOfExpectedEventParticipants",
      "eventAtmosphere.atmosphereID",
      "eventAtmosphere.atmosphereType",
      "eventAtmosphere.venueOpenPlan",
      "eventAtmosphere.privateRoomsAvailable",
      "eventAtmosphere.numberOfGazebosRequired",
      "isGazebosRequisitioned",
      "specialRequirements",
      "venueRestrictions",
      "dropOffFacilityDetails",
      "provincialCoordinatorID",
      "provincialCoordinator.provincialCoordinatorFirstName",
      "provincialCoordinator.provincialCoordinatorSurname",
      "provincialCoordinator.provincialCoordinatorEmail",
      "provincialCoordinator.provincialCoordinatorCellphone",
      "provincialCoordinator.provincialCoordinatorSignature",
      "provincialCoordinator.provincialCoordinatorTelephone",
      "provincialCoordinator.provincialCoordinatorRank.provincialCoordinatorRankID",
      "provincialCoordinator.provincialCoordinatorRank.provincialCoordinatorRankName",
      "siteCoordinator.siteCoordinatorFirstName",
      "siteCoordinator.siteCoordinatorSurname",
      "siteCoordinator.siteCoordinatorTelephone",
      "siteCoordinator.siteCoordinatorCellphone",
      "siteCoordinator.siteCoordinatorEmail",
      "siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID",
      "siteCoordinator.siteCoordinatorRank.siteCoordinatorRankName",
      "eventPlanning.serviceProviderId",
      "eventPlanning.serviceProviderName",
      "eventPlanning.healthPractitionerTypeId",
      "eventPlanning.numberOfHealthPractitioners",
      "eventPlanning.allocatedEquipment",
      "eventPlanning.numberOfHealthPractitioners",
      "eventPlanning.marketingCampaignDate",
      "eventPlanning.strikeDownTimes",
      "eventPlanning.strikeDownHours",
      "eventPlanning.strikeDownMinutes",
      "eventPlanning.setUpTimes",
      "eventPlanning.setUpHours",
      "eventPlanning.setUpMinutes"
    ].forEach(d =>
      ([Enums.EVENT_STATUS_IDS.CANCELLED, Enums.EVENT_STATUS_IDS.EXECUTED].indexOf(data.eventStatusId) > -1) ? (this.eventFormMain.get(d).disable()) : (this.eventFormMain.get(d).enable()));
    this.showEvents = false;
    document.getElementById("eventForm").style.display = "block";
    this.eventFormMain.get("id").setValue(data.id);
    this.eventFormMain.get("date").setValue(formatDate(data.eventDate, 'yyyy-MM-dd', 'en'));
    if ([Enums.EVENT_STATUS_IDS.CANCELLED, Enums.EVENT_STATUS_IDS.EXECUTED].indexOf(data.eventStatusId) == -1) {
      this.eventFormMain.get("date").enable();
    }
    this.eventFormMain.get("eventStartTime").setValue(data.eventStartTime);
    this.eventFormMain.get("eventEndTime").setValue(data.eventEndTime);
    this.eventFormMain.get('eventStatusId').setValue(data.eventStatusId || this.eventStatuses[0].id);
    this.showTown = true;
    this.eventFormMain.get("eventProvince.provinceID").setValue(data.provinceId);
    this.eventFormMain.get("eventProvince.provinceName").setValue(data.provinceName);
    this.onSelectedProvince(data.provinceId);
    this.eventFormMain.get("eventTownAndStationID").setValue(data.stationId);
    this.eventFormMain.get("eventTownAndStaionName").setValue(data.stationName);
    this.eventFormMain.get("serviceType.serviceTypeID").setValue(data.serviceTypeId);
    this.eventFormMain.get("serviceType.serviceTypeName").setValue(null);
    this.eventFormMain.get("shiftWorkersStartTime").setValue(data.shiftWorkerStartTime);
    this.eventFormMain.get("shiftWorkersEndTime").setValue(data.shiftWorkerEndTime);
    this.eventFormMain.get("address").setValue(data.address);
    this.eventFormMain.get("specialLandmarks").setValue(data.specialLandmarks);
    this.eventFormMain.get("employeeDetails.amountOfEmployeesOnSite").setValue(data.amountOfEmployeesOnSite);
    this.eventFormMain.get("employeeDetails.amountOfExpectedEventParticipants").setValue(data.amountOfExpectedParticipants);
    this.eventFormMain.get("eventAtmosphere.atmosphereID").setValue(data.eventAtmosphereId);
    this.eventFormMain.get("eventAtmosphere.atmosphereType").setValue(null);
    this.eventFormMain.get("eventAtmosphere.venueOpenPlan").setValue(data.isVenueOpenPlan);
    this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").setValue(data.amountOfPrivateRoomsAvailable);
    this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(data.amountOfGazebos);
    this.eventFormMain.get("isGazebosRequisitioned").setValue(data.areGazebosRequisitioned);
    this.eventFormMain.get("specialRequirements").setValue(data.specialRequirements);
    this.eventFormMain.get("venueRestrictions").setValue(data.venueRestrictions);
    this.eventFormMain.get("dropOffFacilityDetails").setValue(data.dropOffFacilityDetails);
    this.eventFormMain.get("provincialCoordinatorID").setValue(data.provincialCoordinatorIdNumber);


    let prov = data.provincialCoordinatorFullName.split(" ");
    //first entry in array will always be first name
    if (prov.length == 2) {
      this.eventFormMain.get("provincialCoordinator.provincialCoordinatorFirstName").setValue(prov[0]);
      this.eventFormMain.get("provincialCoordinator.provincialCoordinatorSurname").setValue(prov[1]);
    } else if (prov.length > 2) {
      let provSur = "";
      this.eventFormMain.get("provincialCoordinator.provincialCoordinatorFirstName").setValue(prov[0]);
      prov.forEach(element => {
        if (element != prov[0]) {
          provSur += `${element} `
        }
      });
      this.eventFormMain.get("provincialCoordinator.provincialCoordinatorSurname").setValue(provSur.toString().trim());
    }
    this.eventFormMain.get("provincialCoordinator.provincialCoordinatorEmail").setValue(data.provincialCoordinatorEmail);
    this.eventFormMain.get("provincialCoordinator.provincialCoordinatorCellphone").setValue(data.provincialCoordinatorCellphone);
    this.eventFormMain.get("provincialCoordinator.provincialCoordinatorSignature").setValue(data.provincialCoordinatorSignatureHardCopy);
    this.eventFormMain.get("provincialCoordinator.provincialCoordinatorTelephone").setValue(data.provincialCoordinatorTelephone);
    this.eventFormMain.get("provincialCoordinator.provincialCoordinatorRank.provincialCoordinatorRankID").setValue(data.provincialCoordinatorRankId);
    this.eventFormMain.get("provincialCoordinator.provincialCoordinatorRank.provincialCoordinatorRankName").setValue(null);
    if ((data.siteCoordinatorFullName && data.siteCoordinatorEmail && (data.siteCoordinatorTelephone || data.siteCoordinatorCellphone) && data.siteCoordinatorRankId) != null) {
      let site = data.siteCoordinatorFullName.split(" ");
      if (site.length == 2) {
        this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setValue(site[0]);
        this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setValue(site[1]);
      } else if (site.length > 2) {
        let siteSur = "";
        this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setValue(site[0]);
        site.forEach(element => {
          if (element != site[0]) {
            siteSur += `${element} `
          }
        });
        this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setValue(siteSur.toString().trim());
      }
      this.eventFormMain.get("siteCoordinator.siteCoordinatorTelephone").setValue(data.siteCoordinatorTelephone);
      this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").setValue(data.siteCoordinatorCellphone);
      this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").setValue(data.siteCoordinatorEmail);
      this.eventFormMain.get("siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID").setValue(data.siteCoordinatorRankId);
      this.eventFormMain.get("siteCoordinator.siteCoordinatorRank.siteCoordinatorRankName").setValue(null);
    }
    if (this.eventFormMain.get('id').value) {
      this.eventFormMain.get("eventPlanning.serviceProviderId").setValue(data.serviceProviderId);
      this.eventFormMain.get("eventPlanning.serviceProviderName").setValue(data.serviceProviderName);
      this.onSelectedServiceProvider(data.serviceProviderId);
      this.eventFormMain.get('eventPlanning.healthPractitionerTypeId').setValue(data.healthPractitionerTypeId);
      const allocatedEquipment = data.allocatedEquipment || [];
      this.eventFormMain.get('eventPlanning.allocatedEquipment').setValue(allocatedEquipment);
      this.eventFormMain.get('eventPlanning.numberOfHealthPractitioners').setValue(data.numberOfHealthPractitioners || null);
      this.eventFormMain.get('eventPlanning.marketingCampaignDate').setValue(formatDate(data.marketingCampaignDate, 'yyyy-MM-dd', 'en'));
      this.eventFormMain.get('eventPlanning.setUpHours').setValue((data.setUpTimes ? data.setUpTimes.split(':')[0] : "01"));
      this.eventFormMain.get('eventPlanning.setUpMinutes').setValue((data.setUpTimes ? data.setUpTimes.split(':')[1] : "00"));
      this.eventFormMain.get('eventPlanning.strikeDownHours').setValue((data.strikeDownTimes ? data.strikeDownTimes.split(':')[0] : "01"));
      this.eventFormMain.get('eventPlanning.strikeDownMinutes').setValue((data.strikeDownTimes ? data.strikeDownTimes.split(':')[1] : "00"));
    }
    this.isLoading = false;
  }

  get eventSetUpTimes(): string {
    return (this.eventFormMain.get('eventPlanning.setUpHours').value || "00") + ':' + (this.eventFormMain.get('eventPlanning.setUpMinutes').value || "00");
  }

  get eventStrikeDownTimes(): string {
    return (this.eventFormMain.get('eventPlanning.strikeDownHours').value || "00") + ':' + (this.eventFormMain.get('eventPlanning.strikeDownMinutes').value || "00");
  }

  get eventStatusId(): string {
    return this.eventFormMain.get('eventStatusId').value;
  }

  get eventDates(): Date {
    return this.eventFormMain.get('date').value;
  }

  get eventStartTime(): string {
    return this.eventFormMain.get('eventStartTime').value;
  }

  get eventEndTime(): string {
    return this.eventFormMain.get('eventEndTime').value;
  }

  cancelBooking(): void {
    const dialogRef = this.dialog.open(CancellationDialogComponent, {
      width: '55%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.eventFormMain.get('eventStatusId').setValue('377d722b-7400-4160-98e8-f7084ce2a7e6');
        this.eventFormMain.get('cancellationReasonId').setValue(result.cancellationReason);
        this.eventFormMain.get('cancelledBy').setValue(result.cancelledBy);
        this.onSubmit();
      }
      console.log('The dialog was closed', result);
      // Here you can handle the result, e.g., send it to the server or use it in your component
    });
  }



  backToCalendar(refresh: boolean = false): void {
    if (refresh) {
      this.isLoading = true; //below loads the events based on the user's details
      this.wellnessDayEventBookingService.getAllWellnessDayEvents(this.captureCoordinatorGUID).subscribe(events => {
        this.allUpcomingWellnessEvents = [];
        if (this.bookingStage != 'create') {
          events = events.filter(d => [Enums.EVENT_STATUS_IDS.EXECUTED, Enums.EVENT_STATUS_IDS.PLANNED].indexOf(d.eventStatusId) > -1);
        }
        events.forEach(e => this.allUpcomingWellnessEvents.push(e));
        this.refreshBookings(this.myBookings);
        this.isLoading = false;
      });
    }
    else {
      this.isLoading = false;
    }
    document.getElementById("eventForm").style.display = "none";
    this.showEvents = true;
    // this.calendarAPI.unselect();
    this.eventFormMain.reset();
    this.formDirective.resetForm();
    this.clearSiteCoordinatorEntry();
    this.model = new SAPSWellnessEventBookingForm();
    this.invalidForm = false;
    // this.siteCoordinatorValidation = false;
    this.resetPanels();
  }
  handleEventClick(clickInfo: EventClickArg) {
    if (this.bookingStage == 'create') {
      this.editBooking(this.allUpcomingWellnessEvents.find(d => d.id == clickInfo.event.id));
    }
    else {
      this.globalData.updateBookingEventId(clickInfo.event.id);
      this.router.navigate(['../registration', clickInfo.event.id, clickInfo.event.title], { relativeTo: this.route });
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
    this.changeDetector.detectChanges();
  }
  onSubmit() {
    if (!this.eventFormMain.get('eventStatusId').value) {
      this.eventFormMain.get('eventStatusId').setValue('DDBB6282-ECAA-455B-9357-42CE9CA44325');
    }
    var data = this.eventFormMain.value;

    this.resetPanels();
    if (this.eventFormMain.valid) {
      this.invalidForm = false;
      this.model.id = this.editMode ? data.id : "00000000-0000-0000-0000-000000000000";
      if (this.editMode) {
        this.model.eventDate = data.date;
      }
      this.model.provinceID = data.eventProvince.provinceID;


      this.model.stationID = data.eventTownAndStationID;

      this.model.serviceTypeID = data.serviceType.serviceTypeID;

      this.model.eventStartTime = this.editMode ? data.eventStartTime : (this.startTime || data.eventStartTime);
      this.model.eventStatusId = data.eventStatusId || this.eventStatuses[0].id;
      this.model.eventEndTime = this.editMode ? data.eventEndTime : (this.endTime || data.eventEndTime);

      this.model.shiftWorkerStartTime = data.shiftWorkersStartTime;
      this.model.shiftWorkerEndTime = data.shiftWorkersEndTime;
      this.model.address = data.address;
      this.model.specialLandmarks = data.specialLandmarks;
      this.model.amountOfEmployeesOnSite = data.employeeDetails.amountOfEmployeesOnSite
      this.model.amountOfExpectedParticipants = data.employeeDetails.amountOfExpectedEventParticipants
      //Event Atmosphere
      this.model.eventAtmosphereID = data.eventAtmosphere.atmosphereID;

      this.model.amountOfPrivateRoomsAvailable = data.eventAtmosphere.privateRoomsAvailable;
      this.model.isVenueOpenPlan = data.eventAtmosphere.venueOpenPlan;
      this.model.areGazebosRequisitioned = data.eventAtmosphere.numberOfGazebosRequired > 0;
      this.model.amountOfGazebos = this.model.areGazebosRequisitioned ? data.eventAtmosphere.numberOfGazebosRequired : 0;
      this.model.specialRequirements = data.specialRequirements;
      this.model.venueRestrictions = data.venueRestrictions;
      this.model.dropOffFacilityDetails = data.dropOffFacilityDetails;

      //provincial coordinator submit --> will change for MVP 2
      this.model.provincialCoordinatorIdNumber = data.provincialCoordinatorID;
      this.model.provincialCoordinatorFULLNAME = `${data.provincialCoordinator.provincialCoordinatorFirstName.toString().trim()} ${data.provincialCoordinator.provincialCoordinatorSurname.toString().trim()}`;
      this.model.provincialCoordinatorRANKID = data.provincialCoordinator.provincialCoordinatorRank.provincialCoordinatorRankID;
      this.model.provincialCoordinatorTELEPHONE = data.provincialCoordinator.provincialCoordinatorTelephone;
      this.model.provincialCoordinatorCELLPHONE = data.provincialCoordinator.provincialCoordinatorCellphone;
      this.model.provincialCoordinatorEMAIL = data.provincialCoordinator.provincialCoordinatorEmail;
      this.model.provincialCoordinatorSignatureHardCopy = data.provincialCoordinator.provincialCoordinatorSignature;
      this.model.provincialCoordinatorSIGNATURE = null;
      //Site Coordinator
      if ((data.siteCoordinator.siteCoordinatorFirstName && data.siteCoordinator.siteCoordinatorSurname) != null) {
        this.model.siteCoordinatorFullName = `${data.siteCoordinator.siteCoordinatorFirstName.toString().trim()} ${data.siteCoordinator.siteCoordinatorSurname.toString().trim()}`;
      } else {
        this.model.siteCoordinatorFullName = null;
      }
      this.model.siteCoordinatorRankId = data.siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID;
      this.model.siteCoordinatorTelephone = data.siteCoordinator.siteCoordinatorTelephone;
      this.model.siteCoordinatorCellphone = data.siteCoordinator.siteCoordinatorCellphone;
      this.model.siteCoordinatorEmail = data.siteCoordinator.siteCoordinatorEmail;
      this.model.eventSubmissionCoordinatorId = this.model.eventSubmissionCoordinatorId || this.captureCoordinatorGUID;

      this.model.cancelledBy = data.cancelledBy;
      this.model.cancellationReasonId = data.cancellationReasonId;

      this.model.allocatedEquipment = data.eventPlanning.allocatedEquipment;
      this.model.healthPractitionerTypeId = data.eventPlanning.healthPractitionerTypeId;
      this.model.numberOfHealthPractitioners = data.eventPlanning.numberOfHealthPractitioners;
      this.model.marketingCampaignDate = data.eventPlanning.marketingCampaignDate;
      this.model.setUpTimes = data.eventPlanning.setUpHours && data.eventPlanning.setUpMinutes ? data.eventPlanning.setUpHours + ":" + data.eventPlanning.setUpMinutes : null;
      this.model.strikeDownTimes = data.eventPlanning.strikeDownHours && data.eventPlanning.strikeDownMinutes ? data.eventPlanning.strikeDownHours + ":" + data.eventPlanning.strikeDownMinutes : null;
      this.model.serviceProviderId = data.eventPlanning.serviceProviderId;


      const headers = { 'Content-Type': 'application/json' };
      this.isLoading = true;
      let uriPath = this.editMode ? Enums.API_PATHS.UPDATE_WELLNESS_DAY_EVENT_BOOKING : Enums.API_PATHS.CREATE_WELLNESS_DAY_EVENT_BOOKING;
      this.http.post<any>(Enums.API_PATH + uriPath, this.model, { headers }).subscribe({
        next: data => {
          let snackBar = this.snackBar.open('Saved', null, { duration: 3000, panelClass: 'app-notification-success' });
          snackBar.afterDismissed().subscribe(() => {
            //get the model out of this.allUpcomingWellnessEvents with id == data.id

            let index = this.allUpcomingWellnessEvents.findIndex(d => d.id == data.id);
            // remove index from this.allupcomingwellnessevents

            this.allUpcomingWellnessEvents.splice(index, 1);
            this.backToCalendar(true);

            setTimeout(() => this.allUpcomingWellnessEvents.push(data), 2000);
            // this.wellnessDayEventBookingService.getAllWellnessDayEvents(this.captureCoordinatorGUID).subscribe((data) => {
            //   //empty this.allUpcomingWellnessEvents
            //   this.allUpcomingWellnessEvents = [];

            //   this.populateData(data);
            // })
          });
        },
        error: error => {
          this.isLoading = false;
          let snackBar = this.snackBar.open('Submission Error', null, { duration: 3000, panelClass: 'app-notification-error' })
          snackBar.afterDismissed().subscribe(() => this.backToCalendar());
        }
      });
    } else {
      this.expandInvalidFieldsPanel(this.eventFormMain)
      this.invalidForm = true;
    }
  }

  expandInvalidFieldsPanel(form: any) {
    if (((form.get('date').status == "DISABLED" || form.get('date').invalid == true)) || ((form.get('shiftWorkersEndTime').invalid || form.get('shiftWorkersStartTime').invalid || form.get('eventEndTime').invalid || form.get('eventStartTime').invalid || form.get('eventProvince.provinceID').invalid || form.get('eventTownAndStationID').invalid || form.get('serviceType.serviceTypeID').invalid || form.get('specialLandmarks').invalid || form.get('employeeDetails.amountOfExpectedEventParticipants').invalid || form.get('employeeDetails.amountOfEmployeesOnSite').invalid || form.get('address').invalid) == true)) {
      this.eventDetailsPanel.open();
      this.expandEventDetails = true;
    }
    if (((form.get('dropOffFacilityDetails').status) == "INVALID" || ((form.get('eventAtmosphere.privateRoomsAvailable').invalid || form.get('eventAtmosphere.venueOpenPlan').invalid || form.get('eventAtmosphere.atmosphereID').invalid || form.get('eventAtmosphere.numberOfGazebosRequired').invalid) == true)) && !this.expandEventDetails) {
      this.eventVenuePanel.open();
      this.expandEventVenue = true;
    }
    if (((form.get('provincialCoordinator.provincialCoordinatorSignature').status) == "INVALID" || ((form.get('provincialCoordinator.provincialCoordinatorEmail').invalid || form.get('provincialCoordinator.provincialCoordinatorTelephone').invalid || form.get('provincialCoordinator.provincialCoordinatorRank.provincialCoordinatorRankID').invalid || form.get('provincialCoordinatorID').invalid || form.get('provincialCoordinator.provincialCoordinatorCellphone').invalid || form.get('provincialCoordinator.provincialCoordinatorFirstName').invalid || form.get('provincialCoordinator.provincialCoordinatorSurname').invalid) == true)) && !this.expandEventVenue && !this.expandEventDetails) {
      this.provincialCoordinatorPanel.open();
      this.expandProvincialCoordinator = true;
    }
    if (this.siteCoordinatorValidation && !this.expandProvincialCoordinator && !this.expandEventVenue && !this.expandEventDetails) {
      this.siteCoordinatorPanel.open();
    }
  }





  resetPanels() {
    this.eventDetailsPanel.close();
    this.eventVenuePanel.close();
    this.provincialCoordinatorPanel.close();
    this.siteCoordinatorPanel.close();
    this.expandProvincialCoordinator = false;
    this.expandEventVenue = false;
    this.expandEventDetails = false;
  }
  createForm() {
    this.eventFormMain = this.fb.group({
      id: null,
      date: [{ value: null, disabled: true }, Validators.required],
      eventProvince: this.fb.group({
        provinceID: [null, Validators.required],
        provinceName: null,
        // provinceTown: this.fb.group({
        //   townID: ['', Validators.required],
        //   townName: '',
        //   provinceStation: this.fb.group({
        //     stationID: ['', Validators.required],
        //     stationName: ''
        //   })
        // })
      }),
      eventStatusId: [null, Validators.required],
      eventTownAndStationID: [null, Validators.required],
      eventTownAndStaionName: null,
      serviceType: this.fb.group({
        serviceTypeID: [null, Validators.required],
        serviceTypeName: ''
      }),
      eventStartTime: [null, Validators.required],
      eventEndTime: [null, Validators.required],
      shiftWorkersStartTime: null,
      shiftWorkersEndTime: null,
      address: null,
      specialLandmarks: [, Validators.required],
      employeeDetails: this.fb.group({
        amountOfEmployeesOnSite: ['', Validators.required],
        amountOfExpectedEventParticipants: ['', Validators.required],
      }),
      eventAtmosphere: this.fb.group({
        atmosphereID: [null, Validators.required],
        atmosphereType: '',
        venueOpenPlan: null,
        privateRoomsAvailable: null,
        numberOfGazebosRequired: null
      }),
      isGazebosRequisitioned: null,//input (if outdoor {could also be indoor for registration that happens outside})

      specialRequirements: null, //input (Biokenetist must also be on premises) -> could this be turned into a dropdown
      venueRestrictions: null,//input -> see if we can come up with a definite list

      dropOffFacilityDetails: ['', Validators.required],//input etc. parking lot?

      siteCoordinator: this.fb.group({
        //siteCoordinatorFullName: null,
        siteCoordinatorFirstName: null,
        siteCoordinatorSurname: null,
        siteCoordinatorRank: this.fb.group({
          siteCoordinatorRankID: null,
          siteCoordinatorRankName: ''
        }),
        siteCoordinatorTelephone: [null],
        siteCoordinatorCellphone: [null, cellNumberValidator],
        siteCoordinatorEmail: [null, Validators.email]
      }),
      provincialCoordinatorID: [null, southAfricanIdNumberValidator],
      provincialCoordinator: this.fb.group({
        provincialCoordinatorFirstName: [null, Validators.required],
        provincialCoordinatorSurname: [null, Validators.required],
        provincialCoordinatorRank: this.fb.group({
          provincialCoordinatorRankID: [null, Validators.required],
          provincialCoordinatorRankName: ''
        }),
        provincialCoordinatorTelephone: [null],
        provincialCoordinatorCellphone: [null, [Validators.required, cellNumberValidator]],
        provincialCoordinatorEmail: [null, [Validators.required, Validators.email]],//Validators.email not working -_-
        provincialCoordinatorSignature: [null, Validators.required]//this will change in MVP 2, now only for harcopy signatures

      }),
      cancellationReasonId: [null],
      cancelledBy: [null],
      eventPlanning: this.fb.group({
        serviceProviderId: [null],
        serviceProviderName: [null],
        healthPractitionerTypeId: [null],
        numberOfHealthPractitioners: [null],
        allocatedEquipment: [null],
        marketingCampaignDate: [null],
        setUpTimes: [null],
        strikeDownTimes: [null],
        strikeDownHours: [null],
        strikeDownMinutes: [null],
        setUpHours: [null],
        setUpMinutes: [null]
      }),
    });
  }


  backToCalendarAttempt() {
    if (this.eventFormMain.dirty) {
      var _popup = this.dialog.open(PopupComponent, {
        width: '55%',
        data: {
          title: 'All changes will be lost. Are you sure you want to continue?',
          lButTitle: 'Cancel',
          rButTitle: 'OK',
          lButNavUrl: 'back',
          rButNavUrl: 'calendar',
        }
        //height: '50%'
      }).afterClosed().subscribe((data) => {
        if (data == 'calendar') {
          this.backToCalendar();
        }
      });
    }
    else {
      this.backToCalendar();
    }
    // _popup.afterClosed().subscribe(item => {
    //   if (item == 'calendar') {
    //     this.backToCalendar();
    //   }
    // })
  }
  onVenueSelection(value: string) {
    //list item ID for inside
    if (value == '69d34741-ddf0-4025-b2b8-916cb017b0df') {
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").clearValidators();
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(null);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").addValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").addValidators(Validators.required);
      //list item ID for outside
    } else if (value == '9a859b37-8f61-497b-b0b0-771ea4f1fac5') {
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").addValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").clearValidators();
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").setValue(null);
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(null);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").removeValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").clearValidators();
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").setValue(null);
      //list item ID for combined
    } else if (value == 'a423a72a-5552-469c-a64b-68cf9bce6e91') {
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").setValue(null);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").setValue(null);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").setValue(null);
      this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").addValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.venueOpenPlan").addValidators(Validators.required);
      this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").addValidators(Validators.required);
    }
    this.eventFormMain.get("eventAtmosphere.numberOfGazebosRequired").updateValueAndValidity();
    this.eventFormMain.get("eventAtmosphere.venueOpenPlan").updateValueAndValidity();
    this.eventFormMain.get("eventAtmosphere.privateRoomsAvailable").updateValueAndValidity();
    this.eventFormMain.updateValueAndValidity();
  }
  onShiftWorkerTimeSelection() {
    this.eventFormMain.get("shiftWorkersStartTime").addValidators(Validators.required);
    this.eventFormMain.get("shiftWorkersEndTime").addValidators(Validators.required);

    this.eventFormMain.updateValueAndValidity();
  }
  clearShiftWorkerTimeEntries() {
    this.eventFormMain.controls["shiftWorkersStartTime"].setValue(null);
    this.eventFormMain.controls["shiftWorkersEndTime"].setValue(null);
    this.eventFormMain.get("shiftWorkersStartTime").clearValidators();
    this.eventFormMain.get("shiftWorkersEndTime").clearValidators();
    this.eventFormMain.controls["shiftWorkersStartTime"].setErrors(null);
    this.eventFormMain.controls["shiftWorkersEndTime"].setErrors(null);
    this.eventFormMain.updateValueAndValidity();
  }
  setIDLengthValidation(value: string, id: number) {
    let provID = id.toString();
    if (provID.length > 0) {
      if (provID.length < 13) {
        this.eventFormMain.controls[value].setErrors({ min: true });
      }
    } else {
      this.eventFormMain.get(value).clearValidators();
      this.eventFormMain.controls[value].setErrors(null);
    }
    this.eventFormMain.updateValueAndValidity();
  }
  onSiteCoordinatorEntry() {
    this.siteCoordinatorValidation = true;
    console.log(this.eventFormMain)
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").errors || {}), 'required': "Site Coordinator's full name is required." });
    }
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").errors || {}), 'required': "Site Coordinator's full name is required." });
    }
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").errors || {}), 'required': "Site Coordinator's full name is required." });
    } else {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").addValidators(Validators.email);
    }
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").errors || {}), 'required': "Site Coordinator's full name is required." });
    } else {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").addValidators([cellNumberValidator]);
    }
    if (!this.eventFormMain.get("siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID").value) {
      this.eventFormMain.get("siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID").setErrors({ ...(this.eventFormMain.get("siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID").errors || {}), 'required': "Site Coordinator's full name is required." });
    }
    this.eventFormMain.updateValueAndValidity();
  }
  clearSiteCoordinatorEntry() {
    this.siteCoordinatorValidation = false;
    this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorTelephone").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").setValue(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorFirstName").setErrors(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorSurname").setErrors(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorRank.siteCoordinatorRankID").setErrors(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorCellphone").setErrors(null);
    this.eventFormMain.get("siteCoordinator.siteCoordinatorEmail").setErrors(null);
    this.eventFormMain.updateValueAndValidity();
  }

  onSelectedTown(value: string) {

  }
  onSelectedProvince(value: string) {
    if (value != null) {
      this.showTown = true
    };
    let provinceSelectedCode: string = '';
    this.provinces.forEach(province => {
      if (province.id == this.eventFormMain.get("eventProvince.provinceID").value) {
        provinceSelectedCode = province.code;
      }
    });
    //get array of towns and stations with code
    this.listService.getByName(provinceSelectedCode).subscribe((data) => {
      data.listItems.forEach(li => {
        li.description = this.eventDescription(li.description);
      });
      this.selectedProvinceTownsAndStations = data.listItems;
      this.filteredOptions = data.listItems;
    })
  }
  onSelectedServiceProvider(value: string) {
  }
  filterTownsAndStations(value: string) {
    if (value && value.length > 0) {
      console.log(`Has Value ` + value);
      let result = this.select(value.toLowerCase());
      this.filteredOptions = result;
    } else {

      console.log('Else value: ' + this.selectedProvinceTownsAndStations);
      this.filteredOptions = this.selectedProvinceTownsAndStations;//
    }
  }
  public select(query: string): ListItemObject[] {
    let result: ListItemObject[] = [];
    return this.selectedProvinceTownsAndStations.filter(a => a.description.toLowerCase().indexOf(query) > -1);
  }
  setAddressValidation(value: string) {
    if (value == "address") {
      this.eventFormMain.get(value).addValidators(Validators.required);
      this.eventFormMain.get("specialLandmarks").clearValidators();
      this.eventFormMain.controls["specialLandmarks"].setErrors(null);
    } else if (value == "specialLandmarks") {
      this.eventFormMain.get(value).addValidators(Validators.required);
      this.eventFormMain.get("address").clearValidators();
      this.eventFormMain.controls["address"].setErrors(null);
    }
    this.eventFormMain.updateValueAndValidity();
  }
  timeValidation(start: string, end: string, timeType: string) {
    if (end != null || end != "") {
      let startTime: Date = new Date(Date.parse("2015/01/01 " + start));
      let endTime: Date = new Date(Date.parse("2015/01/01 " + end));
      let sum: number = (endTime.getTime() - startTime.getTime());

      if (sum <= 0) {
        if (timeType.toString() == "event") {
          this.validEventTimeInputs = false;
        }
        if (timeType.includes("shiftWorker")) {
          this.validShiftWorkerTimeInputs = false;
        }
      } else {
        if (timeType.toString() == "event") {
          this.validEventTimeInputs = true;
        }
        if (timeType.toString() == "shiftWorker") {
          this.validShiftWorkerTimeInputs = true;
        }
      }
      this.timeError(timeType);
    }
  }
  specialCharacterChecker(value: number, field: string) {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(value.toString())) {
      this.eventFormMain.get(`${field}`).setErrors({ ...(this.eventFormMain.get(`${field}`).errors || {}), 'Special': 'Special characters not allowed.' });
    }
    this.eventFormMain.updateValueAndValidity();
  }
  timeError(timeType: string): void {
    console.log(this.eventFormMain)
    if (timeType == "event") {
      if (this.validEventTimeInputs == false) {
        this.eventFormMain.get("eventEndTime").setErrors({ ...(this.eventFormMain.get("eventEndTime").errors || {}), 'Invalid': 'Invalid time selection.' });
      } else {
        this.eventFormMain.get("eventEndTime").setErrors(null);
        this.eventFormMain.get("eventEndTime").clearValidators();
      }
      this.eventFormMain.updateValueAndValidity();
    } else if (timeType == "shiftWorker") {
      if (this.validShiftWorkerTimeInputs == false) {
        this.eventFormMain.get("shiftWorkersEndTime").setErrors({ ...(this.eventFormMain.get("shiftWorkersEndTime").errors || {}), 'Invalid': 'Invalid time selection.' });
      } else {
        this.eventFormMain.get("shiftWorkersEndTime").setErrors(null);
        this.eventFormMain.get("shiftWorkersEndTime").clearValidators();
      }
      this.eventFormMain.updateValueAndValidity();
    }
  }
  ngOnDestroy(): void {
    // Disconnect the MutationObserver when the component is destroyed
    if (this.eventHarnessObserver) {
      this.eventHarnessObserver.disconnect();
    }
  }
}
