<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <app-consent-base (submitConsentMapper)="postSubmit($event)" [consent]="consentMapper">
      <!-- ADD A TWO-WAY BINDING -->
      <span class="blank-line blank-l ine-2 d-block"></span>
      <h4 class="mdc-typography--headline5 text--align-center mb-3">Consent / refusal to have an HIV test</h4>
      <span class="blank-line blank-line-2 d-block"></span>
      <div>
        <span class="mdc-typography--body1">
          <span class="mdc-typography--body1">
            <p>
              <span>I, <u>{{consentMapper.member_FullName}}</u>{{consentMapper.member_IdNumberLabel}},

                <u>{{consentMapper.member_IdNumber}}</u>,
                hereby request to have an HIV test. A finger-prick test will be done by a registered nurse. The
                results from the HIV
                finger-prick test will be made available to my associated medical aid. I understand that the results
                will be kept
                confidential, except for discourse of any non negative results to my medical aid for further
                management and counselling.
                I understand and accept counselling is done based on information I provide and that Momentum HRM take
                no responsibility if incomplete or untrue information is shared. I declare that I have been counselled
                prior to the test, that I received information about the test, and understand what the test is about;
                understand the purpose and benefit of the test; and the implications for my life if the test is
                positive.</span>
          </span>
        </span>
      </div>
    </app-consent-base>
  </article>
</div>
