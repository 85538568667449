// Angular Core Imports

import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { C } from '@fullcalendar/core/internal-common';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as Enums from 'src/app/shared/enums';
import { QUESTION_TYPE, RISK_RATING } from 'src/app/shared/enums';
import { TestNote } from 'src/app/shared/interfaces/notesMapper';
import { PatientObject, PatientObjectFactory } from 'src/app/shared/interfaces/patientMapper';
import { Review } from 'src/app/shared/interfaces/reviewMapper';
import { SouthAfricanId } from 'src/app/shared/interfaces/south-african-id';
import { AdhocService } from 'src/app/shared/services/adhoc.service';
import { CalculationService } from 'src/app/shared/services/calculation.service';
import { ConsultationService } from 'src/app/shared/services/consultation.service';
import { ConsultationWorkflowService } from 'src/app/shared/services/consultation.workflow.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HealthyHeartScoreService, base64ToBlob } from 'src/app/shared/services/healthy-heart-score.service';
import { ListService } from 'src/app/shared/services/list.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { minDateValidator } from 'src/app/shared/validators/min-date-validator';

import { TestRequestedObject } from '../../shared/interfaces/consultationMapper';
import { ExaminationOptionObject, HierarchicalQuestionObject, ListItemObject, QuestionnaireExaminationOptionObject } from '../../shared/interfaces/questionnaireMapper';
import { ResponseItemObject, ResponseObject, TestPerformedObject } from '../../shared/interfaces/responseMapper';
import { GlobalDataService } from '../../shared/services/global-data.service';
import { QuestionnaireService } from '../../shared/services/questionnaire.service';

// RxJS Imports



// Internal Imports - Enums, Interfaces, Services



















@Component({
  selector: 'app-q',
  templateUrl: './q.component.html',
  styleUrls: ['./q.component.scss', './slidercolors.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ transform: 'ease-in(0)', opacity: 0 }),
        animate('500ms', style({ transform: 'ease-in(100%)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'ease-out(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'ease-out(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class QComponent implements OnInit {

  //TODO: Add logged in user profile here
  // import { KeycloakProfile } from 'keycloak-js'
  @ViewChild('toggleAndCommentQuestion') toggleAndCommentQuestion!: TemplateRef<any>
  @ViewChild('multipleSelectionQuestion') multipleSelectionQuestion!: TemplateRef<any>
  @ViewChild('multipleSelectionAndCommentQuestion')
  multipleSelectionAndCommentQuestion!: TemplateRef<any>
  @ViewChild('multipleSelectionWithSubQuestionQuestion')
  multipleSelectionWithSubQuestionQuestion!: TemplateRef<any>
  @ViewChild('dateQuestion') dateQuestion!: TemplateRef<any>
  @ViewChild('numericQuestion') numericQuestion!: TemplateRef<any>
  @ViewChild('buttonQuestion') buttonQuestion!: TemplateRef<any>
  @ViewChild('numericAndCommentQuestion') numericAndCommentQuestion!: TemplateRef<any>
  @ViewChild('toggleQuestion') toggleQuestion!: TemplateRef<any>
  @ViewChild('radioQuestion') radioQuestion!: TemplateRef<any>
  @ViewChild('gridQuestion') gridQuestion!: TemplateRef<any>
  @ViewChild('groupLabelQuestion') groupLabelQuestion!: TemplateRef<any>
  @ViewChild('imageQuestion') imageQuestion!: TemplateRef<any>
  @ViewChild('decimalQuestion') decimalQuestion!: TemplateRef<any>
  @ViewChild('textQuestion') textQuestion!: TemplateRef<any>
  @ViewChild('multilineTextQuestion') multilineTextQuestion!: TemplateRef<any>
  @ViewChild('selectionQuestion') selectionQuestion!: TemplateRef<any>
  @ViewChild('selectionAndCommentQuestion') selectionAndCommentQuestion!: TemplateRef<any>
  @ViewChild('questionPhrase') questionPhrase!: TemplateRef<any>
  @ViewChild('questionComment') questionComment!: TemplateRef<any>
  @ViewChild('sliderQuestion') sliderQuestion!: TemplateRef<any>
  @ViewChild('errorTemplate') errorTemplate!: TemplateRef<any>

  // Inputs
  @Input() allowNotes: boolean = true;
  @Input() allowSubmit: boolean = true;
  @Input() consultationId: string = null; //Req
  @Input() consultationSelection: string = ''; //Req
  @Input() forcedExaminationStatus: number = -1;
  @Input() fullRow: string = '';
  @Input() hideExaminationStatus: boolean = false;
  @Input() questionnaireId: string = '287dcf35-7b57-451f-a958-6138adbf01f6'; //Req
  @Input() reviewId: string = null; //Req
  @Input() showBackButton: boolean = true; //Req
  @Input() showTitle: boolean = true; //Req
  @Input() testRequested: TestRequestedObject; //Req

  // Outputs
  @Output() onBackPressed = new EventEmitter<void>();
  @Output() onSaved = new EventEmitter<TestPerformedObject>();

  // Grid question variables
  private gridApi;
  private gridColumnApi;

  // Public Properties
  examinationFormGroup: FormGroup;
  examinationNotesFormGroup: FormGroup;
  hierarchicalQuestionnaire: HierarchicalQuestionObject[]; //Req
  isLoading: boolean = true;
  isSubmit: boolean = false;
  noData: boolean = false; //Req
  patient: PatientObject;
  questionnaireFormGroup: FormGroup;
  reviewInfoObject: Review; //Req
  showError: boolean = false;
  testPerformed: TestPerformedObject; //Req
  userId: string;

  // Variables for questionnaire management
  showQuestionnaire: boolean = false;
  showQuestionnaireComment: boolean = false;
  showContraindicated: boolean = false;
  showContraindicatedComment: boolean = false;
  enableSubmit: boolean = false;
  enableAddNote: boolean = false;

  // Variables for handling questionnaires
  responseObject: ResponseObject;
  responseItems: ResponseItemObject[] = [];
  referralQuestion: HierarchicalQuestionObject = null;

  // Variables for handling reviews
  isCompleted: boolean = true;

  // Variables for both
  examinationComment?: string = null;
  examinationNotes: TestNote[] = [];
  examinationResultName: string = '';
  examinationResult: number = 0;
  questionnaireResult?: number = null;
  examinationOptions: QuestionnaireExaminationOptionObject[] = [];
  allExaminationOptions: ExaminationOptionObject[] = [];
  contraindicationList: ListItemObject[] = [];
  contraindicationWatchList: string[] = [];
  stackLevel: number = 0;
  examinationType: string = '';

  // userProfile: KeycloakProfile
  updateAllowed: boolean = false;
  userRoles: any;
  userFullName: string;

  get submitAllowed(): boolean {
    return
    this.allowSubmit &&
      (this.consultationSelection == 'Survey' ||
        (this.enableSubmit && this.updateAllowed))
  }

  get questions(): HierarchicalQuestionObject[] {
    return this.hierarchicalQuestionnaire.filter(hq => [QUESTION_TYPE.GroupStart, QUESTION_TYPE.GroupEnd].indexOf(hq.questionType) == -1);
  }

  get multipleSelectionQuestions(): HierarchicalQuestionObject[] {
    return this.hierarchicalQuestionnaire.filter((hq) => ["multipleselection", "multipleselectionwithcomment"].indexOf(hq.questionTypeName.toLowerCase()) > -1);
  }

  constructor(
    private router: Router,
    private globalData: GlobalDataService,
    private questionnaireService: QuestionnaireService,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private calculationService: CalculationService,
    private consultationService: ConsultationService,
    private adhocService: AdhocService,
    private consultationWorkflowService: ConsultationWorkflowService,
    private listService: ListService,
    private dateService: DateService,
    private datePipe: DatePipe,
    private healthyHeartScore: HealthyHeartScoreService
  ) {
    this.healthyHeartScore.bodyParams = {
      clientDetails: {
        cellNumber: '',
        email: '',
      },
      providerDetails: {
        assessorName: '',
        location: ''
      },
      measurements: []
    }
    this.globalData.userIdVal.subscribe(val => this.userId = val);
    this.globalData.userFullNameVal.subscribe(val => this.userFullName = val);
    this.examinationFormGroup = new FormGroup({
      examinationResultName: new FormControl('', [Validators.required]),
      examinationComment: new FormControl(''),
      contraindicatedSelection: new FormControl(''),
      contraindicatedComment: new FormControl(''),
    })
    this.questionnaireFormGroup = new FormGroup({})
    this.examinationNotesFormGroup = new FormGroup({
      examinationNotes: new FormControl(''),
    })
  }

  ngOnInit(): void {
    // this.globalData.userProfileVal.subscribe((up) => (this.userProfile = up))

    // Get the patient information as tis may be required for calculations
    if (this.consultationId) {
      this.consultationService.getConsultationDetail(this.consultationId).subscribe(d => {
        this.examinationType = d.examinationType;
      });
      this.patientService
        .getPatientFromConsultation(this.consultationId)
        .subscribe((patient) => {
          this.patient = patient
          if (this.patient != null) {
            this.listService.get(Enums.LISTS.GENDERS).subscribe((data) => {
              data.listItems.forEach(gen => {
                if (gen.id == patient.genderId) {
                  this.patient.gender = gen.description;
                }
              });
            })
          }
        })
    } else {
      this.patient = PatientObjectFactory.default()
    }

    this.globalData.userRolesVal.subscribe((roles) => (this.userRoles = roles))
    if (this.consultationSelection != 'Survey') {
      this.consultationWorkflowService
        .getCurrentWorkflowStatus(this.consultationId)
        .subscribe((workflow) => {
          if (workflow) {
            if (workflow.consultationWorkflowStatus > 2) {
              if (workflow.consultationWorkflowStatus == 5) {
                this.updateAllowed = false
              }
              else {
                if (workflow.consultationWorkflowStatus == 3 && (this.userRoles.includes('Perigon_OHNP') || this.userRoles.includes('Perigon_OMP'))) {
                  this.updateAllowed = true
                }

                if (workflow.consultationWorkflowStatus == 4 && this.userRoles.includes('Perigon_OMP')) {
                  this.updateAllowed = true
                }
              }
            }
            else {
              this.updateAllowed = true
            }
          }
          else {
            this.updateAllowed = true
          }

          this.questionnaireService.getExaminationOptions().subscribe((result) => {
            this.allExaminationOptions = result as ExaminationOptionObject[]
            this.questionnaireService
              .getQuestionnaireExaminationOptions(this.questionnaireId)
              .subscribe((result) => {
                this.examinationOptions = result as QuestionnaireExaminationOptionObject[]
                this.examinationOptions.forEach((item) => {
                  if (item.listId != null) {
                    this.contraindicationWatchList = item.commentRequiredList
                      .split(',')
                      .map((item) => item.trim().toUpperCase())
                    this.questionnaireService
                      .getContraindicationList(item.listId)
                      .subscribe((result) => {
                        this.contraindicationList = result as ListItemObject[]
                      })
                  }
                })

                if (
                  this.consultationSelection == 'Questionnaire' ||
                  this.consultationSelection == 'Survey'
                ) {
                  // Retrieve the complete hierarchical questionnaire structure (does not contain response values)
                  this.questionnaireService
                    .getHierarchicalQuestionnaire(this.questionnaireId)
                    .subscribe((result) => {
                      this.hierarchicalQuestionnaire =
                        result as HierarchicalQuestionObject[]
                      this.processHierarchy()
                    })
                }

                if (this.consultationSelection == 'Review') {
                  this.questionnaireService
                    .getTestPerformedForReviewId(this.reviewId)
                    .subscribe((test) => {
                      this.testPerformed = test
                      // Retrieve the complete hierarchical questionnaire structure (includes the clients previous response values)
                      this.questionnaireService
                        .getHierarchicalResponse(this.reviewId)
                        .subscribe((result) => {
                          if (result?.length == 0) {
                            this.questionnaireService
                              .getHierarchicalQuestionnaire(this.questionnaireId)
                              .subscribe((result) => {
                                this.noData = true
                                this.hierarchicalQuestionnaire =
                                  result as HierarchicalQuestionObject[]
                                this.processHierarchy()
                              })
                          } else {
                            // Convert dates to ISO formatting
                            result.forEach((item) => {
                              if (item.responseDateTime != null) {
                                item.responseDateTime = new Date(
                                  item.responseDateTime
                                ).toISOString()
                              }
                            })

                            this.hierarchicalQuestionnaire =
                              result as HierarchicalQuestionObject[]
                            this.createResponseObject()
                          }
                        })
                    })
                }
              })
          })
        })
    } else {
      this.questionnaireService.getExaminationOptions().subscribe((result) => {
        this.allExaminationOptions = result as ExaminationOptionObject[]
        this.questionnaireService
          .getQuestionnaireExaminationOptions(this.questionnaireId)
          .subscribe((result) => {
            this.examinationOptions = result as QuestionnaireExaminationOptionObject[]
            this.questionnaireService
              .getHierarchicalQuestionnaire(this.questionnaireId)
              .subscribe((result) => {
                this.hierarchicalQuestionnaire = result as HierarchicalQuestionObject[]
                this.processHierarchy()
              })
          })
      })
    }
  }

  /* #region Notes */

  addNote(): void {
    const val = this.examinationNotesFormGroup.controls['examinationNotes'].value
    this.examinationNotes.push({
      user: this.userFullName,
      // this.userProfile.firstName + ' ' + this.userProfile.lastName,
      dateTime: new Date(),
      notes: val,
      isNew: true,
    })
    this.examinationNotesFormGroup.controls['examinationNotes'].setValue(null)
    this.enableAddNote = false
  }

  removeNote(index: number): void {
    this.examinationNotes.splice(index, 1)
  }

  onNoteTextChange(event): void {
    const val = this.examinationNotesFormGroup.controls['examinationNotes'].value
    this.enableAddNote = val && val.trim()
  }

  /* #endregion */

  createResponseObject(): void {
    // Change the options etc to match what was on the original question
    var keys = Object.keys(this.hierarchicalQuestionnaire)
    if (!this.noData) {
      if (keys.length > 0) {
        for (let index = 0; index < keys.length; index++) {
          let hq = this.hierarchicalQuestionnaire[index]
          if (hq.responseReviewId) {
            hq.groupQuestionAssociationId = hq.responseGroupQuestionAssociationId
            hq.groupQuestionAssociationParentId = hq.responseParentQuestionAssociationId
            hq.questionPhrase = hq.responseQuestionPhrase
            hq.questionType = hq.responseQuestionType
            hq.questionTypeName = this.getQuestionTypeName(hq.responseQuestionType)
            hq.options = hq.responseSelectionOptions
          }
        }
      }
    }

    keys = Object.keys(this.hierarchicalQuestionnaire)

    if (keys.length > 0) {
      // Create a populated response
      this.questionnaireService.getReview(this.reviewId).subscribe((review) => {
        this.reviewInfoObject = review

        this.responseObject = {
          reviewId: this.reviewInfoObject.id,
          reviewDate: this.reviewInfoObject.date,
          questionnaireId: this.reviewInfoObject.questionnaireId,
          responseItems: [],
        }

        this.responseItems = []

        var keys = Object.keys(this.hierarchicalQuestionnaire)
        for (let index = 0; index < keys.length; index++) {
          let hq = this.hierarchicalQuestionnaire[index]

          let type = hq.questionTypeName.toLowerCase()
          let response: ResponseItemObject = {
            questionId: hq.questionId,
            groupQuestionAssociationId: hq.groupQuestionAssociationId,
            comment: hq.responseComment,
            dateTime: hq.responseDateTime,
            result: hq.responseResult,
            selectionOptions: hq.responseSelectionOptions,
            selectionOptionsSelected: hq.responseSelectionOptionsSelected,
            value: hq.responseValue,
            listItemId: hq.responseOptionId,
            signature: hq.responseSignature,
            time: hq.responseTime,
            responseText: hq.responseResponseText,
            contoller: hq.responseController,
            endPoint: hq.responseEndPoint,
            method: hq.responseMethod,
            parameters: hq.responseParameters,
            serverRequestResponse: hq.responseServerRequestResponse_Result,
            question: hq,
          }

          this.responseItems.push(response)
        }

        this.processHierarchy()
      })
    } else {
      this.processHierarchy()
    }
  }

  getQuestionTypeName(id): string {
    return [
      'Selection',
      'MultipleSelection',
      'Toggle',
      'Text',
      'MultilineText',
      'Range',
      'Numeric',
      'Decimal',
      'Currency',
      'Date',
      'DateTime',
      'Time',
      'Image',
      'Signature',
      'ToggleAndComment',
      'NumericAndComment',
      'Expression',
      'SelectionAndComment',
      'CommentAndYear',
      'ServerRequest',
      'MultipleSelectionAndComment',
      'GroupLabel',
      'Button',
      'Grid',
      'MultipleSelectionWithSubQuestion',
      'GroupStart',
      'GroupEnd',
      'Slider',
      'Radio',
    ][id]
  }

  getExaminationResultName(id): string {
    let result = 'Not found'

    if (this.allExaminationOptions.length > 0) {
      let found = false

      this.allExaminationOptions.forEach((option) => {
        if (!found) {
          if (option.id == id) {
            result = option.name
            found = true
          }
        }
      })
    }

    return result
  }

  getExaminationResult(id): any {
    let found = false
    var result = null

    if (this.allExaminationOptions.length > 0) {
      this.allExaminationOptions.forEach((option) => {
        if (!found) {
          if (option.id == id) {
            result = option
            found = true
          }
        }
      })
    }

    return result
  }

  processHierarchy(): void {
    this.isCompleted = false
    if (this.consultationSelection == 'Review') {
      // Check to see if this test was previously performed. If it was, set the examinationForm values

      let result = this.testPerformed.resultValue
      if (result == 0 || result == 1) {
        result = 0
      }
      this.isCompleted = true
      if (result == null) {
        this.isCompleted = false
      } else {
        this.examinationFormGroup.controls['examinationResultName'].setValue(result)

        if (result != 0) {
          // Done
          this.examinationFormGroup.controls['examinationResultName'].setValue(result)
          this.examinationFormGroup.controls['examinationComment'].setValue(
            this.testPerformed.examStatusComment
          )
          this.showQuestionnaireComment = true

          if (result == 3) {
            // Contraindicated
            this.showContraindicated = true
            if (this.testPerformed.contraIndicationId != null) {
              this.examinationFormGroup.controls['contraindicatedSelection'].setValue(
                this.testPerformed.contraIndicationId
              )

              if (
                this.contraindicationWatchList.includes(
                  this.testPerformed.contraIndicationId.toUpperCase()
                )
              ) {
                this.showContraindicatedComment = true
                this.examinationFormGroup.controls['contraindicatedComment'].setValue(
                  this.testPerformed.contraIndicationComment
                )
                if (
                  this.examinationFormGroup.controls['contraindicatedComment'].value.trim().length > 0
                ) {
                  this.enableSubmit = true
                }
              } else {
                this.showContraindicatedComment = false
                this.enableSubmit = true
              }
            }
          } else {
            if (this.testPerformed.examStatusComment != null) {
              if (this.testPerformed.examStatusComment.trim().length > 0) {
              }
            }
          }
        } else {
          this.enableSubmit = true
        }
        this.testPerformed.notes.forEach((n) => this.examinationNotes.push(n))
      }

      this.examinationFormGroup.controls['examinationResultName'].setValue(
        this.getExaminationResult(result).id
      )
      this.examinationSelection({ value: result })
      this.examinationResultName = this.getExaminationResultName(result)
      if (this.testPerformed.contraIndicationId) {
        this.contraindicatedSelection({ value: this.testPerformed.contraIndicationId })
      }
    }
    if (this.forcedExaminationStatus > -1) {
      this.examinationFormGroup.controls['examinationResultName'].setValue(
        this.getExaminationResult(this.forcedExaminationStatus).id
      )
      this.examinationSelection({ value: this.forcedExaminationStatus })
      this.examinationResultName = this.getExaminationResultName(
        this.forcedExaminationStatus
      )
      if (this.forcedExaminationStatus) {
        this.examinationFormGroup.controls['examinationResultName'].disable()
      }
      this.showQuestionnaireComment = true
    }

    // Enumerate the hierarchical questionnaire structure
    var keys = Object.keys(this.hierarchicalQuestionnaire)

    // Default all questions and headers
    for (let index = 0; index < keys.length; index++) {
      let template = this.hierarchicalQuestionnaire[index];
      template.isVisibleSetByCondition = false
      template.showHeader = true
      template.commentAvailable = true
      template.commentRequired = false
      template.questionRequired = true
      template.questionRequiredIndicator = '  *'
      template.questionDisabled = false
      template.questionReadOnly = false
      template.questionMin = Number.MIN_SAFE_INTEGER
      template.questionMax = Number.MAX_SAFE_INTEGER
      template.listItems = []
      template.listItemLabel = ''
      template.questionMaxLength = 300
      template.questionPattern = '^[0-9]+(\\.[0-9]{1,2})?$'
      template.questionCommentPattern = ''
      template.questionStep = 1
      template.questionName = template.questionId.toLowerCase() //.questionnaireCode.concat(index.toString());
      template.questionNameComment = template.questionnaireCode.concat(index.toString()) + 'comment'
      template.isReferral = template.questionClassification?.toUpperCase() == "REFERRAL";
      template.isVisible = !template.isReferral
      template.questionGroupLevel = 0
      template.questionIndentStyle = '0px'
      template.questionPaddingLeftStyle = '0px'
      template.questionPaddingRightStyle = '0px'
      template.questionBackgroundColorStyle = 0xffffff
      template.questionMask = ''
      template.questionMaskValidation = false
      template.questionClasses = null
      template.questionClass = {}
      template.abnormalities = []

      if (template.isReferral) {
        this.referralQuestion = template;
      }

      switch (template.questionTypeName.toLowerCase()) {
        case 'numeric':
        case 'numericandcomment': {
          template.questionPlaceholder = 'e.g. 123'
          break
        }
        case 'date': {
          template.questionPlaceholder = 'e.g. 01/01/2019'
          break
        }
        case 'text':
        case 'multilinetext': {
          template.questionPlaceholder = 'e.g. Enter text here'
          break
        }
        case 'decimal': {
          template.questionPlaceholder = 'e.g. 123.45'
          break;
        }
        default: {
          template.questionPlaceholder = template.questionPhrase
          break
        }
      }

      //TODO: Cater for QuestionDisplayCondition at time of capture for reviews
      // Check for display conditions
      // Hide questions that are dependent on parent values
      // Go though all conditions and option and set to not visible where required
      template.conditions = template.conditions || []
      var condKeys = Object.keys(template.conditions)

      if (condKeys.length > 0) {
        template.isVisible = false
        template.isVisibleSetByCondition = true
      }

      //TODO: Cater for options a time of capture for reviews
      // Check for options
      var optKeys = Object.keys(template.options)

      if (optKeys.length > 0) {
        this.recurse(optKeys, template.options, (option) => {
          // Handle comment options
          if (option.discriminator.toLowerCase() == 'commentoption') {
            if (option.attribute.toLowerCase() == 'always-available') {
              template.commentAvailable = true
              template.commentRequired = false
            }
            if (option.attribute.toLowerCase() == 'always-required') {
              template.commentAvailable = true
              template.commentRequired = true
            }
            if (option.attribute.toLowerCase() == 'always-hide') {
              template.commentAvailable = false
              template.commentRequired = false
            }
          }

          if (option.discriminator.toLowerCase() == 'selectionoption') {
            template.listItems = option.list.listItems
            template.questionMax = template.listItems.length - 1
          }

          // Handle attribute options
          if (option.discriminator.toLowerCase() == 'attributeoption') {
            if (option.value == null) {
              option.value = ''
            }
            let options = option.value
              .toLowerCase()
              .split(',')
              .map((item) => item.trim())

            if (option.attribute.toLowerCase() == 'required') {
              if (option.value == 'true') {
                template.questionRequired = true
                template.questionRequiredIndicator = '*'
              } else {
                template.questionRequired = false
                template.questionRequiredIndicator = ''
              }
            }
            if (option.attribute.toLowerCase() == 'value') {
              if ([QUESTION_TYPE.Date, QUESTION_TYPE.Time, QUESTION_TYPE.DateTime].indexOf(template.questionType) > -1) {
                template.questionMin = template.questionMax = option.value == "today" ? this.datePipe.transform(new Date(), 'yyyy-MM-dd') : option.value;
              }
              else {
                template.questionMin = template.questionMax = option.value;
              }
            }
            if (option.attribute.toLowerCase() == 'disabled') {
              template.questionDisabled = options[0] == 'true'
            }
            if (option.attribute.toLowerCase() == 'readonly') {
              template.questionReadOnly = options[0] == 'true'
            }
            if (option.attribute.toLowerCase() == 'visible') {
              if (options[1] == 'novalue') {
                if (
                  template.responseOptionId != null ||
                  template.responseSelectionOptionsSelected != null
                ) {
                  template.isVisible = true
                } else {
                  template.isVisible = false
                }
              } else {
                template.isVisible = options[0] == 'true'
              }
            }
            if (option.attribute.toLowerCase() == 'min') {
              if ([QUESTION_TYPE.Date, QUESTION_TYPE.Time, QUESTION_TYPE.DateTime].indexOf(template.questionType) > -1) {
                template.questionMin = option.value == "today" ? this.datePipe.transform(new Date(), 'yyyy-MM-dd') : option.value;
              }
              else {
                template.questionMin = option.value;
              }
            }
            if (option.attribute.toLowerCase() == 'max') {
              if ([QUESTION_TYPE.Date, QUESTION_TYPE.Time, QUESTION_TYPE.DateTime].indexOf(template.questionType) > -1) {
                template.questionMax = option.value == "today" ? this.datePipe.transform(new Date(), 'yyyy-MM-dd') : option.value;
              }
              else {
                template.questionMax = +option.value;
              }
            }
            if (option.attribute.toLowerCase() == 'step') {
              template.questionStep = +options[0]
            }
            if (option.attribute.toLowerCase() == 'pattern') {
              template.questionPattern = option.value.toLowerCase()
            }
            if (option.attribute.toLowerCase() == 'mask') {
              template.questionMask = option.value.toLowerCase()
            }
            if (option.attribute.toLowerCase() == 'maskValidation') {
              template.questionMaskValidation = options[0] == 'true'
            }
            if (option.attribute.toLowerCase() == 'placeholder') {
              template.questionPlaceholder = option.value
            }
            if (option.attribute.toLowerCase() == 'src') {
              template.questionSrc = option.value
            }
          }

          if (option.discriminator.toLowerCase() == 'slideroption') {
            if (option.attribute == 'set-class') {
              template.questionClasses = JSON.parse(option.value)
            }
          }

          if (option.discriminator.toLowerCase() == 'classoption') {
            //if (option.attribute == 'set-class') {
            template.questionClass = { [option.value]: true }
            //}
          }

          // Handle grid definitions
          if (
            option.discriminator.toLowerCase() == 'gridoption' &&
            option.attribute?.toLowerCase() !== 'value-range-select'
          ) {
            template.questionGridDefinition = JSON.parse(option.value)
          }
          if (
            option.discriminator.toLowerCase() == 'gridoption' &&
            option.attribute?.toLowerCase() == 'value-range-select'
          ) {
            // Default data
            template.questionGridData = JSON.parse(option.value)
          }
        })
      }
    }

    // Create a mapping of parent IDs to children
    let parentToChildren = {};
    this.hierarchicalQuestionnaire.forEach((hq, index) => {
      let parentId = hq.groupQuestionAssociationParentId;
      if (parentId) {
        if (!parentToChildren[parentId]) {
          parentToChildren[parentId] = [];
        }
        parentToChildren[parentId].push(index);
      }
    });

    // Handle display options
    this.hierarchicalQuestionnaire.forEach((hq, index) => {
      hq.options.forEach((option) => {
        if (option.discriminator.toLowerCase() == 'displayoption') {
          hq.isVisible = false;

          // If DisplayOption is set, also make children not visible
          let childrenIndices = parentToChildren[hq.groupQuestionAssociationId];
          if (childrenIndices) {
            childrenIndices.forEach((childIndex) => {
              this.hierarchicalQuestionnaire[childIndex].isVisible = false;
            });
          }
        }
      });
    });

    // Create a mapping of groupId to indices in the array
    let groupToIndices = {};
    this.hierarchicalQuestionnaire.forEach((hq, index) => {
      let groupId = hq.groupId;
      if (!groupToIndices[groupId]) {
        groupToIndices[groupId] = [];
      }
      groupToIndices[groupId].push(index);
    });

    // Initialize currentGroupId and visibleCount
    let currentGroupId = '';
    let visibleCount = 0;

    // Iterate over hierarchicalQuestionnaire
    this.questions.forEach((hq, index) => {
      if (hq.groupId !== currentGroupId) {
        // If moving to a new group and no visible questions in the last group, hide the group header
        if (currentGroupId && visibleCount === 0) {
          groupToIndices[currentGroupId].forEach((idx) => {
            this.hierarchicalQuestionnaire[idx].showHeader = false;
          });
        }
        // Update currentGroupId and reset visibleCount
        currentGroupId = hq.groupId;
        visibleCount = hq.isVisible ? 1 : 0;
      } else if (hq.isVisible) {
        visibleCount++;
      }
    });

    // Check for the last group
    if (visibleCount === 0 && currentGroupId) {
      groupToIndices[currentGroupId].forEach((idx) => {
        this.hierarchicalQuestionnaire[idx].showHeader = false;
      });
    }

    let options: ValidatorFn[] = []
    // Create the form group dynamically according to question info to assist with validation
    for (let index = 0; index < keys.length; index++) {
      let hq = this.hierarchicalQuestionnaire[index]
      switch (hq.questionTypeName.toLowerCase()) {
        case 'numeric':
          options = [
            Validators.min(hq.questionMin as number),
            Validators.max(hq.questionMax as number),
            Validators.pattern(hq.questionPattern),
          ]

          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', options)
          )
          break
        case 'decimal':
          options = [
            Validators.min(hq.questionMin as number),
            Validators.max(hq.questionMax as number),
            Validators.pattern(hq.questionPattern),
          ]

          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', options)
          )

          break
        case 'toggleandcomment':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )
          this.questionnaireFormGroup.addControl(
            hq.questionNameComment,
            new FormControl('', null)
          )

          break
        case 'multipleselection':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )
          break
        case 'multipleselectionandcomment':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )
          this.questionnaireFormGroup.addControl(
            hq.questionNameComment,
            new FormControl('', null)
          )

          break
        case 'selectionandcomment':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )
          this.questionnaireFormGroup.addControl(
            hq.questionNameComment,
            new FormControl('', null)
          )

          break
        case 'selection':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )

          break
        case 'text':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )

          break
        case 'date':
          options = [minDateValidator(this.dateService)]
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', options),
          )

          break
        case 'multilinetext':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )

          break
        case 'button':
          // Buttons cannot be added as input controls
          break
        case 'grid':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )

          break
        case 'multipleselectionwithsubquestion':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )

          break
        case 'radio':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )

          break
        case 'groupstart':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )
          // GroupStart should never be visible
          hq.isVisible = false

          break
        case 'groupend':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )
          // GroupEnd should never be visible
          hq.isVisible = false

          break
        case 'grouplabel':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )
          hq.questionRequired = false
          hq.questionRequiredIndicator = null

          break
        case 'slider':
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )
          hq.listItemLabel = 'Unselected'

          break
        default:
          // If not one of the above, just add it without validators
          this.questionnaireFormGroup.addControl(
            hq.questionName,
            new FormControl('', null)
          )

          break
      }

      // Set disabled property according to settings
      // Button cannot be part of the form group - enable/disable for buttons must be handled in HTML
      if (hq.questionTypeName.toLowerCase() != 'button') {
        if (hq.questionDisabled) {
          this.questionnaireFormGroup.controls[hq.questionName].disable()
          if (this.questionnaireFormGroup.controls[hq.questionNameComment]) {
            this.questionnaireFormGroup.controls[hq.questionNameComment].disable()
          }
        } else {
          this.questionnaireFormGroup.controls[hq.questionName].enable()
          if (this.questionnaireFormGroup.controls[hq.questionNameComment]) {
            this.questionnaireFormGroup.controls[hq.questionNameComment].enable()
          }
        }
      }
    }

    // For reviews, populate the fields
    if (this.consultationSelection == 'Review' && !this.noData) {
      let name = ''
      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]
        name = hq.questionTypeName.toLowerCase()
        switch (name) {
          case 'numeric':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseValue)
            this.onChange(hq, { value: hq.responseValue }, hq.questionTypeName, 0)
            break
          case 'numericandcomment':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseValue)
            this.onChange(hq, { value: hq.responseValue }, hq.questionTypeName, 0)

            this.questionnaireFormGroup.controls[hq.questionNameComment].setValue(hq.responseComment)
            this.onChange(hq, { value: hq.responseComment }, 'comment', 0)
            break
          case 'decimal':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseValue)
            this.onChange(hq, { value: hq.responseValue }, hq.questionTypeName, 0)
            break
          case 'selection':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseOptionId)
            this.onChange(hq, { value: hq.responseOptionId }, hq.questionTypeName, 0)
            break
          case 'selectionandcomment':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseOptionId)
            this.onChange(hq, { value: hq.responseOptionId }, hq.questionTypeName, 0)

            this.questionnaireFormGroup.controls[hq.questionNameComment].setValue(hq.responseComment)
            this.onChange(hq, { value: hq.responseComment }, 'comment', 0)
            break
          case 'toggleandcomment':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseOptionId)
            this.onChange(hq, { value: hq.responseOptionId }, hq.questionTypeName)

            this.questionnaireFormGroup.controls[hq.questionNameComment].setValue(hq.responseComment)
            this.onChange(hq, { value: hq.responseComment }, 'comment', 0)
            break
          case 'text':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseResponseText)
            this.onChange(hq, { value: hq.responseResponseText }, hq.questionTypeName, 0)
            break
          case 'date':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseDateTime)
            this.onChange(hq, { value: hq.responseDateTime }, hq.questionTypeName, 0)
            break
          case 'multilinetext':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseResponseText)
            this.onChange(hq, { value: hq.responseResponseText }, hq.questionTypeName, 0)
            break
          case 'multipleselection':
            // if (hq.responseSelectionOptionsSelected.length > 0) {
            //   let values = hq.responseSelectionOptionsSelected;
            //   this.questionnaireFormGroup.controls[hq.questionName].setValue(values);
            // }
            //this.onChange(hq, { value: hq.responseOptionId }, hq.questionTypeName, index);
            break
          case 'multipleselectionandcomment':
            // if (hq.responseSelectionOptionsSelected.length > 0) {
            //   let values = hq.responseSelectionOptionsSelected;
            //   this.questionnaireFormGroup.controls[hq.questionName].setValue(values);
            // }
            this.questionnaireFormGroup.controls[hq.questionNameComment].setValue(hq.responseComment)
            break
          case 'grouplabel':
            this.onChange(hq, { value: hq.responseResponseText }, hq.questionTypeName, 0)
            break
          case 'slider':
            let option = hq.listItems.find(r => r.id == hq.responseOptionId);
            let index: number = 0;
            if (option) {
              index = hq.listItems.indexOf(option);
            }
            this.questionnaireFormGroup.controls[hq.questionName].setValue(index)
            this.onChange(hq, { value: hq.responseOptionId }, hq.questionTypeName, 0)
            break
          case 'radio':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseOptionId)
            this.onChange(hq, { value: hq.responseOptionId }, hq.questionTypeName, 0)
            break
          case 'grid':
            if (hq.responseResponseText?.length) {
              hq.questionGridData = JSON.parse(hq.responseResponseText)
            }

            break
          case 'multipleselectionwithsubquestion':
            this.questionnaireFormGroup.controls[hq.questionName].setValue(hq.responseValue)
            this.onChange(hq, { value: hq.responseValue }, hq.questionTypeName, 0)
            break
          default:
            break
        }
      }
    }

    if (visibleCount == 0) {
      this.recurse(keys, this.hierarchicalQuestionnaire, (hq) => {
        if (hq.groupId == currentGroupId) {
          hq.showHeader = false
        }
      })
    }

    if (
      this.consultationSelection == 'Questionnaire' ||
      this.consultationSelection == 'Survey' ||
      this.noData
    ) {
      this.responseObject = {
        reviewId: '',
        reviewDate: new Date(),
        questionnaireId: this.questionnaireId,
        responseItems: [],
      }
    }

    // Add indentation levels according to group level rules
    let level = 0
    for (let index = 0; index < keys.length; index++) {
      let hq = this.hierarchicalQuestionnaire[index]
      hq.questionGroupLevel = level

      // Set styles
      hq.questionIndentStyle = (level * 40).toString() + 'px'
      hq.questionBackgroundColorStyle =
        'rgb(' +
        (255 - level * 10).toString() +
        ',' +
        (255 - level * 10).toString() +
        ',' +
        +(255 - level * 10).toString() +
        ')'
      if (level == 0) {
        hq.questionBorderStyle = '0px'
        hq.questionPaddingTopStyle = '0px'
        hq.questionPaddingLeftStyle = '0px'
        hq.questionPaddingRightStyle = '0px'
      } else {
        hq.questionBorderStyle = '0px'
        hq.questionPaddingTopStyle = '10px'
        hq.questionPaddingLeftStyle = '10px'
        hq.questionPaddingRightStyle = '10px'
      }

      if (hq.questionTypeName.toLowerCase() == 'groupstart') {
        level++
      }
      if (hq.questionTypeName.toLowerCase() == 'groupend') {
        level--
      }
    }

    this.isLoading = false
  }

  recurse(keys: string[], list: any, cb: Function): void {
    for (var i = 0; i < keys.length; i++) {
      cb.call(this, list[i])
    }
  }

  // TODO  Add new marker


  measurementsArray(response: ResponseObject) {

    let bloodPressureResults = {
      diastolic: "",
      systolic: "",
    }

    response.responseItems.forEach(item => {
      let measurement = {
        name: '',
        type: 'Health Risk Assessment',
        date: this.formatDateWithOffset(this.responseObject?.reviewDate),
        results: {}
      }

      switch (item.question.questionIdentifier) {
        case 'WEIGHT':
          measurement.name = "Weight"
          let unitWeight = Math.floor(item?.value);
          measurement.results = {
            result: unitWeight?.toString()
          }
          this.healthyHeartScore.bodyParams.measurements.push(measurement)
          break

        case 'HEIGHT':
          measurement.name = "Height";
          let unitConversion = Math.floor(item?.value * 100);
          measurement.results = {
            result: unitConversion?.toString()
          }
          this.healthyHeartScore.bodyParams.measurements.push(measurement)
          break
        case 'BLOODGLUCOSE':
          measurement.name = 'Glucose - Random';
          let unitGlucose = Math.floor(item?.value);
          measurement.results = {
            result: unitGlucose?.toString()
          }
          this.healthyHeartScore.bodyParams.measurements.push(measurement)
          break
        case 'CHOLESTEROL':
          measurement.name = "Cholesterol"
          measurement.results = {
            result: item.value?.toString()
          }
          this.healthyHeartScore.bodyParams.measurements.push(measurement)
          break
        case 'WAIST':
          measurement.name = "Waist";
          let unitWaist = Math.floor(item?.value);
          measurement.results = {
            result: unitWaist?.toString()
          }
          this.healthyHeartScore.bodyParams.measurements.push(measurement)
          break
        case 'PHRAWS06':
          measurement.name = "Non-Smoking Declaration";
          let smokeResult = "";
          if (item.question.responseOptionId == "cefd39fb-e9b1-4ad7-a618-5fe0fa4351ff")
            smokeResult = "Smoker";
          else
            smokeResult = "Non-Smoker";
          // item.question.
          measurement.results = {
            result: smokeResult
          }
          this.healthyHeartScore.bodyParams.measurements.push(measurement)
          // Add logic for pregnant question when introduced
          break
      }


      if (item.question.questionIdentifier == 'DIASTOLIC')
        bloodPressureResults.diastolic = Math.floor(item.value).toString();
      if (item.question.questionIdentifier == 'SYSTOLIC')
        bloodPressureResults.systolic = Math.floor(item.value).toString();
      if (item.question.questionIdentifier == 'DIASTOLIC') {
        measurement.name = "Blood Pressure";
        measurement.results = bloodPressureResults;
        this.healthyHeartScore.bodyParams.measurements.push(measurement)
      }

    })
    // pregancy option, to be removed when pregnant question is added
    let pregObj = {
      name: 'Pregnant',
      type: 'Health Risk Assessment',
      date: this.formatDateWithOffset(this.responseObject?.reviewDate),
      results: {
        result: "No"
      }
    }
    this.healthyHeartScore.bodyParams.measurements.push(pregObj);

  }

  formatDateWithOffset(input: Date | string): string {
    const date = typeof input === 'string' ? new Date(input) : input;

    if (isNaN(date.getTime())) {
      throw new Error("Invalid Date");
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    const offsetMinutes = date.getTimezoneOffset();
    const absOffset = Math.abs(offsetMinutes);
    const offsetHours = String(Math.floor(absOffset / 60)).padStart(2, '0');
    const offsetMinutesStr = String(absOffset % 60).padStart(2, '0');
    const offsetSign = offsetMinutes > 0 ? '-' : '+';

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutesStr}`;
  }


  submit(): void {
    this.isSubmit = true
    this.isLoading = true

    // Validation

    // Disable all controls which are not visible so that they are not validated
    // this prevents instances where a hidden control was displayed, hanged, and hidden again (ie. should be ignored)
    var keys = Object.keys(this.hierarchicalQuestionnaire)
    for (let index = 0; index < keys.length; index++) {
      let hq = this.hierarchicalQuestionnaire[index]
      hq.questionDisabledForValidation = false
      if (!hq.isVisible) {
        if (!this.questionnaireFormGroup.controls[hq.questionId].disabled) {
          this.questionnaireFormGroup.controls[hq.questionId].disable()
          hq.questionDisabledForValidation = true
        }
      }
    }
    if (this.showContraindicatedComment) {
      this.examinationFormGroup.controls['contraindicatedComment'].enable()
      this.examinationFormGroup.controls['contraindicatedSelection'].enable()
    } else {
      this.examinationFormGroup.controls['contraindicatedComment'].disable()
      this.examinationFormGroup.controls['contraindicatedSelection'].disable()
    }

    if (this.showQuestionnaireComment) {
      this.examinationFormGroup.controls['examinationComment'].enable()
    } else {
      this.examinationFormGroup.controls['examinationComment'].disable()
    }

    Object.keys(this.questionnaireFormGroup.controls).forEach((key) => {
      this.questionnaireFormGroup.controls[key].markAsTouched()
    })

    this.questionnaireFormGroup.updateValueAndValidity()

    let visibleMSVEnabledQuestionIds = this.multipleSelectionQuestions.filter((d) => d.questionRequired && d.isVisible && !d.questionDisabledForValidation).map(d => d.questionId);
    let responses = this.responseItems.filter(r => visibleMSVEnabledQuestionIds.indexOf(r.questionId.toLowerCase()) > -1)
    let msValidationFailed = visibleMSVEnabledQuestionIds.length > 0
      && (responses.length == 0
        || responses.some(r => (!r.selectionOptionsSelected || r.selectionOptionsSelected.length == 0)));


    let sliders = this.hierarchicalQuestionnaire.filter(
      (d) => d.questionTypeName.toLowerCase() == 'slider'
    )
    sliders.forEach((d) => {
      d.hasIssue = false
      d.questionClass['invalid'] = false
    })
    let unvalidatedSliders = sliders.filter(
      (d) =>
        d.isVisible &&
        !d.questionDisabledForValidation &&
        d.questionRequired &&
        (d.listItemLabel == 'Unselected' || d.listItemLabel == 'Click and drag slider')
    )
    unvalidatedSliders.forEach((d) => {
      Object.keys(d.questionClass).forEach((k) => (d.questionClass[k] = false))
      d.questionClass['invalid'] = true
      d.hasIssue = true
    })

    if (
      this.examinationFormGroup.valid
      && (
        this.showQuestionnaire
        && this.questionnaireFormGroup.valid
        && unvalidatedSliders.length == 0
        && !msValidationFailed
        || !this.showQuestionnaire)
    ) {
      this.showError = false

      // Check if the Done-value was selected as result
      if (!this.showQuestionnaire) {
        switch (this.examinationResultName) {
          case 'Not done':
            this.examinationResult = 2
            break
          case 'Done':
            this.examinationResult = 0
            break
          case 'Contraindicated':
            this.examinationResult = 3
            break
          case 'Not required':
            this.examinationResult = 5
            break
          case 'Refused':
            this.examinationResult = 7
            break

          default:
            break
        }
        this.examinationNotes.push({
          user: this.userFullName,
          //this.userProfile.firstName + ' ' + this.userProfile.lastName,
          dateTime: new Date(),
          notes: this.examinationComment,
          isNew: false,
        })
      } else {
        this.examinationResult = 0
        this.examinationComment = null
      }

      this.responseObject.responseItems = this.responseItems

      // Check if any question has a QuestionnaireResult option - if so, the value of the question must be stored in the questionnaireResult property for saving
      // Note: Currently only designed to work with dropdowns
      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]

        // Check for options
        var optKeys = Object.keys(hq.options)
        if (optKeys.length > 0) {
          for (let optIndex = 0; optIndex < optKeys.length; optIndex++) {
            if (
              hq.options[optIndex].discriminator.toLowerCase() == 'questionnaireresult'
            ) {
              this.questionnaireResult = null

              let checkList: string[] = hq.options[optIndex].value
                .toLowerCase()
                .split(',')

              this.responseItems.forEach((item) => {
                if (item.questionId.toLowerCase() == hq.questionId.toLowerCase()) {
                  if (checkList.indexOf(item.listItemId.toLowerCase()) > -1) {
                    this.questionnaireResult = 0
                  } else {
                    this.questionnaireResult = 1
                  }
                }
              })
            }
          }
        }
      }

      // Clean up empty listitem lists and dates
      var keys = Object.keys(this.responseItems)

      if (keys.length > 0) {
        for (let itemIndex = 0; itemIndex < keys.length; itemIndex++) {
          if (this.responseObject.responseItems[itemIndex].listItemId) {
            if (
              this.responseObject.responseItems[itemIndex].listItemId.length == undefined
            ) {
              this.responseObject.responseItems[itemIndex].listItemId = null
            }
          }
          if (!Number(this.responseObject.responseItems[itemIndex].value)) {
            this.responseObject.responseItems[itemIndex].value = null
          }
          if (this.responseObject.responseItems[itemIndex].dateTime != null) {
            if (!this.isDate(this.responseObject.responseItems[itemIndex].dateTime)) {
              this.responseObject.responseItems[itemIndex].dateTime = null
            } else {
              this.responseObject.responseItems[itemIndex].dateTime = this.fixDate(
                this.responseObject.responseItems[itemIndex].dateTime
              )
            }
          }
          if (this.responseObject.responseItems[itemIndex].responseText != null) {
            if (
              typeof this.responseObject.responseItems[itemIndex].responseText !==
              'string'
            ) {
              this.responseObject.responseItems[itemIndex].responseText = null
            }
          }
        }
      }

      let consultation = JSON.parse(localStorage?.getItem('consultationDetail'));
      let clincian = JSON.parse(localStorage?.getItem('Clinician'));

      console.log("Clinician", clincian);

      this.healthyHeartScore.bodyParams.clientDetails.cellNumber = consultation.
        patientDetail?.cellPhone;
      this.healthyHeartScore.bodyParams.clientDetails.email = consultation.patientDetail?.email;
      this.healthyHeartScore.bodyParams.providerDetails.assessorName = clincian?.name;
      this.healthyHeartScore.bodyParams.providerDetails.location = consultation.adhocEvent?.address;
      let rsaidnumber = consultation.patientDetail?.rsaidnumber;
      let passportnumber = consultation.patientDetail?.passportNo;
      this.measurementsArray(this.responseObject);
      this.healthyHeartScore.orderMeasurements();
      localStorage.setItem("reponseObject", JSON.stringify(this.responseObject));
      //let consentStatus = JSON.parse(localStorage.getItem("consentStatus"));

      if (this.allowSubmit) {
        if (this.consultationSelection == 'Questionnaire') {
          //this.healthyHeartScore.heartScore();
          //console.log(this.responseObject)


          this.questionnaireService
            .postQuestionnaire(this.responseObject)
            .subscribe((result) => {
              var name = this.userFullName
              // this.userProfile
              //   ? this.userProfile.firstName + ' ' + this.userProfile.lastName
              //   : this.userProfile.username

              // calling the multiply api to download healthy-heart-score-pdf

              console.log("consultationDetail Object", consultation);
              const clinicianId = localStorage &&
                localStorage['Clinician'] &&
                JSON.parse(localStorage.Clinician)
                ? JSON.parse(localStorage.Clinician).id
                : '7854d0dc-a028-4b9c-a166-5a945b9af7b8';
              if (result.reviewId && this.consultationId && clinicianId) {
                this.testPerformed = {
                  active: true,
                  clinicianId: clinicianId,
                  completed: true,
                  consultationId: this.consultationId,
                  createDate: new Date(),
                  createName: name,
                  notes: this.examinationNotes,
                  result: this.examinationResult,
                  resultValue: this.examinationResult,
                  reviewId: result.reviewId,
                  testMappingId: this.testRequested.testMappingId,
                  testRequestedId: this.testRequested.id,
                  changeDate: null,
                  changeName: null,
                  subTestMappingId: null,
                  contraIndicationComment: this.showContraindicatedComment
                    ? this.examinationFormGroup.controls['contraindicatedComment'].value
                    : null,
                  contraIndicationId: this.showContraindicated
                    ? this.examinationFormGroup.controls['contraindicatedSelection'].value
                    : null,
                  examStatusComment: this.showQuestionnaireComment
                    ? this.examinationFormGroup.controls['examinationComment'].value
                    : null,
                  questionnaireResult: this.questionnaireResult,
                }

                this.questionnaireService
                  .postTestPerformed(this.testPerformed)
                  .subscribe((postResult) => {
                    this.testRequested.status = 3
                    this.testRequested.changeDate = new Date()
                    this.testRequested.changeName = name
                    this.questionnaireService
                      .putTestStatus(this.testRequested)
                      .subscribe((theresult) => {
                        if (this.responseObject.questionnaireId == 'cf61a7a5-a53c-49a4-b045-198f97a1dc70' && consultation.patientDetail.medicalAidId.toUpperCase() == "528189BB-27EB-469B-B281-1D78A7C7C15A") {
                          let auditObj = {
                            cId: consultation.id,
                            pId: consultation.patientDetailId,
                            createBy: this.userFullName,
                            rsaidnumber: rsaidnumber,
                            passportnumber: passportnumber
                          }
                          this.callMultiplyAPI(rsaidnumber, auditObj, passportnumber)

                          // this.healthyHeartScore.heartScore(rsaidnumber).subscribe(
                          //   ({ status, body }) => {
                          //     console.log('API Status Code:', status);

                          //   },
                          //   error => {
                          //     console.error('Error:', error);
                          //   }
                          // );

                        }
                      })
                    this.onSaved.emit(this.testPerformed)
                  })
              } else {
                alert('unable to submit')
                this.isLoading = false
              }
            })
        } else if (this.consultationSelection == 'Review') {
          this.responseObject.reviewDate = new Date();
          if (this.noData) {
            this.responseObject.reviewId = this.testPerformed.reviewId
          }

          const clinicianId = localStorage &&
            localStorage['Clinician'] &&
            JSON.parse(localStorage.Clinician)
            ? JSON.parse(localStorage.Clinician).id
            : null
          this.questionnaireService.putQuestionnaire(this.responseObject, clinicianId).subscribe({
            next: (result) => {
              this.testPerformed = {
                id: this.testPerformed.id,
                active: true,
                clinicianId: clinicianId,
                completed: true,
                consultationId: this.consultationId,
                createDate: this.testPerformed.createDate,
                createName: this.testPerformed.createName,
                notes: this.examinationNotes,
                result: this.examinationResult,
                resultValue: this.examinationResult,
                reviewId: this.testPerformed.reviewId,
                testMappingId: this.testPerformed.testMappingId,
                testRequestedId: this.testPerformed.testRequestedId,
                changeDate: new Date(),
                changeName: 'Perigon-UI',
                subTestMappingId: null,
                contraIndicationComment: this.showContraindicatedComment
                  ? this.examinationFormGroup.controls['contraindicatedComment'].value
                  : null,
                contraIndicationId: this.showContraindicated
                  ? this.examinationFormGroup.controls['contraindicatedSelection'].value
                  : null,
                examStatusComment: this.showQuestionnaireComment
                  ? this.examinationFormGroup.controls['examinationComment'].value
                  : null,
                questionnaireResult: this.questionnaireResult,
              }
              this.questionnaireService
                .putTestPerformed(this.testPerformed)
                .subscribe((postResult) => {
                  this.testRequested.status = 3
                  this.testRequested.changeDate = new Date()
                  this.testRequested.changeName = 'Perigon-UI'
                  this.questionnaireService
                    .putTestStatus(this.testRequested)
                    .subscribe((theresult) => {

                      if (this.responseObject.questionnaireId == 'cf61a7a5-a53c-49a4-b045-198f97a1dc70' && consultation.patientDetail.medicalAidId.toUpperCase() == "528189BB-27EB-469B-B281-1D78A7C7C15A") {
                        let auditObj = {
                          consultationId: consultation.id,
                          patientId: consultation.patientDetailId,
                          createdBy: this.userFullName,
                          rsaidnumber: rsaidnumber,
                          passportnumber: passportnumber
                        }
                        this.callMultiplyAPI(rsaidnumber, auditObj, passportnumber);

                      }

                    })
                  this.onSaved.emit(this.testPerformed)
                })
            },
            error: (error) => {
              /* console.log(error) */
            },
            complete: () => {
              this.isLoading = false
            },
          })
        } else if (this.consultationSelection == 'Survey') {
          this.responseObject.reviewId = this.reviewId
          this.adhocService.postWCG(this.responseObject).subscribe((result) => {
            this.onSaved.emit(null)
          })
        }
      } else {
        this.showError = false
        this.isLoading = false
      }

    } else {
      this.showError = true
      this.isLoading = false
    }

    // Re-enable controls that were disabled for validation
    var keys = Object.keys(this.hierarchicalQuestionnaire)
    for (let index = 0; index < keys.length; index++) {
      let hq = this.hierarchicalQuestionnaire[index]
      if (hq.questionDisabledForValidation) {
        this.questionnaireFormGroup.controls[
          hq.questionId
        ].enable()
        hq.questionDisabledForValidation = false
      }
    }

    this.isSubmit = false
  }

  onChange(obj: HierarchicalQuestionObject, event, type, i?): void {
    if (this.stackLevel < 1) {
      obj.sliderColor = obj.sliderColor || 'red'
      this.stackLevel++

      const time = new Date()

      type = type.toLowerCase()

      let eventResult = event

      if (!['comment', 'text', 'multilinetext'].includes(type)) {
        if (event != null && isNaN(Number(event))) {
          if (event['value'] != null) {
            eventResult = event['value']
          } else {
            eventResult = null
          }
        }
      }

      let response: ResponseItemObject

      const index = this.responseItems.findIndex(
        (search) => search.questionId == obj.questionId
      )
      let finalResult: string | number | Date | null = undefined
      // Do not process values for buttons
      if (type != 'button') {
        if (index > -1) {
          if (type == 'multipleselection' || type == 'multipleselectionandcomment') {
            eventResult = this.getForItem(
              obj,
              event,
              i,
              this.responseItems[index].selectionOptionsSelected
            )
          }

          if (type == 'multipleselectionwithsubquestion' || type == 'toggle') {
            if (event.hasOwnProperty('checked')) {
              eventResult = event.checked ? 1 : 0
            }
            if (eventResult != null) {
              if (eventResult != 1) {
                eventResult = 0
              }
            } else {
              eventResult = 0
            }
          }
          //   if (event.checked != undefined) {
          //     if (event.checked) {
          //       eventResult = 1;
          //     }
          //     else {
          //       eventResult = 0;
          //     }
          //   }
          //   else {
          //     if (eventResult != null) {
          //       if (eventResult != 1) {
          //         eventResult = 0;
          //       }
          //     }
          //     else {
          //       eventResult = 0;
          //     }
          //   }
          // }

          if (type == 'grid') {
            eventResult = this.gridToString(event)
          }
          if (type == 'radio') {
            obj.responseOptionId = eventResult || event;
          }
          if (type != 'multipleselection' && type != 'multipleselectionandcomment') {
            eventResult = eventResult || event
          }
          if (type == 'slider') {
            if (eventResult.length == 36) {
              obj.listItemLabel = obj.listItems.find(
                (d) => d.id.toLowerCase() == eventResult.toLowerCase()
              ).description
            } else {
              obj.listItemLabel = obj.listItems[eventResult].description
              eventResult = obj.listItems[eventResult].id
            }

            if (type == 'slider') {
              obj.hasIssue = false
              obj.questionClass['invalid'] = false
              if (obj.questionClasses) {
                for (var prop in obj.questionClass) {
                  obj.questionClass[prop] = false
                }
                obj.questionClass[obj.questionClasses[eventResult.toUpperCase()]] = true
              }
            }
          }


          if (type == 'toggle') {
            let resObj = obj.listItems[eventResult.checked ? 1 : 0];
            obj.listItemLabel = resObj.description;
            eventResult = resObj.id;
          }

          let item = this.responseItems[index]

          let getValue = (prop) =>
            prop && prop.hasOwnProperty('value') ? prop.value : prop

          var selectionOptionsSelected = [
            'multipleselection',
            'multipleselectionandcomment',
          ].includes(type)
            ? getValue(eventResult)
            : getValue(item.selectionOptionsSelected),
            comment = type === 'comment' ? getValue(eventResult) : getValue(item.comment),
            date = type === 'date' ? getValue(eventResult) : getValue(item.dateTime),
            value = ['decimal', 'numeric', 'multipleselectionwithsubquestion'].includes(
              type
            )
              ? getValue(eventResult)
              : getValue(item.value),
            listItemId = [
              'selection',
              'selectionandcomment',
              'slider',
              'toggle',
              'radio',
            ].includes(type)
              ? getValue(eventResult)
              : getValue(item.listItemId),
            signature = ['signature'].includes(type)
              ? getValue(eventResult)
              : getValue(item.signature),
            timeValue = ['time'].includes(type)
              ? getValue(eventResult)
              : getValue(item.time),
            responseText = ['text', 'multilinetext', 'grid'].includes(type)
              ? getValue(eventResult)
              : getValue(item.responseText)

          finalResult = listItemId?.toLowerCase() || date || timeValue || value || responseText
          this.responseItems[index] = {
            questionId: obj.questionId,
            groupQuestionAssociationId: obj.groupQuestionAssociationId,
            selectionOptionsSelected: selectionOptionsSelected,
            comment: comment,
            dateTime: date,
            result: null,
            value: value,
            listItemId: listItemId,
            signature: signature,
            time: timeValue,
            responseText: responseText,
            contoller: null,
            endPoint: null,
            method: null,
            parameters: null,
            serverRequestResponse: null,
            question: obj,
          }
          // this.responseItem[index] = {
          //   questionId: obj.questionId,
          //   groupQuestionAssociationId: obj.groupQuestionAssociationId,
          //   selectionOptionsSelected: type == "multipleselection" ? eventResult : type == "multipleselectionandcomment" ? eventResult : this.responseItem[index].selectionOptionsSelected,
          //   comment: type === 'comment' ? eventResult : this.responseItem[index].comment === null ? null : this.responseItem[index].comment,
          //   dateTime: type === 'date' ? eventResult : this.responseItem[index].dateTime,
          //   result: null,
          //   value: type == 'decimal' || type == 'numeric' || type == 'multipleselectionwithsubquestion' ? eventResult : this.responseItem[index].value,
          //   listItemId: type == 'selection' || type === 'selectionandcomment' || type == 'toggle' || type == 'toggleandcomment' ? eventResult : this.responseItem[index].listItemId,
          //   signature: type == 'signature' ? eventResult : this.responseItem[index].signature,
          //   time: type == 'time' ? eventResult : this.responseItem[index].time,
          //   responseText: type == 'text' || type == 'multilinetext' || type == 'grid' ? eventResult : this.responseItem[index].responseText,
          //   contoller: null,
          //   endPoint: null,
          //   method: null,
          //   parameters: null,
          //   serverRequestResponse: null
          // };
        } else {
          if (type == 'multipleselection' || type == 'multipleselectionandcomment') {
            eventResult = this.getForItem(obj, event, i, "")
          }

          if (type == 'multipleselectionwithsubquestion' || type == 'toggle') {
            if (event['checked']) {
              eventResult = 1
            } else {
              eventResult = 0
            }
          }

          if (type == 'grid') {
            eventResult = this.gridToString(event)
          }
          eventResult = eventResult || event
          if (type == 'slider') {
            if (eventResult.length == 36) {
              obj.listItemLabel = obj.listItems.find(
                (d) => d.id.toLowerCase() == eventResult.toLowerCase()
              ).description
            } else {
              obj.listItemLabel = obj.listItems[eventResult].description
              eventResult = obj.listItems[eventResult].id
            }
            if (type == 'slider') {
              obj.hasIssue = false
              obj.questionClass['invalid'] = false
              if (obj.questionClasses) {
                for (var prop in obj.questionClass) {
                  obj.questionClass[prop] = false
                }
                obj.questionClass[obj.questionClasses[eventResult.toUpperCase()]] = true
              }
            }
          }

          if (type == 'toggle') {
            let resObj = obj.listItems[eventResult.checked ? 1 : 0];
            obj.listItemLabel = resObj.description;
            eventResult = resObj.id;
          }

          var val = (eventResult && eventResult.hasOwnProperty('value')) ? eventResult.value : eventResult
          finalResult = val == null ? null : (typeof (val) == "string" ? val.toLowerCase() : val);
          var selectionOptionsSelected = [
            'multipleselection',
            'multipleselectionandcomment',
          ].includes(type)
            ? val
            : null,
            comment = type === 'comment' ? val : null,
            date = type === 'date' ? val : null,
            value = ['decimal', 'numeric', 'multipleselectionwithsubquestion'].includes(
              type
            )
              ? val
              : null,
            listItemId = [
              'selection',
              'selectionandcomment',
              'slider',
              'toggle',
              'radio',
            ].includes(type)
              ? val
              : null,
            signature = ['signature'].includes(type) ? val : null,
            timeValue = ['time'].includes(type) ? val : null,
            responseText = ['text', 'multilinetext', 'grid'].includes(type) ? val : null
          response = {
            questionId: obj.questionId,
            selectionOptionsSelected: selectionOptionsSelected,
            groupQuestionAssociationId: obj.groupQuestionAssociationId,
            comment: comment,
            dateTime: date,
            result: null,
            value: value,
            listItemId: listItemId,
            signature: signature,
            time: timeValue,
            responseText: responseText,
            contoller: null,
            endPoint: null,
            method: null,
            parameters: null,
            serverRequestResponse: null,
            question: obj,
          }
          this.responseItems.push(response)
        }
      }

      // Enumerate the hierarchical questionnaire structure
      var keys = Object.keys(this.hierarchicalQuestionnaire)

      // Check if we need to show or hide comments
      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]
        // Check for options
        var optKeys = Object.keys(hq.options)

        if (optKeys.length > 0) {
          for (let optIndex = 0; optIndex < optKeys.length; optIndex++) {
            if (hq.options[optIndex].discriminator.toLowerCase() == 'commentoption') {
              if (hq.options[optIndex].attribute.toLowerCase() == 'selected-any') {
                let found = false
                this.responseItems.forEach((item) => {
                  if (item.questionId.toLowerCase() == hq.questionId.toLowerCase()) {
                    if (item.listItemId != null) {
                      if (item.listItemId.length > 0) {
                        found = true
                        hq.commentAvailable = true
                        hq.commentRequired = true
                      }
                    } else {
                      if (item.selectionOptionsSelected != null) {
                        if (item.selectionOptionsSelected.length > 0) {
                          found = true
                          hq.commentAvailable = true
                          hq.commentRequired = true
                        }
                      }
                    }
                  }
                })
                if (!found) {
                  hq.commentRequired = false
                }
              }
              if (hq.options[optIndex].attribute.toLowerCase() == 'selected-option') {
                this.responseItems.forEach((item) => {
                  if (item.questionId.toLowerCase() == hq.questionId.toLowerCase()) {
                    if (item.listItemId) {
                      if (item.listItemId.length > 0) {
                        if (
                          item.listItemId.toLowerCase() ==
                          hq.options[optIndex].value.toLowerCase()
                        ) {
                          hq.commentAvailable = true
                          hq.commentRequired = true
                        } else {
                          hq.commentRequired = false
                        }
                      }
                    } else {
                      hq.commentRequired = false
                    }
                  }
                })
              }
            }
          }
        }
      }

      // Check if we need to activate or deactivate questions according to options
      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]
        let moo: RISK_RATING
        // Check the options - Handle visibility options
        // Enumerate the options for the current record
        var optKeys = Object.keys(hq.options)
        //let found = true;
        let truthTable: boolean[] = []
        let truthTableType: string[] = []

        if (optKeys.length > 0) {
          for (let optIndex = 0; optIndex < optKeys.length; optIndex++) {
            // Process display options affecting visibility
            if (hq.options[optIndex].discriminator.toLowerCase() == 'displayoption') {
              if (hq.options[optIndex].attribute.toLowerCase() == 'any-selected-option') {
                // Check if the lookup question has been completed
                let respFound = false
                let options = hq.options[optIndex].value.split(',')

                if (options.length == 1) {
                  let q = this.responseItems.find(
                    (s) => s.questionId.toLowerCase() == options[0].toLowerCase()
                  )
                  if (q) {
                    if (q.listItemId) {
                      if (q.listItemId.length > 0) {
                        //found = found && true;
                        truthTable.push(true)
                        truthTableType.push('or')
                      } else {
                        //found = false;
                        truthTable.push(false)
                        truthTableType.push('or')
                      }
                    }
                    if (q.selectionOptionsSelected) {
                      if (q.selectionOptionsSelected.length > 0) {
                        //found = found && true;
                        truthTable.push(true)
                        truthTableType.push('or')
                      } else {
                        //found = false;
                        truthTable.push(false)
                        truthTableType.push('or')
                      }
                    }

                    respFound = true
                  }
                } else if (options.length > 1) {
                  let qId = options.shift().toLowerCase()
                  let q = this.responseItems.find((s) => s.questionId.toLowerCase() == qId)
                  const range = options.map((element) => {
                    return element.toLowerCase()
                  })
                  if (q) {
                    if (q.listItemId) {
                      if (range.indexOf(q.listItemId.toLowerCase()) > -1) {
                        //found = found && true;
                        truthTable.push(true)
                        truthTableType.push('or')
                      } else {
                        //found = false;
                        truthTable.push(false)
                        truthTableType.push('or')
                      }
                    }
                    if (q.selectionOptionsSelected) {
                      if (q.selectionOptionsSelected.length > 0) {
                        let any = false
                        for (var m = 0; m < q.selectionOptionsSelected.length; m++) {
                          if (
                            range.indexOf(q.selectionOptionsSelected[m].toLowerCase()) >
                            -1
                          ) {
                            truthTable.push(true)
                            truthTableType.push('or')
                            any = true
                            break
                          }
                        }
                        //found = found && true;
                        if (!any) {
                          truthTable.push(false)
                          truthTableType.push('or')
                        }
                      } else {
                        //found = false;
                        truthTable.push(false)
                        truthTableType.push('or')
                      }
                    }

                    respFound = true
                  }
                }

                if (!respFound) {
                  truthTable.push(false)
                  truthTableType.push('or')
                }
              }

              if (hq.options[optIndex].attribute.toLowerCase() == 'selected-option') {
                let stringValues = (hq.options[optIndex].value || '')
                  .split(',')
                  .map((item) => item.trim())

                // make sure there are at least 2 values, first is the question ID, the rest are the selected values
                if (stringValues.length >= 2) {
                  let respFound = false
                  this.responseItems.forEach((item) => {
                    if (item.questionId.toLowerCase() == stringValues[0].toLowerCase()) {
                      for (let valueIndex = 1; valueIndex < stringValues.length; valueIndex++) {
                        if (item.listItemId) {
                          if (item.listItemId.length > 0) {
                            if (
                              item.listItemId.toLowerCase() ==
                              stringValues[valueIndex].toLowerCase()
                            ) {
                              //found = found && true;
                              truthTable.push(true)
                              truthTableType.push('or')
                            } else {
                              //found = false;
                              truthTable.push(false)
                              truthTableType.push('or')
                            }
                          }
                        } else {
                          if (item.selectionOptionsSelected) {
                            if (item.selectionOptionsSelected.length > 0) {
                              if (
                                item.selectionOptionsSelected
                                  .toLowerCase()
                                  .includes(stringValues[valueIndex].toLowerCase())
                              ) {
                                //found = found && true;
                                truthTable.push(true)
                                truthTableType.push('or')
                                break
                              } else {
                                //found = false;
                                truthTable.push(false)
                                truthTableType.push('or')
                              }
                            }
                          } else {
                            //found = false;
                            truthTable.push(false)
                            truthTableType.push('or')
                          }
                        }
                      }
                      respFound = true
                    }
                  })

                  if (!respFound) {
                    truthTable.push(false)
                    truthTableType.push('or')
                  }
                }
              }

              if (hq.options[optIndex].attribute.toLowerCase() == 'value-less') {
                let stringValues = hq.options[optIndex].value
                  .split(',')
                  .map((item) => item.trim())

                // make sure there are at least 2 values, first is the question ID, second is the value to check
                if (stringValues.length >= 2) {
                  //do not check more than one value, and it must be numeric
                  if (Number(stringValues[1])) {
                    let question = this.responseItems.find(
                      (e) => e.questionId.toLowerCase() == stringValues[0].toLowerCase()
                    )
                    if (question && Number(question.value)) {
                      if (question.value < +stringValues[1]) {
                        //found = found && true;
                        truthTable.push(true)
                        truthTableType.push('or')
                      } else {
                        //found = false;
                        truthTable.push(false)
                        truthTableType.push('or')
                      }
                    }
                  }
                }
              }

              if (hq.options[optIndex].attribute.toLowerCase() == 'value-greater') {
                let stringValues = hq.options[optIndex].value
                  .split(',')
                  .map((item) => item.trim())

                // make sure there are at least 2 values, first is the question ID, second is the value to check
                if (stringValues.length >= 2) {
                  //do not check more than one value, and it must be numeric
                  if (Number(stringValues[1])) {
                    this.responseItems.forEach((item) => {
                      if (
                        item.questionId.toLowerCase() == stringValues[0].toLowerCase()
                      ) {
                        if (Number(item.value)) {
                          if (item.value > +stringValues[1]) {
                            //found = found && true;
                            truthTable.push(true)
                            truthTableType.push('or')
                          } else {
                            //found = false;
                            truthTable.push(false)
                            truthTableType.push('or')
                          }
                        } else {
                          //found = false;
                          truthTable.push(false)
                          truthTableType.push('or')
                        }
                      }
                    })
                  }
                }
              }

              if (hq.options[optIndex].attribute.toLowerCase() == 'has-value') {
                let qId = hq.options[optIndex].value.toLowerCase()

                //do not check more than one value
                this.responseItems.forEach((item) => {
                  if (item.questionId.toLowerCase() == qId) {
                    if (item.value || item.time || item.dateTime || item.responseText) {
                      //found = found && true;
                      truthTable.push(true)
                      truthTableType.push('or')
                    } else {
                      //found = false;
                      truthTable.push(false)
                      truthTableType.push('or')
                    }
                  } else {
                    //found = false;
                    truthTable.push(false)
                    truthTableType.push('or')
                  }
                })
              }

              if (hq.options[optIndex].attribute.toLowerCase() == 'is-checked') {
                let stringValues = hq.options[optIndex].value
                  .split(',')
                  .map((item) => item.trim())

                // make sure there are at least 2 values, first is the question ID, second is the value to check
                if (stringValues.length >= 2) {
                  //do not check more than one value, and it must be numeric
                  if (Number(stringValues[1])) {
                    let respFound = false

                    this.responseItems.forEach((item) => {
                      if (
                        item.questionId.toLowerCase() == stringValues[0].toLowerCase()
                      ) {
                        if (Number(item.value)) {
                          if (item.value == +stringValues[1]) {
                            //found = found && true;
                            truthTable.push(true)
                            truthTableType.push('and')
                          } else {
                            //found = false;
                            truthTable.push(false)
                            truthTableType.push('and')
                          }
                        } else {
                          //found = false;
                          truthTable.push(false)
                          truthTableType.push('and')
                        }
                        respFound = true
                      }
                    })

                    if (!respFound) {
                      truthTable.push(false)
                      truthTableType.push('or')
                    }
                  }
                }
              }

              if (hq.options[optIndex].attribute.toLowerCase() == 'is-checked-multiple') {
                let stringValues = hq.options[optIndex].value
                  .split(',')
                  .map((item) => item.trim())

                var hit = 0
                var mis = 0

                // make sure there are at least 2 values, a set of question IDs, last is the value to check (true or flase)
                // values for all ids must validate to the value to be true
                if (stringValues.length >= 2) {
                  if (Number(stringValues[stringValues.length - 1])) {
                    this.responseItems.forEach((item) => {
                      if (
                        hq.options[optIndex].value
                          .toLowerCase()
                          .includes(item.questionId.toLowerCase())
                      ) {
                        if (Number(item.value)) {
                          if (item.value == +stringValues[stringValues.length - 1]) {
                            hit++
                          }
                        } else {
                          mis++
                        }
                      }
                    })

                    if (hit == stringValues.length - 1) {
                      //found = found && true;
                      truthTable.push(true)
                      truthTableType.push('and')
                    } else {
                      //found = false;
                      truthTable.push(false)
                      truthTableType.push('and')
                    }
                  }
                }
              }

              if (hq.options[optIndex].attribute.toLowerCase() == 'objectdependency') {
                let stringValues = hq.options[optIndex].value
                  .split(',')
                  .map((item) => item.trim().toLowerCase())

                if (stringValues.length == 3) {
                  let object = stringValues[0]
                  let property = stringValues[1]
                  let value = stringValues[2]

                  switch (object) {
                    case 'patient':
                      switch (property) {
                        case 'gender':
                          //found = found && (this.patient.gender.toLowerCase() == value);
                          //truthTable.push(this.patient.gender.toLowerCase() == value);
                          truthTable.push(this.patient.gender.toLowerCase() == value)
                          truthTableType.push('and')
                          break
                        default:
                          //found = false;
                          truthTable.push(false)
                          truthTableType.push('and')
                          break
                      }
                      break
                    default:
                      //found = false;
                      truthTable.push(false)
                      truthTableType.push('and')
                      break
                  }
                }
              }
            }
          }

          let found = false

          if (truthTable.length > 0) {
            found = truthTable[0]

            if (truthTable.length > 1) {
              for (let truthIndex = 1; truthIndex < truthTable.length; truthIndex++) {
                if (truthTableType[truthIndex] == 'or') {
                  found = found || truthTable[truthIndex]
                }
                if (truthTableType[truthIndex] == 'and') {
                  found = found && truthTable[truthIndex]
                }
              }
            }
          }

          if (found || truthTable.length == 0) {
            hq.isVisible = true

            // Also make children visible
            for (let i = 0; i < keys.length; i++) {
              const question = this.hierarchicalQuestionnaire[i];
              const parentId = question.groupQuestionAssociationParentId;

              if (parentId && parentId.toUpperCase() === hq.groupQuestionAssociationId.toUpperCase()) {
                question.isVisible = true;
              }
            }

          } else {
            this.resetQuestion(hq);
            this.hideChildren(hq.groupQuestionAssociationId);
          }
        }
      }

      let disableSet: Set<string> = new Set();
      let enableSet: Set<string> = new Set();

      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]

        enableSet.add(hq.questionId.toLowerCase());

        // Disable a specific question if this question has a value
        if (hq.options.some(opt => opt.discriminator.toLowerCase() == 'displayoption' && opt.attribute.toLowerCase() == 'disableifnotempty')) {
          let stringValue = hq.options.find(opt => opt.discriminator.toLowerCase() == 'displayoption' && opt.attribute.toLowerCase() == 'disableifnotempty').value.trim().toLowerCase()

          if (stringValue.length >= 1) {
            this.responseItems.forEach((item) => {
              if (item.questionId.toLowerCase() == hq.questionId.toLowerCase() && item.value && typeof item.value != 'object') {
                disableSet.add(stringValue);
              }
            })
          }
        }
      }

      // Remove from enableSet questions that should be disabled
      disableSet.forEach(disabledQuestion => enableSet.delete(disabledQuestion));

      // Now disable or enable questions accordingly
      this.hierarchicalQuestionnaire.forEach((hq) => {
        const questionId = hq.questionId.toLowerCase();

        if (disableSet.has(questionId)) {
          if (hq.questionTypeName.toLowerCase() != 'button') {
            this.questionnaireFormGroup.controls[hq.questionName].disable();
            if (this.questionnaireFormGroup.controls[hq.questionNameComment]) {
              this.questionnaireFormGroup.controls[hq.questionNameComment].disable();
            }
          } else {
            hq.questionDisabled = true;
          }
        }

        if (enableSet.has(questionId)) {
          if (hq.questionTypeName.toLowerCase() != 'button') {
            this.questionnaireFormGroup.controls[hq.questionName].enable();
            if (this.questionnaireFormGroup.controls[hq.questionNameComment]) {
              this.questionnaireFormGroup.controls[hq.questionNameComment].enable();
            }
          } else {
            hq.questionDisabled = false;
          }
        }
      });



      // Check the command options
      // Enumerate the options for the current record
      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]
        if (hq.isVisible) {
          var optKeys = Object.keys(hq.options)

          if (optKeys.length > 0) {
            for (let optIndex = 0; optIndex < optKeys.length; optIndex++) {
              if (hq.options[optIndex].discriminator.toLowerCase() == 'commandoption') {
                if (
                  hq.options[optIndex].attribute.toLowerCase() == 'value-greater-select'
                ) {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim())

                  // make sure there are 4 values, first is the question ID, second is the value to check, third the option if true, then option if false
                  if (stringValues.length == 4) {
                    // Value to check must be numeric
                    if (Number(stringValues[1])) {
                      this.responseItems.forEach((item) => {
                        if (
                          item.questionId.toLowerCase() == hq.questionId.toLowerCase()
                        ) {
                          if (Number(item.value)) {
                            var visKeys = Object.keys(this.hierarchicalQuestionnaire)
                            if (visKeys.length > 0) {
                              for (let visIndex = 0; visIndex < visKeys.length; visIndex++) {
                                let subHQ = this.hierarchicalQuestionnaire[visIndex];
                                if (
                                  subHQ.questionId.toLowerCase() ==
                                  stringValues[0].toLowerCase()
                                ) {
                                  if (item.value > +stringValues[1]) {
                                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                                    if (
                                      subHQ.questionType == 0 &&
                                      subHQ.questionReadOnly
                                    ) {
                                      this.questionnaireFormGroup.controls[subHQ.questionName
                                      ].setValue(
                                        this.getSelectedValueText(
                                          stringValues[0].toLowerCase(),
                                          stringValues[2].toLowerCase()
                                        )
                                      )
                                    } else {
                                      this.questionnaireFormGroup.controls[subHQ.questionName
                                      ].setValue(stringValues[2].toLowerCase())
                                    }
                                    this.setResponseDropdownListItem(
                                      stringValues[0].toLowerCase(),
                                      stringValues[2].toLowerCase()
                                    )
                                  } else {
                                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                                    if (
                                      subHQ.questionType == 0 &&
                                      subHQ.questionReadOnly
                                    ) {
                                      this.questionnaireFormGroup.controls[
                                        subHQ.questionName
                                      ].setValue(
                                        this.getSelectedValueText(
                                          stringValues[0].toLowerCase(),
                                          stringValues[3].toLowerCase()
                                        )
                                      )
                                    } else {
                                      this.questionnaireFormGroup.controls[
                                        subHQ.questionName
                                      ].setValue(stringValues[3].toLowerCase())
                                    }
                                    this.setResponseDropdownListItem(
                                      stringValues[0].toLowerCase(),
                                      stringValues[3].toLowerCase()
                                    )
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                    }
                  }
                }

                if (hq.options[optIndex].attribute.toLowerCase() == 'value-less-select') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim())

                  // make sure there are 4 values, first is the question ID, second is the value to check, third the option if true, then option if false
                  if (stringValues.length == 4) {
                    // Value to check must be numeric
                    if (Number(stringValues[1])) {
                      this.responseItems.forEach((item) => {
                        if (
                          item.questionId.toLowerCase() == hq.questionId.toLowerCase()
                        ) {
                          if (Number(item.value)) {
                            var visKeys = Object.keys(this.hierarchicalQuestionnaire)
                            if (visKeys.length > 0) {
                              for (let visIndex = 0; visIndex < visKeys.length; visIndex++) {
                                let subHQ = this.hierarchicalQuestionnaire[visIndex];
                                if (subHQ.questionId.toLowerCase() == stringValues[0].toLowerCase()) {
                                  if (item.value < +stringValues[1]) {
                                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                                    if (
                                      subHQ.questionType == 0 && subHQ.questionReadOnly
                                    ) {
                                      this.questionnaireFormGroup.controls[subHQ.questionName].setValue(
                                        this.getSelectedValueText(
                                          stringValues[0].toLowerCase(),
                                          stringValues[2].toLowerCase()
                                        )
                                      )
                                    } else {
                                      this.questionnaireFormGroup.controls[
                                        subHQ
                                          .questionName
                                      ].setValue(stringValues[2].toLowerCase())
                                    }
                                    this.setResponseDropdownListItem(
                                      stringValues[0].toLowerCase(),
                                      stringValues[2].toLowerCase()
                                    )
                                  } else {
                                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                                    if (
                                      subHQ
                                        .questionType == 0 &&
                                      subHQ
                                        .questionReadOnly
                                    ) {
                                      this.questionnaireFormGroup.controls[
                                        subHQ
                                          .questionName
                                      ].setValue(
                                        this.getSelectedValueText(
                                          stringValues[0].toLowerCase(),
                                          stringValues[3].toLowerCase()
                                        )
                                      )
                                    } else {
                                      this.questionnaireFormGroup.controls[
                                        subHQ
                                          .questionName
                                      ].setValue(stringValues[3].toLowerCase())
                                    }
                                    this.setResponseDropdownListItem(
                                      stringValues[0].toLowerCase(),
                                      stringValues[3].toLowerCase()
                                    )
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                    }
                  }
                }

                if (hq.options[optIndex].attribute.toLowerCase() == 'value-range-select') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim());

                  if (stringValues.length == 5 && Number(stringValues[1]) && Number(stringValues[2])) {
                    this.responseItems.forEach((item) => {
                      if (item.questionId.toLowerCase() == hq.questionId.toLowerCase()) {
                        var visKeys = Object.keys(this.hierarchicalQuestionnaire);
                        if (visKeys.length > 0) {
                          for (let visIndex = 0; visIndex < visKeys.length; visIndex++) {
                            let subHQ = this.hierarchicalQuestionnaire[visIndex];
                            if (subHQ.questionId.toLowerCase() == stringValues[0].toLowerCase()) {
                              let isReadOnly = subHQ.questionType == 0 && subHQ.questionReadOnly;
                              let valueToSet = stringValues[4].toLowerCase();

                              if (Number(item.value) && item.value >= +stringValues[1] && item.value <= +stringValues[2]) {
                                valueToSet = stringValues[3].toLowerCase();
                              }
                              let responseDropdownListItemValue = valueToSet;
                              if (isReadOnly) {
                                valueToSet = this.getSelectedValueText(stringValues[0].toLowerCase(), valueToSet);
                              }

                              this.questionnaireFormGroup.controls[subHQ.questionName].setValue(valueToSet);
                              this.setResponseDropdownListItem(stringValues[0].toLowerCase(), responseDropdownListItemValue);
                            }
                          }
                        }
                      }
                    });
                  }
                }


                if (hq.options[optIndex].attribute.toLowerCase() == 'value-range-clear') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim())

                  // make sure there are 3 values:
                  // - first is the question ID to clear
                  // - second is the lower range value to check
                  // - third is the higher range value to check
                  if (stringValues.length == 3) {
                    // Values to check must be numeric
                    if (Number(stringValues[1]) && Number(stringValues[2])) {
                      this.responseItems.forEach((item) => {
                        if (
                          item.questionId.toLowerCase() == hq.questionId.toLowerCase()
                        ) {
                          if (Number(item.value)) {
                            var visKeys = Object.keys(this.hierarchicalQuestionnaire)
                            if (visKeys.length > 0) {
                              for (let visIndex = 0; visIndex < visKeys.length; visIndex++) {
                                let subHQ = this.hierarchicalQuestionnaire[visIndex];
                                if (
                                  subHQ.questionId.toLowerCase() ==
                                  stringValues[0].toLowerCase()
                                ) {
                                  if (
                                    item.value >= +stringValues[1] &&
                                    item.value <= +stringValues[2]
                                  ) {
                                    this.questionnaireFormGroup.controls[
                                      subHQ
                                        .questionName
                                    ].setValue(null)
                                    this.setResponseDropdownListItem(
                                      stringValues[0].toLowerCase(),
                                      null
                                    )
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                    }
                  }
                }
              }
            }
          }
        }
      }

      // Check the calculation options
      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]
        if (hq.isVisible) {
          var optKeys = Object.keys(hq.options)

          if (optKeys.length > 0) {
            for (let optIndex = 0; optIndex < optKeys.length; optIndex++) {
              if (hq.options[optIndex].discriminator.toLowerCase() == 'calculation') {
                if (hq.options[optIndex].attribute.toLowerCase() == 'harvardstep') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim())

                  let pulse = Number.MIN_SAFE_INTEGER
                  let gender = ''
                  let age = Number.MIN_SAFE_INTEGER

                  this.responseItems.forEach((item) => {
                    if (item.questionId.toLowerCase() == stringValues[0].toLowerCase()) {
                      // Refers to pulse question
                      if (Number(item.value)) {
                        pulse = +item.value
                      }
                    }
                    if (item.questionId.toLowerCase() == stringValues[1].toLowerCase()) {
                      // Refers to gender question
                      if (item.listItemId != null) {
                        gender = this.findListItemValueInQuestion(
                          item.questionId,
                          item.listItemId
                        )
                      }
                    }
                    if (item.questionId.toLowerCase() == stringValues[2].toLowerCase()) {
                      // Refers to age question
                      if (this.isDate(item.dateTime)) {
                        age = Math.floor(
                          Math.abs(Date.now() - Date.parse(item.dateTime.toString())) /
                          (365.24 * 1000 * 60 * 60 * 24)
                        )
                      }
                    }
                  })

                  if (
                    pulse != Number.MIN_SAFE_INTEGER &&
                    gender.length > 0 &&
                    age != Number.MIN_SAFE_INTEGER
                  ) {
                    let result = this.calculationService.harvardStepTestResult(
                      pulse,
                      age,
                      gender
                    )

                    if (this.isValidGUID(result)) {
                      // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                      if (
                        hq.questionType == 0 &&
                        hq.questionReadOnly
                      ) {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          this.getSelectedValueText(
                            hq.questionId.toLowerCase(),
                            result.toLowerCase()
                          )
                        )
                      } else {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                      }
                      this.setResponseDropdownListItem(
                        hq.questionId.toLowerCase(),
                        result.toLowerCase()
                      )
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(null)
                      this.setResponseDropdownListItem(
                        hq.questionId,
                        null
                      )
                      setTimeout(() => {
                        this.questionnaireFormGroup.controls[hq.questionName].setErrors(new Error(result))
                        this.questionnaireFormGroup.controls[hq.questionName].markAsTouched()
                      }, 500)
                    }
                  } else {
                    this.questionnaireFormGroup.controls[hq.questionName].setValue(null)
                    this.setResponseDropdownListItem(
                      hq.questionId,
                      null
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'multidrugresult') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length > 2) {
                    let negative = stringValues[0]
                    let nonNegative = stringValues[1]
                    let questionIds: string[] = []

                    for (let index = 2; index < stringValues.length; index++) {
                      questionIds.push(stringValues[index])
                    }

                    let result = this.calculationService.multidrugTestResult(
                      nonNegative,
                      negative,
                      questionIds,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (
                      hq.questionType == 0 &&
                      hq.questionReadOnly
                    ) {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(
                        this.getSelectedValueText(
                          hq.questionId.toLowerCase(),
                          result.toLowerCase()
                        )
                      )
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(
                      hq.questionId,
                      result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'urineanalysisresult') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length > 13) {
                    let phId = stringValues[0]
                    let phLow = +stringValues[1]
                    let phHigh = +stringValues[2]
                    let phValue = ''
                    let sgId = stringValues[3]
                    let sgLow = +stringValues[4]
                    let sgHigh = +stringValues[5]
                    let normalId = stringValues[6]
                    let abnormalId = stringValues[7]
                    let positiveId = stringValues[8]
                    let negativeId = stringValues[9]
                    let bloodId = stringValues[10]
                    let menstruationId = stringValues[11]
                    let menstruationYesId = stringValues[12]

                    let questionIds: string[] = []

                    for (let index = 13; index < stringValues.length; index++) {
                      questionIds.push(stringValues[index])
                    }

                    // Get the selected value for ph
                    this.responseItems.forEach((item) => {
                      if (item.questionId.toLowerCase() == phId) {
                        if (item.listItemId) {
                          phValue = item.listItemId
                        }
                      }
                    })

                    phValue = this.getSelectedValueText(phId, phValue)

                    let result = this.calculationService.urineAnalysisResult(
                      phId,
                      phLow,
                      phHigh,
                      +phValue,
                      sgId,
                      sgLow,
                      sgHigh,
                      normalId,
                      abnormalId,
                      positiveId,
                      negativeId,
                      this.patient.gender,
                      bloodId,
                      menstruationId,
                      menstruationYesId,
                      questionIds,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (
                      hq.questionType == 0 &&
                      hq.questionReadOnly
                    ) {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(
                        this.getSelectedValueText(
                          hq.questionId.toLowerCase(),
                          result.toLowerCase()
                        )
                      )
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(
                      hq.questionId,
                      result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'tbresult') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length > 14) {
                    let q1y = stringValues[0]
                    let q2y = stringValues[1]
                    let q3y = stringValues[2]
                    let q4y = stringValues[3]
                    let q5y = stringValues[4]
                    let q6y = stringValues[5]
                    let q7y = stringValues[6]
                    let q8y = stringValues[7]
                    let q9n = stringValues[8]
                    let q10y = stringValues[9]
                    let yes = stringValues[10]
                    let no = stringValues[11]
                    let sputumId = stringValues[12]
                    let sputumClear = stringValues[13]
                    let normalId = stringValues[14]
                    let abnormalId = stringValues[15]

                    let result = this.calculationService.tbCalculation(
                      q1y,
                      q2y,
                      q3y,
                      q4y,
                      q5y,
                      q6y,
                      q7y,
                      q8y,
                      q9n,
                      q10y,
                      yes,
                      no,
                      sputumId,
                      sputumClear,
                      normalId,
                      abnormalId,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(
                        this.getSelectedValueText(
                          hq.questionId.toLowerCase(),
                          result.toLowerCase()
                        )
                      )
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId, result.toLowerCase())
                    if (result == abnormalId) {
                      if (this.referralQuestion) this.referralQuestion.isVisible = true;
                      hq.atRisk = true
                    } else {
                      hq.atRisk = false
                      if (this.referralQuestion) this.referralQuestion.isVisible = false;
                    }
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'psychosocial1') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())
                  if (stringValues.length > 12) {
                    let voicesQuestionId = stringValues[0];
                    let yesAnswerId = stringValues[1];
                    let q1 = stringValues[2]
                    let q2 = stringValues[3]
                    let q3 = stringValues[4]
                    let q4 = stringValues[5]
                    let q5 = stringValues[6]
                    let q6 = stringValues[7]
                    let q7 = stringValues[8]
                    let q8 = stringValues[9]
                    let q9 = stringValues[10]
                    let q10 = stringValues[11]
                    let lowStressId = stringValues[12]
                    let medStressId = stringValues[13]
                    let highStressId = stringValues[14]

                    let result = this.calculationService.psychosocial1Calculation(
                      voicesQuestionId,
                      yesAnswerId,
                      q1,
                      q2,
                      q3,
                      q4,
                      q5,
                      q6,
                      q7,
                      q8,
                      q9,
                      q10,
                      lowStressId,
                      medStressId,
                      highStressId,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value

                    if (result) {
                      if (hq.questionType == 0 && hq.questionReadOnly) {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          this.getSelectedValueText(
                            hq.questionId.toLowerCase(),
                            result.toLowerCase()
                          )
                        )
                      } else {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          result
                        )
                      }
                      this.setResponseDropdownListItem(
                        hq.questionId,
                        result.toLowerCase()
                      )
                      this.setResponse(hq, result.toLowerCase())
                      if (result == medStressId || result == highStressId) {
                        hq.atRisk = true
                        hq.riskRating = RISK_RATING.HIGH
                        if (result == highStressId) {
                          hq.riskRating = RISK_RATING.VERY_HIGH
                        }
                      } else {
                        hq.atRisk = false
                      }
                    } else {
                      hq.atRisk = false
                    }

                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'stressresult') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())
                  if (stringValues.length > 14) {
                    let voicesQuestionId = stringValues[0];
                    let yesAnswerId = stringValues[1];
                    let q1 = stringValues[2]
                    let q2 = stringValues[3]
                    let q3 = stringValues[4]
                    let q4 = stringValues[5]
                    let q5 = stringValues[6]
                    let q6 = stringValues[7]
                    let q7 = stringValues[8]
                    let q8 = stringValues[9]
                    let q9 = stringValues[10]
                    let q10 = stringValues[11]
                    let lowStressId = stringValues[12]
                    let medStressId = stringValues[13]
                    let highStressId = stringValues[14]

                    let result = this.calculationService.psychosocial1Calculation(
                      voicesQuestionId,
                      yesAnswerId,
                      q1,
                      q2,
                      q3,
                      q4,
                      q5,
                      q6,
                      q7,
                      q8,
                      q9,
                      q10,
                      lowStressId,
                      medStressId,
                      highStressId,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value

                    if (result) {
                      if (hq.questionType == 0 && hq.questionReadOnly) {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          this.getSelectedValueText(
                            hq.questionId.toLowerCase(),
                            result.toLowerCase()
                          )
                        )
                      } else {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          result
                        )
                      }
                      this.setResponseDropdownListItem(
                        hq.questionId,
                        result.toLowerCase()
                      )
                      this.setResponse(hq, result.toLowerCase())
                      if (result == medStressId || result == highStressId) {
                        hq.atRisk = true
                        hq.riskRating = RISK_RATING.HIGH
                        if (result == highStressId) {
                          hq.riskRating = RISK_RATING.VERY_HIGH
                        }
                      } else {
                        hq.atRisk = false
                      }
                    } else {
                      hq.atRisk = false
                    }

                    if (this.referralQuestion) this.referralQuestion.isVisible = result !== lowStressId;
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'psychosocial2') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())
                  if (stringValues.length > 6) {
                    let q1 = stringValues[0]
                    let q2 = stringValues[1]
                    let q3 = stringValues[2]
                    let q4 = stringValues[3]
                    let normalId = stringValues[4]
                    let mildId = stringValues[5]
                    let moderateId = stringValues[6]
                    let severeId = stringValues[7]

                    let result = this.calculationService.psychosocial2Calculation(
                      hq,
                      q1,
                      q2,
                      q3,
                      q4,
                      normalId,
                      mildId,
                      moderateId,
                      severeId,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value

                    if (result) {
                      if (hq.questionType == 0 && hq.questionReadOnly) {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          this.getSelectedValueText(
                            hq.questionId.toLowerCase(),
                            result.toLowerCase()
                          )
                        )
                      } else {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          result
                        )
                      }
                      this.setResponse(hq, result.toLowerCase())
                      this.setResponseDropdownListItem(
                        hq.questionId,
                        result.toLowerCase()
                      )

                      if (result == moderateId || result == severeId) {
                        hq.atRisk = true
                        hq.riskRating = RISK_RATING.HIGH
                        if (result == severeId) {
                          hq.riskRating = RISK_RATING.VERY_HIGH
                        }
                      } else {
                        hq.atRisk = false
                      }
                    } else {
                      hq.atRisk = false
                    }
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'mentalwellbeingresult') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())
                  if (stringValues.length > 6) {
                    let q1 = stringValues[0]
                    let q2 = stringValues[1]
                    let q3 = stringValues[2]
                    let q4 = stringValues[3]
                    let normalId = stringValues[4]
                    let mildId = stringValues[5]
                    let moderateId = stringValues[6]
                    let severeId = stringValues[7]

                    let result = this.calculationService.psychosocial2Calculation(
                      hq,
                      q1,
                      q2,
                      q3,
                      q4,
                      normalId,
                      mildId,
                      moderateId,
                      severeId,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value

                    if (result) {
                      if (hq.questionType == 0 && hq.questionReadOnly) {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          this.getSelectedValueText(
                            hq.questionId.toLowerCase(),
                            result.toLowerCase()
                          )
                        )
                      } else {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          result
                        )
                      }
                      this.setResponse(hq, result.toLowerCase())
                      this.setResponseDropdownListItem(
                        hq.questionId,
                        result.toLowerCase()
                      )

                      if (result !== normalId) {
                        hq.atRisk = true
                        hq.riskRating = RISK_RATING.HIGH
                        if (result == severeId) {
                          hq.riskRating = RISK_RATING.VERY_HIGH
                        }
                      } else {
                        hq.atRisk = false
                      }
                      if (this.referralQuestion) this.referralQuestion.isVisible = result !== normalId;
                    } else {
                      hq.atRisk = false
                    }
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'psychosocialresult') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 6) {
                    let q1 = stringValues[0]
                    let q2 = stringValues[1]

                    let lowResult = stringValues[2]
                    let mildResult = stringValues[3]
                    let moderateResult = stringValues[4]
                    let severeResult = stringValues[5]

                    let result: any = this.calculationService.psychosocialResult(
                      q1,
                      q2,
                      lowResult,
                      mildResult,
                      moderateResult,
                      severeResult,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value

                    if (result) {
                      if (hq.questionType == 0 && hq.questionReadOnly) {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          this.getSelectedValueText(
                            hq.questionId.toLowerCase(),
                            result.toLowerCase()
                          )
                        )
                      } else {
                        this.questionnaireFormGroup.controls[hq.questionName].setValue(
                          result
                        )
                      }
                      this.setResponse(hq, result.toLowerCase())
                      this.setResponseDropdownListItem(
                        hq.questionId,
                        result.toLowerCase()
                      )

                      if (result == moderateResult || result == severeResult) {
                        hq.atRisk = true
                        hq.riskRating = RISK_RATING.HIGH
                        if (result == severeResult) {
                          hq.riskRating = RISK_RATING.VERY_HIGH
                        }
                      } else {
                        hq.atRisk = false
                      }
                    } else {
                      hq.atRisk = false
                    }


                    if (hq.options[optIndex].attribute.toLowerCase() == 'stressresult') {
                      let stringValues = hq.options[optIndex].value
                        .split(',')
                        .map((item) => item.trim().toLowerCase())

                      if (stringValues.length == 6) {
                        let q1 = stringValues[0]

                        let lowResult = stringValues[1]
                        let mildResult = stringValues[2]
                        let moderateResult = stringValues[3]
                        let severeResult = stringValues[4]

                        let result: any = this.calculationService.stressResult(
                          q1,
                          lowResult,
                          mildResult,
                          moderateResult,
                          severeResult,
                          this.responseItems
                        )

                        // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value

                        if (result) {
                          if (hq.questionType == 0 && hq.questionReadOnly) {
                            this.questionnaireFormGroup.controls[hq.questionName].setValue(
                              this.getSelectedValueText(
                                hq.questionId.toLowerCase(),
                                result.toLowerCase()
                              )
                            )
                          } else {
                            this.questionnaireFormGroup.controls[hq.questionName].setValue(
                              result
                            )
                          }
                          this.setResponse(hq, result.toLowerCase())
                          this.setResponseDropdownListItem(
                            hq.questionId,
                            result.toLowerCase()
                          )

                          if (result == moderateResult || result == severeResult) {
                            hq.atRisk = true
                            hq.riskRating = RISK_RATING.HIGH
                            if (result == severeResult) {
                              hq.riskRating = RISK_RATING.VERY_HIGH
                            }
                          } else {
                            hq.atRisk = false
                          }
                        } else {
                          hq.atRisk = false
                        }
                      }
                    }
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'visiontestresult') {
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 12) {
                    let resultValues = stringValues[0].split(',')
                    let farAcuityLeftValues = stringValues[1].split(',')
                    let farAcuityRightValues = stringValues[2].split(',')
                    let farAcuityBothValues = stringValues[3].split(',')
                    let nearAcuityLeftValues = stringValues[4].split(',')
                    let nearAcuityRightValues = stringValues[5].split(',')
                    let nearAcuityBothValues = stringValues[6].split(',')
                    let visualFieldValues = stringValues[7].split(',')
                    let nightValues = stringValues[8].split(',')
                    let colourValues = stringValues[9].split(',')
                    let depthValues = stringValues[10].split(',')
                    let phoriaValues = stringValues[11].split(',')

                    let normalId = resultValues[0]
                    let abnormalId = resultValues[1]

                    let farAcuityLeftId = farAcuityLeftValues[0]
                    let farAcuityLeftList: string[] = []
                    farAcuityLeftValues.forEach((item) => {
                      if (item != farAcuityLeftId) {
                        farAcuityLeftList.push(item)
                      }
                    })

                    let farAcuityRightId = farAcuityRightValues[0]
                    let farAcuityRightList: string[] = []
                    farAcuityRightValues.forEach((item) => {
                      if (item != farAcuityRightId) {
                        farAcuityRightList.push(item)
                      }
                    })

                    let farAcuityBothId = farAcuityBothValues[0]
                    let farAcuityBothList: string[] = []
                    farAcuityBothValues.forEach((item) => {
                      if (item != farAcuityBothId) {
                        farAcuityBothList.push(item)
                      }
                    })

                    let nearAcuityLeftId = nearAcuityLeftValues[0]
                    let nearAcuityLeftList: string[] = []
                    nearAcuityLeftValues.forEach((item) => {
                      if (item != nearAcuityLeftId) {
                        nearAcuityLeftList.push(item)
                      }
                    })

                    let nearAcuityRightId = nearAcuityRightValues[0]
                    let nearAcuityRightList: string[] = []
                    nearAcuityRightValues.forEach((item) => {
                      if (item != nearAcuityRightId) {
                        nearAcuityRightList.push(item)
                      }
                    })

                    let nearAcuityBothId = nearAcuityBothValues[0]
                    let nearAcuityBothList: string[] = []
                    nearAcuityBothValues.forEach((item) => {
                      if (item != nearAcuityBothId) {
                        nearAcuityBothList.push(item)
                      }
                    })

                    let visualFieldId = visualFieldValues[0]
                    let visualFieldList: string[] = []
                    visualFieldValues.forEach((item) => {
                      if (item != visualFieldId) {
                        visualFieldList.push(item)
                      }
                    })

                    let nightId = nightValues[0]
                    let nightItemId = colourValues[1]

                    let colourId = colourValues[0]
                    let colourItemId = colourValues[1]

                    let depthId = depthValues[0]
                    let depthItemId = depthValues[1]

                    let phoriaId = phoriaValues[0]
                    let phoriaItemId = phoriaValues[1]

                    let result = this.calculationService.visionTestResult(
                      normalId,
                      abnormalId,
                      farAcuityLeftId,
                      farAcuityLeftList,
                      farAcuityRightId,
                      farAcuityRightList,
                      farAcuityBothId,
                      farAcuityBothList,
                      nearAcuityLeftId,
                      nearAcuityLeftList,
                      nearAcuityRightId,
                      nearAcuityRightList,
                      nearAcuityBothId,
                      nearAcuityBothList,
                      visualFieldId,
                      visualFieldList,
                      nightId,
                      nightItemId,
                      colourId,
                      colourItemId,
                      depthId,
                      depthItemId,
                      phoriaId,
                      phoriaItemId,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (
                      hq.questionType == 0 &&
                      hq.questionReadOnly
                    ) {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(
                        this.getSelectedValueText(
                          hq.questionId.toLowerCase(),
                          result.toLowerCase()
                        )
                      )
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(
                      hq.questionId,
                      result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'bmi') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 2) {
                    let heightId = stringValues[0]
                    let weightId = stringValues[1]

                    let result = this.calculationService.bmiCalculation(
                      heightId,
                      weightId,
                      this.responseItems
                    )

                    if (result != null) {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                      this.setResponse(hq, result);
                      // this.responseItem.forEach((item) => {
                      //   if (item.questionId == hq.questionId.toLowerCase()) {
                      //     item.value = result
                      //   }
                      // })
                    }
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'psaresult') {
                  hq.abnormalities = [];
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length > 3) {
                    let resultValues = stringValues[0].split(',')
                    let prevResultVals = stringValues[1].split(',')
                    let prostateValues = stringValues[2].split(',')
                    let dietValues = stringValues[3].split(',')
                    let ageValues = stringValues[4].split(',')
                    let urineValues = stringValues[5]
                    let testReusltValues = stringValues[6].split(',')

                    let atRiskID = resultValues[0]
                    let notRiskID = resultValues[1]

                    let prevTestQuestionId = prevResultVals[0]
                    let prevTestRiskId = prevResultVals[1]

                    let prostateQuestionId = prostateValues[0]
                    let prostateTestRiskId = prostateValues[1]

                    let dietQuestionId = dietValues[0]
                    let dietTestRiskId = dietValues[1]

                    let ageQuestionId = ageValues[0]
                    let maxAge = +ageValues[1]

                    let urineQuestionId = urineValues


                    let testReusltQuestionId = testReusltValues[0]
                    let testResultId = testReusltValues[1]

                    let result = this.calculationService.psaresult(
                      atRiskID,
                      notRiskID,
                      prevTestQuestionId,
                      prevTestRiskId,
                      prostateQuestionId,
                      prostateTestRiskId,
                      dietQuestionId,
                      dietTestRiskId,
                      ageQuestionId,
                      maxAge,
                      urineQuestionId,

                      testResultId,
                      testReusltQuestionId,
                      this.responseItems,
                      hq.abnormalities
                    )

                    hq.atRisk = !(result == notRiskID);
                    if (this.referralQuestion) this.referralQuestion.isVisible = result === atRiskID;
                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      let selectedValueText = this.getSelectedValueText(hq.questionId.toLowerCase(), result.toLowerCase())
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(selectedValueText)
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId.toLowerCase(), result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'fluresult') {
                  hq.abnormalities = [];
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())
                  console.log("String Values", stringValues)

                  if (stringValues.length > 3) {
                    let resultValues = stringValues[0].split(',')
                    let pregResult = stringValues[1].split(',');
                    let triResult = stringValues[2].split(',');
                    let feverResult = stringValues[3].split(',');
                    let sensitiveResult = stringValues[4].split(',');

                    let atRiskID = resultValues[0];
                    let notRiskID = resultValues[1];

                    let pregQuestionId = pregResult[0];
                    let pregTestRiskId = pregResult[1];

                    let triResultQuestionId = triResult[0];
                    let triResultTestRiskId = triResult[1];

                    let feverResultQuestionId = feverResult[0];
                    let feverResultTestRiskId = feverResult[1];

                    let sensitiveResultQuestionId = sensitiveResult[0];
                    let sensitiveResultTestRiskId = sensitiveResult[1];

                    let result = this.calculationService.fluresult(
                      atRiskID,
                      notRiskID,
                      pregQuestionId,
                      pregTestRiskId,
                      triResultQuestionId,
                      triResultTestRiskId,
                      feverResultQuestionId,
                      feverResultTestRiskId,
                      sensitiveResultQuestionId,
                      sensitiveResultTestRiskId,
                      this.responseItems,
                      hq.abnormalities
                    )

                    hq.atRisk = !(result == notRiskID);
                    if (this.referralQuestion) this.referralQuestion.isVisible = result === atRiskID;
                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      let selectedValueText = this.getSelectedValueText(hq.questionId.toLowerCase(), result.toLowerCase())
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(selectedValueText)
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId.toLowerCase(), result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'patientage') {
                  // let stringValues = hq.options[optIndex].value
                  //   .split(',')
                  //   .map((item) => item.trim().toLowerCase())

                  // if (stringValues.length == 2) {
                  //   let heightId = stringValues[0]
                  //   let weightId = stringValues[1]

                  // let result = this.calculationService.bmiCalculation(
                  //   heightId,
                  //   weightId,
                  //   this.responseItems
                  // )
                  let dob: Date;
                  if (this.patient.rsaidnumber && this.patient.rsaidnumber.length > 10) {
                    let id = this.patient.rsaidnumber;
                    let id_day = id.substring(4, 6);
                    let id_month = id.substring(2, 4);
                    let id_year = (id.substring(0, 2) > "00" ? "19" + id.substring(0, 2) : "20" + id.substring(0, 2));
                    // let dob = new Date(`${id_year}-${id_month}-${id_day}`)
                    let dob_param = id_year + '-' + id_month + '-' + id_day;
                    console.log(`Id no ${id} and dob ${dob_param}`)
                    dob = new Date(dob_param);
                  }
                  else {
                    dob = new Date(this.patient["dob"]);
                  }
                  let age = new Date().getFullYear() - dob.getFullYear()

                  let result = age;

                  if (result != null) {
                    this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    this.setResponse(hq, result);
                    // this.responseItem.forEach((item) => {
                    //   if (item.questionId == hq.questionId.toLowerCase()) {
                    //     item.value = result
                    //   }
                    // })
                  }
                  //}
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'riskscore') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 2) {
                    let interestId = stringValues[0].toLowerCase()
                    let feelingId = stringValues[1].toLowerCase()
                    if (
                      obj.questionId.toLowerCase() == interestId ||
                      obj.questionId.toLowerCase() == feelingId
                    ) {
                      let interestResponse = this.responseItems.find(
                        (r) => r.questionId.toLowerCase() == interestId
                      )
                      if (interestResponse) {
                        let feelingResponse = this.responseItems.find(
                          (r) => r.questionId.toLowerCase() == feelingId
                        )
                        if (feelingResponse) {
                          let interestListItems = this.hierarchicalQuestionnaire.find(
                            (q) => q.questionId == interestId
                          ).listItems
                          let feelingListItems = this.hierarchicalQuestionnaire.find(
                            (q) => q.questionId == feelingId
                          ).listItems

                          let result = this.calculationService.riskScoreCalculation(
                            interestResponse,
                            feelingResponse,
                            interestListItems,
                            feelingListItems,
                            this.responseItems
                          )

                          if (result != null) {
                            let severityRating =
                              result > 2
                                ? 'Major Depressive disorder is likely'
                                : 'Mental Health Condition is not likely'
                            this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                            let srHq = this.hierarchicalQuestionnaire[index + 1]
                            this.questionnaireFormGroup.controls[srHq.questionName].setValue(severityRating)

                            //   this.responseItem.forEach(item => {
                            //     if (item.questionId == hq.questionId.toLowerCase()) {
                            //       item.value = result;
                            //     }
                            //     if (item.questionId == srHq.questionId.toLowerCase()) {
                            //       item.responseText = severityRating;
                            //     }
                            // });
                          }
                        }
                      }
                    }
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'vitalstestresult') {
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 8) {
                    let resultValues = stringValues[0].split(',')
                    let prevResultVals = stringValues[1].split(',')
                    let chestValues = stringValues[2].split(',')
                    let waistValues = stringValues[3].split(',')
                    let tempValues = stringValues[4].split(',')
                    let pulseValues = stringValues[5].split(',')
                    let systolicValues = stringValues[6].split(',')
                    let diastolicValues = stringValues[7].split(',')

                    let normalId = resultValues[0]
                    let abnormalId = resultValues[1]

                    let bmiId = prevResultVals[0]
                    let bmiMin = +prevResultVals[1]
                    let bmiMax = +prevResultVals[2]

                    let chestInspId = chestValues[0]
                    let chestExppId = chestValues[1]
                    let chestDiffMin = +chestValues[2]
                    let chestDiffMax = +chestValues[3]

                    let waistId = waistValues[0]
                    let waistMaxFemale = +waistValues[1]
                    let waistMaxMale = +waistValues[2]

                    let tempId = tempValues[0]
                    let tempMin = +tempValues[1]
                    let tempMax = +tempValues[2]

                    let pulseId = pulseValues[0]
                    let pulseMin = +pulseValues[1]
                    let pulseMax = +pulseValues[2]

                    let systolicId = systolicValues[0]
                    let systolicMin = +systolicValues[1]
                    let systolicMax = +systolicValues[2]

                    let diastolicId = diastolicValues[0]
                    let diastolicMin = +diastolicValues[1]
                    let diastolicMax = +diastolicValues[2]
                    let result = this.calculationService.vitalsTestResult(
                      normalId,
                      abnormalId,
                      bmiId,
                      bmiMin,
                      bmiMax,
                      chestInspId,
                      chestExppId,
                      chestDiffMin,
                      chestDiffMax,
                      waistId,
                      waistMaxFemale,
                      waistMaxMale,
                      tempId,
                      tempMin,
                      tempMax,
                      pulseId,
                      pulseMin,
                      pulseMax,
                      systolicId,
                      systolicMin,
                      systolicMax,
                      diastolicId,
                      diastolicMin,
                      diastolicMax,
                      this.patient.gender,
                      this.responseItems
                    )

                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (
                      hq.questionType == 0 &&
                      hq.questionReadOnly
                    ) {
                      this.questionnaireFormGroup.controls[
                        hq.questionName
                      ].setValue(
                        this.getSelectedValueText(
                          hq.questionId.toLowerCase(),
                          result.toLowerCase()
                        )
                      )
                    } else {
                      this.questionnaireFormGroup.controls[
                        hq.questionName
                      ].setValue(result)
                    }
                    this.setResponseDropdownListItem(
                      hq.questionId.toLowerCase(),
                      result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'adhocvcresult') {
                  hq.abnormalities = [];
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 5) {
                    let normalId = stringValues[0]
                    let abnormalId = stringValues[1]
                    let unprotectedSexId = stringValues[2]
                    let innebriatedSexId = stringValues[3]
                    let adhocYesId = stringValues[4]

                    let result = this.calculationService.adhocVCResult(
                      normalId,
                      abnormalId,
                      unprotectedSexId,
                      innebriatedSexId,
                      adhocYesId,
                      this.responseItems,
                    )

                    hq.atRisk = result == abnormalId;
                    if (this.referralQuestion) this.referralQuestion.isVisible = result === abnormalId;
                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      let selectedValueText = this.getSelectedValueText(hq.questionId.toLowerCase(), result.toLowerCase())
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(selectedValueText)
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId.toLowerCase(), result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'adhocsnellenresult') {
                  hq.abnormalities = [];
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())
                  if (stringValues.length > 0) {
                    let resultValues = stringValues[0].split(',');
                    let nromalId = resultValues[0];
                    let abnormalId = resultValues[1];

                    let visualAcuityValues = stringValues[1].split(',');
                    let bothAcuityId = visualAcuityValues[0];
                    let twelveId = visualAcuityValues[1];
                    let fifteenId = visualAcuityValues[2];
                    let twentyId = visualAcuityValues[3];
                    let thirtyId = visualAcuityValues[4];
                    let sixtyId = visualAcuityValues[5];
                    let aboveSixtyId = visualAcuityValues[6];

                    let result = this.calculationService.adhocSnellenResult(
                      nromalId,
                      abnormalId,
                      bothAcuityId,
                      twelveId,
                      fifteenId,
                      twentyId,
                      thirtyId,
                      sixtyId,
                      aboveSixtyId,
                      this.responseItems,
                      hq.abnormalities
                    )

                    hq.atRisk = result == abnormalId;
                    if (this.referralQuestion) this.referralQuestion.isVisible = result === abnormalId;
                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      let selectedValueText = this.getSelectedValueText(hq.questionId.toLowerCase(), result.toLowerCase())
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(selectedValueText)
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId.toLowerCase(), result.toLowerCase()
                    )

                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'adhochraresult') {
                  hq.abnormalities = [];
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 8) {
                    let resultValues = stringValues[0].split(',')
                    let bmiValues = stringValues[1].split(',')
                    let waistValues = stringValues[2].split(',')
                    let systolicValues = stringValues[3].split(',')
                    let diastolicValues = stringValues[4].split(',')
                    let cholesterolValues = stringValues[5].split(',')
                    let bloodGlucoseValues = stringValues[6].split(',')

                    let normalId = resultValues[0]
                    let abnormalId = resultValues[1]

                    let bmiId = bmiValues[0]
                    let bmiMin = +bmiValues[1]
                    let bmiMax = +bmiValues[2]
                    let pregnantId = bmiValues[3];
                    let yesId = bmiValues[4];

                    let waistId = waistValues[0]
                    let waistMaxFemale = +waistValues[1]
                    let waistMaxMale = +waistValues[2]

                    let systolicId = systolicValues[0]
                    let systolicMin = +systolicValues[1]
                    let systolicMax = +systolicValues[2]

                    let diastolicId = diastolicValues[0]
                    let diastolicMin = +diastolicValues[1]
                    let diastolicMax = +diastolicValues[2]

                    let cholesterolId = cholesterolValues[0]
                    let cholesterolMin = +cholesterolValues[1]
                    let cholesterolMax = +cholesterolValues[2]

                    let bloodGlucoseFirstId = bloodGlucoseValues[0]
                    let bloodGlucoseSecondId = bloodGlucoseValues[1]
                    let glucoseLowerBound = +bloodGlucoseValues[2]
                    let glucoseUpperBound = +bloodGlucoseValues[3]
                    let result = this.calculationService.adhocHRAResult(
                      normalId,
                      abnormalId,
                      bmiId,
                      bmiMin,
                      bmiMax,
                      pregnantId,
                      yesId,
                      waistId,
                      waistMaxFemale,
                      waistMaxMale,
                      systolicId,
                      systolicMin,
                      systolicMax,
                      diastolicId,
                      diastolicMin,
                      diastolicMax,
                      cholesterolId,
                      cholesterolMin,
                      cholesterolMax,
                      bloodGlucoseFirstId,
                      bloodGlucoseSecondId,
                      glucoseLowerBound,
                      glucoseUpperBound,
                      this.patient.gender,
                      this.responseItems,
                      hq.abnormalities
                    )

                    hq.atRisk = result == abnormalId;
                    if (this.referralQuestion) this.referralQuestion.isVisible = result === abnormalId;
                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      let selectedValueText = this.getSelectedValueText(hq.questionId.toLowerCase(), result.toLowerCase())
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(selectedValueText)
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId.toLowerCase(), result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'biometricsresult') {
                  hq.abnormalities = [];
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 7) {
                    let resultValues = stringValues[0].split(',')
                    let bmiValues = stringValues[1].split(',')
                    let waistValues = stringValues[2].split(',')
                    let systolicValues = stringValues[3].split(',')
                    let diastolicValues = stringValues[4].split(',')
                    let cholesterolValues = stringValues[5].split(',')
                    let bloodGlucoseValues = stringValues[6].split(',')

                    let normalId = resultValues[0]
                    let abnormalId = resultValues[1]

                    let bmiId = bmiValues[0]
                    let bmiMin = +bmiValues[1]
                    let bmiMax = +bmiValues[2]

                    let waistId = waistValues[0]
                    let waistMaxFemale = +waistValues[1]
                    let waistMaxMale = +waistValues[2]

                    let systolicId = systolicValues[0]
                    let systolicMin = +systolicValues[1]
                    let systolicMax = +systolicValues[2]

                    let diastolicId = diastolicValues[0]
                    let diastolicMin = +diastolicValues[1]
                    let diastolicMax = +diastolicValues[2]

                    let cholesterolId = cholesterolValues[0]
                    let cholesterolMax = +cholesterolValues[1]

                    let bloodGlucoseFirstId = bloodGlucoseValues[0]
                    let bloodGlucoseSecondId = bloodGlucoseValues[1]
                    let glucoseLowerBound = +bloodGlucoseValues[2]
                    let glucoseUpperBound = +bloodGlucoseValues[3]
                    let result = this.calculationService.biometricsResult(
                      normalId,
                      abnormalId,
                      bmiId,
                      bmiMin,
                      bmiMax,
                      waistId,
                      waistMaxFemale,
                      waistMaxMale,
                      systolicId,
                      systolicMin,
                      systolicMax,
                      diastolicId,
                      diastolicMin,
                      diastolicMax,
                      cholesterolId,
                      cholesterolMax,
                      bloodGlucoseFirstId,
                      bloodGlucoseSecondId,
                      glucoseLowerBound,
                      glucoseUpperBound,
                      this.patient.gender,
                      this.responseItems,
                      hq.abnormalities
                    )

                    hq.atRisk = result == abnormalId;
                    if (this.referralQuestion) this.referralQuestion.isVisible = result === abnormalId;
                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      let selectedValueText = this.getSelectedValueText(hq.questionId.toLowerCase(), result.toLowerCase())
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(selectedValueText)
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId.toLowerCase(), result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'compharesult') {
                  hq.abnormalities = [];
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length > 0) {
                    let resultValues = stringValues[0].split(',');

                    let normalId = resultValues[0];
                    let abnormalId = resultValues[1];

                    let systolicValues = stringValues[1].split(',');
                    let diastolicValues = stringValues[2].split(',');
                    let bloodGlucoseValues = stringValues[3].split(',');

                    let systolicId = systolicValues[0];
                    let systolicMin = +systolicValues[1];
                    let systolicMax = +systolicValues[2];

                    let diastolicId = diastolicValues[0];
                    let diastolicMin = +diastolicValues[1];
                    let diastolicMax = +diastolicValues[2];

                    let bloodGlucoseFirstId = bloodGlucoseValues[0];
                    let bloodGlucoseSecondId = bloodGlucoseValues[1];
                    let glucoseLowerBound = +bloodGlucoseValues[2];
                    let glucoseUpperBound = +bloodGlucoseValues[3];

                    let result = this.calculationService.comphraResult(
                      normalId,
                      abnormalId,
                      systolicId,
                      systolicMin,
                      systolicMax,
                      diastolicId,
                      diastolicMin,
                      diastolicMax,
                      bloodGlucoseFirstId,
                      bloodGlucoseSecondId,
                      glucoseLowerBound,
                      glucoseUpperBound,
                      this.responseItems,
                      hq.abnormalities
                    )
                    hq.atRisk = result == abnormalId;
                    hq.atRisk = result == abnormalId;
                    if (this.referralQuestion) this.referralQuestion.isVisible = result === abnormalId;
                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      let selectedValueText = this.getSelectedValueText(hq.questionId.toLowerCase(), result.toLowerCase())
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(selectedValueText)
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId.toLowerCase(), result.toLowerCase()
                    )

                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'wellnessresult') {
                  hq.abnormalities = [];
                  let stringValues = hq.options[optIndex].value
                    .split('|')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 8) {
                    let resultValues = stringValues[0].split(',')
                    let bmiValues = stringValues[1].split(',')
                    let waistValues = stringValues[2].split(',')
                    let systolicValues = stringValues[3].split(',')
                    let diastolicValues = stringValues[4].split(',')
                    let cholesterolValues = stringValues[5].split(',')
                    let bloodGlucoseValues = stringValues[6].split(',')

                    let normalId = resultValues[0]
                    let abnormalId = resultValues[1]

                    let bmiId = bmiValues[0]
                    let bmiMin = +bmiValues[1]
                    let bmiMax = +bmiValues[2]

                    let waistId = waistValues[0]
                    let waistMaxFemale = +waistValues[1]
                    let waistMaxMale = +waistValues[2]

                    let systolicId = systolicValues[0]
                    let systolicMin = +systolicValues[1]
                    let systolicMax = +systolicValues[2]

                    let diastolicId = diastolicValues[0]
                    let diastolicMin = +diastolicValues[1]
                    let diastolicMax = +diastolicValues[2]

                    let cholesterolId = cholesterolValues[0]
                    let cholesterolMax = +cholesterolValues[1]

                    let bloodGlucoseFirstId = bloodGlucoseValues[0]
                    let bloodGlucoseSecondId = bloodGlucoseValues[1]
                    let glucoseLowerBound = +bloodGlucoseValues[2]
                    let glucoseUpperBound = +bloodGlucoseValues[3]
                    let result = this.calculationService.wellnessResult(
                      normalId,
                      abnormalId,
                      bmiId,
                      bmiMin,
                      bmiMax,
                      waistId,
                      waistMaxFemale,
                      waistMaxMale,
                      systolicId,
                      systolicMin,
                      systolicMax,
                      diastolicId,
                      diastolicMin,
                      diastolicMax,
                      cholesterolId,
                      cholesterolMax,
                      bloodGlucoseFirstId,
                      bloodGlucoseSecondId,
                      glucoseLowerBound,
                      glucoseUpperBound,
                      this.patient.gender,
                      this.responseItems,
                      hq.abnormalities
                    )

                    hq.atRisk = result == abnormalId;
                    if (this.referralQuestion) this.referralQuestion.isVisible = result === abnormalId;
                    // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                    if (hq.questionType == 0 && hq.questionReadOnly) {
                      let selectedValueText = this.getSelectedValueText(hq.questionId.toLowerCase(), result.toLowerCase())
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(selectedValueText)
                    } else {
                      this.questionnaireFormGroup.controls[hq.questionName].setValue(result)
                    }
                    this.setResponseDropdownListItem(hq.questionId.toLowerCase(), result.toLowerCase()
                    )
                  }
                }
                if (hq.options[optIndex].attribute.toLowerCase() == 'hivresult') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim().toLowerCase())

                  if (stringValues.length == 2) {
                    let posId = stringValues[0]
                    let negId = stringValues[1]
                    let r = this.responseItems.find(d => d.questionId == hq.questionId);
                    if (r) {
                      hq.atRisk = r.listItemId === posId;
                      if (this.referralQuestion) this.referralQuestion.isVisible = r.listItemId === posId;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // Check for QuestionnaireResult options - if the question is not in result item list, add it
      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]
        var optKeys = Object.keys(hq.options)
        if (optKeys.length > 0) {
          for (let optIndex = 0; optIndex < optKeys.length; optIndex++) {
            if (hq.options[optIndex].discriminator.toLowerCase() == 'questionnaireresult') {
              let norm = hq.options[optIndex].value.toLowerCase();

              let resultFound = this.responseItems.some((item) => item.questionId == hq.questionId)

              if (!resultFound) {
                let newItem: ResponseItemObject
                newItem = {
                  questionId: hq.questionId,
                  selectionOptionsSelected: null,
                  groupQuestionAssociationId: hq.groupQuestionAssociationId,
                  comment: null,
                  dateTime: null,
                  result: null,
                  value: null,
                  listItemId: this.questionnaireFormGroup.controls[hq.questionName].value,
                  signature: null,
                  time: null,
                  responseText: null,
                  contoller: null,
                  endPoint: null,
                  method: null,
                  parameters: null,
                  serverRequestResponse: null,
                  question: hq,
                }
                this.responseItems.push(newItem)
              }

              break
            }
          }
        }
      }

      let currentGroupId = '';
      let visibleCount = 0;
      let previousGroupId = '';
      let groupVisibility = true;

      for (let index = 0; index < keys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index];
        if ([QUESTION_TYPE.GroupStart, QUESTION_TYPE.GroupEnd].indexOf(hq.questionType) > -1) {
          continue;
        }
        // New group encountered
        if (hq.groupId !== currentGroupId) {

          // For a new group, set the previous group's showHeader and reset visibleCount
          if (currentGroupId !== '') {
            groupVisibility = visibleCount > 0;
            for (let visIndex = 0; visIndex < index; visIndex++) {
              let subHQ = this.hierarchicalQuestionnaire[visIndex];
              if (subHQ.groupId === previousGroupId) {
                subHQ.showHeader = groupVisibility;
              }
            }
            visibleCount = 0;
          }

          previousGroupId = currentGroupId;
          currentGroupId = hq.groupId;
        }

        // If current question is visible, increase the count
        if (hq.isVisible) {
          visibleCount++;
        }
      }

      // For the last group, set showHeader based on visibleCount
      groupVisibility = visibleCount > 0;
      for (let visIndex = 0; visIndex < keys.length; visIndex++) {
        let subHQ = this.hierarchicalQuestionnaire[visIndex];
        if (subHQ.groupId === currentGroupId) {
          subHQ.showHeader = groupVisibility;
        }
      }


      let hiddenQuestions = this.hierarchicalQuestionnaire.filter(hq => !hq.isVisible);
      for (let o = 0; o < hiddenQuestions.length; o++) {
        let hq = hiddenQuestions[o];
        hq.atRisk = false;
        hq.atRiskReason = null;
        hq.riskRating = RISK_RATING.LOW;
        hq.responseOptionId = null;
        this.questionnaireFormGroup.get(hq.questionName).reset();
        let responses = this.responseItems.filter(r => r.questionId.toLowerCase() === hq.questionId.toLowerCase());
        for (let j = 0; j < responses.length; j++) {
          // let response = responses[j];
          // response.value = 0;
          // response.dateTime = null;
          // response.responseText = null;
          // response.comment = null;
          // response.listItemId = null;
          // response.time = null;
          let index = this.responseItems.indexOf(responses[j]);
          this.responseItems.splice(index, 1);
        }
      }
      this.stackLevel--
    }
  }

  hideChildren(gqaId: string): void {
    let children = this.hierarchicalQuestionnaire.filter(hq => hq.groupQuestionAssociationParentId && hq.groupQuestionAssociationParentId.toLowerCase() === gqaId.toLowerCase());
    for (let childIndex = 0; childIndex < children.length; childIndex++) {
      let child = children[childIndex];
      this.resetQuestion(child);
      this.hideChildren(child.groupQuestionAssociationId);
    }
  }

  resetQuestion(hq): void {
    hq.isVisible = false;
    this.questionnaireFormGroup.get(hq.questionName).reset();
    hq.atRisk = false;
    hq.atRiskReason = null;
    hq.riskRating = RISK_RATING.LOW;
    hq.responseOptionId = null;

    const responses = this.responseItems.filter(r => r.questionId.toLowerCase() === hq.questionId.toLowerCase());
    responses.forEach(response => {
      const index = this.responseItems.indexOf(response);
      this.responseItems.splice(index, 1);
    });
  }

  setResponse(hq: HierarchicalQuestionObject, val: any): void {
    let response = this.responseItems.find(
      (d) => d.questionId.toLowerCase() === hq.questionId.toLowerCase()
    );

    if (!response) {
      response = {
        questionId: hq.questionId,
        selectionOptionsSelected: null,
        groupQuestionAssociationId: hq.groupQuestionAssociationId,
        comment: null,
        dateTime: null,
        result: null,
        value: null,
        listItemId: null,
        signature: null,
        time: null,
        responseText: null,
        contoller: null,
        endPoint: null,
        method: null,
        parameters: null,
        serverRequestResponse: null,
        question: hq,
      }
      this.responseItems.push(response);
    }

    const type = hq.questionTypeName.toLowerCase();
    const typeMapping = {
      selectionOptionsSelected: ['multipleselection', 'multipleselectionandcomment'],
      comment: ['comment'],
      dateTime: ['date'],
      value: ['decimal', 'numeric', 'multipleselectionwithsubquestion'],
      listItemId: ['selection', 'selectionandcomment', 'slider', 'toggle', 'radio'],
      signature: ['signature'],
      time: ['time'],
      responseText: ['text', 'multilinetext', 'grid']
    };

    Object.entries(typeMapping).forEach(([property, types]) => {
      if (types.includes(type)) {
        response[property] = val;
      }
    });
  }

  setResponseDropdownListItem(questionId: string, value: string): void {
    for (let index = 0; index < this.responseItems.length; index++) {
      let item = this.responseItems[index]
      if (item.questionId.toLowerCase() == questionId.toLowerCase()) {
        item.listItemId = value
        break
      }
    }
  }

  isValidGUID(value: string): boolean {
    if (value.length > 0) {
      if (
        !/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/.test(
          value
        )
      ) {
        return false
      }
    } else {
      return false
    }

    return true
  }

  findListItemValueInQuestion(questionId, listItemId): string {
    var questionKeys = Object.keys(this.hierarchicalQuestionnaire)

    if (questionKeys.length > 0) {
      for (let index = 0; index < questionKeys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index];
        if (
          hq.questionId.toLowerCase() ==
          questionId.toLowerCase()
        ) {
          var optKeys = Object.keys(hq.options)

          if (optKeys.length > 0) {
            for (let optIndex = 0; optIndex < optKeys.length; optIndex++) {
              if (
                hq.options[optIndex].discriminator.toLowerCase() == 'selectionoption'
              ) {
                var listKeys = Object.keys(hq.options[optIndex].list.listItems)

                if (listKeys.length > 0) {
                  for (let listIndex = 0; listIndex < listKeys.length; listIndex++) {
                    if (
                      hq.options[optIndex].list.listItems[listIndex].id.toLowerCase() ==
                      listItemId.toLowerCase()
                    ) {
                      return hq.options[optIndex].list.listItems[listIndex].description
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return ''
  }

  findIndexToUpdate(newItem): boolean {
    return newItem.id === this
  }

  checkConditional(form, formId: string, questionOptions?): boolean {
    try {
      const valueCheck = (form['controls'] ? form : form.form).controls[formId].value

      const index = questionOptions[0].list.listItems.findIndex(
        (search) => search.id == valueCheck
      )

      if (
        questionOptions[0].list.listItems[index].description.toLowerCase() == 'abnormal'
      ) {
        return true
      } else {
        return false
      }
    } catch (ex) { }
  }

  getForItem(obj: any, event: { checked: boolean }, i: number, listItem: string | null): string {
    listItem = listItem || '';
    const a = obj.options.find(opt => opt.discriminator.toLowerCase() === 'selectionoption')?.list.listItems[i].id || '';

    if (event.checked) {
      return listItem ? listItem + ',' + a : a;
    } else {
      return listItem.replace(new RegExp(`(${a},|${a}$)`, 'g'), '');
    }
  }

  gridToString(event): string {
    var result = ''
    var lines = []

    this.gridApi = event.api
    this.gridColumnApi = event.ColumnApi

    var rows = this.gridApi
      .getDataAsCsv({ skipColumnHeaders: true, columnSeparator: '\t' })
      .replace(/"/g, '')
      .split('\r\n')

    for (let indRow = 0; indRow < rows.length; indRow++) {
      var cols = rows[indRow].split('\t')
      var check = ''
      for (let indCol = 0; indCol < cols.length; indCol++) {
        check = check + cols[indCol]
      }

      if (check.trim().length > 0) {
        var line = '{'

        for (let indCol = 0; indCol < cols.length; indCol++) {
          line =
            line +
            '"' +
            event.columnApi.columnModel.columnDefs[indCol].field +
            '": "' +
            cols[indCol] +
            '",'
        }

        line = line.substring(0, line.length - 1) + '}'

        lines.push(line)
      }
    }

    if (lines.length > 0) {
      result = '['

      for (let index = 0; index < lines.length; index++) {
        result = result + lines[index] + ','
      }

      result = result.substring(0, result.length - 1) + ']'
    }

    return result
  }

  examinationSelection(event): void {
    setTimeout(() => {
      //Clear validators
      if (!this.questionnaireFormGroup.valid) {
        this.showError = false
        for (const key in this.questionnaireFormGroup.controls) {
          this.questionnaireFormGroup.get(key).clearValidators()
          this.questionnaireFormGroup.get(key).updateValueAndValidity()
        }
      }

      this.examinationFormGroup.controls['examinationComment'].setValue(null);

      // Only show questionnare questions if done was selected, otherwise hide the questions
      // Only show contraindication questions if contraindicated was selected
      // Only show contraindication comments if a specific contraindicated item was selected
      this.showQuestionnaire = [0, 1, 6].includes(event.value) // 'Done', 'Abnormal', or 'Consent given';
      this.showContraindicated = event.value == 3 // 'Contraindicated'
      this.showQuestionnaireComment = !this.showQuestionnaire
      this.examinationResult =
        this.examinationFormGroup.controls['examinationResultName'].value
      this.examinationResultName = this.getExaminationResultName(this.examinationResult)

      if (!this.showQuestionnaire) {
        if (
          this.examinationFormGroup.controls['examinationComment'].value.trim().length ==
          0
        ) {
          this.enableSubmit = false
        }
      } else {
        this.enableSubmit = true
      }
    })
  }

  contraindicatedSelection(event): void {
    setTimeout(() => {
      //Clear validators
      if (!this.questionnaireFormGroup.valid) {
        this.showError = false
        for (const key in this.questionnaireFormGroup.controls) {
          this.questionnaireFormGroup.get(key).clearValidators()
          this.questionnaireFormGroup.get(key).updateValueAndValidity()
        }
      }
      if (event && event.value) {
        if (this.contraindicationWatchList.includes(event.value.toUpperCase())) {
          this.showContraindicatedComment = true
          if (
            this.examinationFormGroup.controls['contraindicatedComment'].value.trim()
              .length > 0
          ) {
            this.enableSubmit = true
          }
        } else {
          this.enableSubmit = true
          this.showContraindicatedComment = false
          this.examinationFormGroup.controls['contraindicatedComment'].clearValidators()
          this.examinationFormGroup.controls[
            'contraindicatedComment'
          ].updateValueAndValidity()
          this.examinationFormGroup.controls['contraindicatedSelection'].clearValidators()
          this.examinationFormGroup.controls[
            'contraindicatedSelection'
          ].updateValueAndValidity()
        }
      }
    })
  }

  examinationCommentChange(event: Event): void {
    const inputValue = (event.currentTarget as HTMLInputElement).value;
    this.enableSubmit = inputValue.length > 0;
    this.examinationComment = this.examinationFormGroup.controls['examinationComment'].value;
  }

  contraindicationCommentChange(event: Event): void {
    this.enableSubmit = (event.currentTarget as HTMLInputElement).value.length > 0;
  }

  getErrorMessage(control: string, onlyOnSubmit: boolean): Observable<string> {
    return this.questionnaireFormGroup.statusChanges.pipe(
      filter(r => r && (!onlyOnSubmit || (onlyOnSubmit && this.isSubmit))),
      map(() => {
        let result = '';
        const errors = this.questionnaireFormGroup.controls[control].errors;


        if (errors) {
          if (errors['max']) {
            result = `Value may not be greater than ${errors['max'].max}`;
          } else if (errors['min']) {
            result = `Value may not be less than ${errors['min'].min}`;
          } else if (errors['required']) {
            result = 'An answer is required.';
          } else if (errors['pattern']) {
            result = 'Invalid value.';
          } else if (errors.message) {
            result = errors.message;
          }
        }

        return result;
      }),
    );
  }

  getSelectionOptionsAsList(listOptions: string): string[] {
    return listOptions ? listOptions.split(',').map(item => item.trim()) : [];
  }

  itemIsChecked(question: any, listId: string): boolean {
    if (
      this.consultationSelection === 'Questionnaire' ||
      this.consultationSelection === 'Survey'
    ) {
      return false;
    }

    const list = this.getSelectionOptionsAsList(question.responseSelectionOptionsSelected);
    return list.some(item => item.toLowerCase() === listId.toLowerCase());
  }

  questionIsChecked(question): boolean {
    if (
      this.consultationSelection == 'Questionnaire' ||
      this.consultationSelection == 'Survey'
    ) {
      return false
    } else {
      if (question.responseValue >= 1) {
        return true
      } else {
        return false
      }
    }
  }

  back(): void {
    this.onBackPressed.emit()
  }

  getQuestionPhraseTemplate(): TemplateRef<any> {
    return this.questionPhrase
  }
  getErrorTemplate(): TemplateRef<any> {
    return this.errorTemplate
  }
  getQuestionCommentTemplate(): TemplateRef<any> {
    return this.questionComment
  }

  getQuestionTemplate(questionType: QUESTION_TYPE): TemplateRef<any> {
    switch (questionType) {
      case QUESTION_TYPE.MultipleSelectionWithSubQuestion:
        return this.multipleSelectionWithSubQuestionQuestion

      case QUESTION_TYPE.Decimal:
        return this.decimalQuestion

      case QUESTION_TYPE.ToggleAndComment:
        return this.toggleAndCommentQuestion

      case QUESTION_TYPE.MultipleSelection:
        return this.multipleSelectionQuestion

      case QUESTION_TYPE.MultipleSelectionAndComment:
        return this.multipleSelectionAndCommentQuestion

      case QUESTION_TYPE.MultipleSelectionWithSubQuestion:
        return this.multipleSelectionWithSubQuestionQuestion

      case QUESTION_TYPE.Date:
        return this.dateQuestion

      case QUESTION_TYPE.Numeric:
        return this.numericQuestion

      case QUESTION_TYPE.Button:
        return this.buttonQuestion

      case QUESTION_TYPE.NumericAndComment:
        return this.numericAndCommentQuestion

      case QUESTION_TYPE.Text:
        return this.textQuestion

      case QUESTION_TYPE.MultilineText:
        return this.multilineTextQuestion

      case QUESTION_TYPE.Selection:
        return this.selectionQuestion

      case QUESTION_TYPE.SelectionAndComment:
        return this.selectionAndCommentQuestion

      case QUESTION_TYPE.Slider:
        return this.sliderQuestion

      case QUESTION_TYPE.Toggle:
        return this.toggleQuestion

      case QUESTION_TYPE.Radio:
        return this.radioQuestion

      case QUESTION_TYPE.Grid:
        return this.gridQuestion

      case QUESTION_TYPE.GroupLabel:
        return this.groupLabelQuestion

      case QUESTION_TYPE.Image:
        return this.imageQuestion

      default:
        throw new Error(`Unknown question type: ${questionType}`)
    }
  }

  questionButtonClick(id, obj, event, type): void {
    var indexKeys = Object.keys(this.hierarchicalQuestionnaire)
    let found = false

    if (indexKeys.length > 0) {
      for (let index = 0; index < indexKeys.length; index++) {
        let hq = this.hierarchicalQuestionnaire[index]
        if (hq.questionId.toLowerCase() == id.toLowerCase()) {
          var optKeys = Object.keys(hq.options)
          if (optKeys.length > 0) {
            for (let optIndex = 0; optIndex < optKeys.length; optIndex++) {
              if (hq.options[optIndex].discriminator.toLowerCase() == 'buttonoption') {
                if (hq.options[optIndex].attribute.toLowerCase() == 'clear') {
                  var respKeys = Object.keys(this.responseItems)

                  if (respKeys.length > 0) {
                    for (let respIndex = 0; respIndex < respKeys.length; respIndex++) {
                      if (
                        this.responseItems[respIndex].questionId.toLowerCase() ==
                        hq.options[optIndex].value.toLowerCase()
                      ) {
                        this.responseItems[respIndex].listItemId = null
                        this.responseItems[respIndex].selectionOptionsSelected = null
                        this.questionnaireFormGroup.controls[
                          this.responseItems[respIndex].questionId.toLowerCase()
                        ].setValue(null)
                        this.setResponseDropdownListItem(
                          hq.questionId.toLowerCase(),
                          null
                        )
                      }
                    }
                  }
                }

                if (hq.options[optIndex].attribute.toLowerCase() == 'makevisible') {
                  let questionId = hq.options[optIndex].value.toLowerCase().trim()
                  if (questionId.length > 0) {
                    var visKeys = Object.keys(this.hierarchicalQuestionnaire)
                    if (indexKeys.length > 0) {
                      for (let visIndex = 0; visIndex < visKeys.length; visIndex++) {
                        let subHQ = this.hierarchicalQuestionnaire[visIndex];
                        if (
                          subHQ.questionId.toLowerCase() == questionId
                        ) {
                          subHQ.isVisible = true

                          // // TODO Also make children visible
                          // for (let childIndex = 0; childIndex < keys.length; childIndex++) {
                          //   if (this.hierarchicalQuestionnaire[childIndex].groupQuestionAssociationParentId) {
                          //     if (this.hierarchicalQuestionnaire[childIndex].groupQuestionAssociationParentId.toUpperCase() === hq.groupQuestionAssociationId.toUpperCase()) {
                          //       this.hierarchicalQuestionnaire[childIndex].isVisible = true;
                          //     }
                          //   }
                          // }
                        }
                      }
                    }
                  }
                }

                if (hq.options[optIndex].attribute.toLowerCase() == 'setdropdownvalue') {
                  let stringValues = hq.options[optIndex].value
                    .split(',')
                    .map((item) => item.trim())

                  // make sure there are at least 2 values, first is the question ID, second is the list ID to select
                  if (stringValues.length >= 2) {
                    var visKeys = Object.keys(this.hierarchicalQuestionnaire)
                    if (indexKeys.length > 0) {
                      for (let visIndex = 0; visIndex < visKeys.length; visIndex++) {
                        let subHQ = this.hierarchicalQuestionnaire[visIndex];
                        if (
                          subHQ.questionId.toLowerCase() == stringValues[0].toLowerCase()
                        ) {
                          // Check if we are writing to a readonly select dropdown - if so, the select has been replaced by a textbox - then write the selection text instead of the value
                          if (
                            subHQ.questionType == 0 &&
                            subHQ.questionReadOnly
                          ) {
                            this.questionnaireFormGroup.controls[
                              subHQ.questionName
                            ].setValue(
                              this.getSelectedValueText(
                                stringValues[0].toLowerCase(),
                                stringValues[1].toLowerCase()
                              )
                            )
                          } else {
                            this.questionnaireFormGroup.controls[
                              subHQ.questionName
                            ].setValue(stringValues[1].toLowerCase())
                          }
                          this.setResponseDropdownListItem(
                            stringValues[0].toLowerCase(),
                            stringValues[1].toLowerCase()
                          )
                        }
                      }
                    }
                  }
                }
                this.onChange(obj, event, type)
              }
            }
          }
        }
      }
    }
  }

  getSelectedValueText(questionId: string, selectionId: string): string {
    if (!selectionId) {
      return '';
    }

    for (const hq of this.hierarchicalQuestionnaire) {
      if (hq.questionId.toLowerCase() === questionId.toLowerCase()) {
        for (const opt of hq.options) {
          if (opt.discriminator.toLowerCase() !== 'selectionoption') {
            continue;
          }

          for (const resp of opt.list.listItems) {
            if (resp.id.toLowerCase() === selectionId.toLowerCase()) {
              return resp.description;
            }
          }
        }
      }
    }

    return '';
  }

  fixDate(dateToFix: string | number | Date): Date {
    const d = new Date(dateToFix);
    const timeZoneDifferenceHours = -d.getTimezoneOffset() / 60; // convert to positive value.
    const timeZoneDifferenceMillisecs = timeZoneDifferenceHours * 3600000; // convert hours to milliseconds
    d.setTime(d.getTime() + timeZoneDifferenceMillisecs);
    return d;
  }

  isDate(value: number | string | Date): boolean {
    switch (typeof value) {
      case 'number':
        return !isNaN(new Date(value).getTime());
      case 'string':
        return !isNaN(Date.parse(value));
      case 'object':
        if (value instanceof Date) {
          return !isNaN(value.getTime());
        }
      default:
        return false;
    }
  }

  onGridReady(params: any, maxRows: number): void {
    const gridApi = params.api;
    const gridColumnApi = params.columnApi;
    const currentRows = params.api.rowModel.rowsToDisplay.length;
    const missingRows = maxRows - currentRows;

    if (missingRows > 0) {
      const rowsToAdd = [];

      for (let rowIndex = 0; rowIndex < missingRows; rowIndex++) {
        const row = {};

        for (const columnDef of gridColumnApi.columnModel.columnDefs) {
          row[columnDef.field] = '';
        }

        rowsToAdd.push(row);
      }

      gridApi.applyTransaction({ add: rowsToAdd });
    }

    gridApi.refreshCells();
    gridApi.sizeColumnsToFit();
  }

  callMultiplyAPI(rsaidnumber, obj: any, passportnumber) {
    this.healthyHeartScore.getMultiplyPDF(rsaidnumber, passportnumber).subscribe(data => {
      if (data != undefined) {
        if (data?.stringData.length == 8) {
          console.log("Error while fetching the data from multiply api");
          //obj.status = "Failure";
          //this.Insert_Update_HHS(obj);
          this.CallHealthyHeartScorUsingNode(rsaidnumber, passportnumber, obj);
        }
        else {
          const blob = base64ToBlob(data.stringData, 'application/octet-stream');
          // Create a Blob URL for the PDF
          const blobUrl = window.URL.createObjectURL(blob);

          // Open the PDF in a new tab
          //window.open(blobUrl, '_blank');


          // Create a temporary anchor element to trigger the download
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = 'healthy_heart_score.pdf';
          link.click();

          // Clean up the Blob URL
          window.URL.revokeObjectURL(blobUrl);
          console.log("data");
          obj.status = "Success";
          this.Insert_Update_HHS(obj);
        }
      }
      else {
        this.CallHealthyHeartScorUsingNode(rsaidnumber, passportnumber, obj);
      }
    },
      error => {
        console.log("error while fetching data using third party api", error);
        this.CallHealthyHeartScorUsingNode(rsaidnumber, passportnumber, obj);
      })

  }

  CallHealthyHeartScorUsingNode(rsaidnumber, passportNumber, obj) {
    this.healthyHeartScore.heartScore(rsaidnumber, passportNumber).subscribe(
      ({ status, body }) => {
        console.log('Multiply API Status Code:', status);
        obj.status = "Success";
        this.Insert_Update_HHS(obj)
      },
      error => {
        console.error('Error:', error);
        console.log('Multiply API Status Code:', status);
        obj.status = "Failure";
        this.Insert_Update_HHS(obj);
      }
    );
  }
  //healthy heart audit insert
  Insert_Update_HHS(obj) {
    this.healthyHeartScore.insertUpdateHHS(obj).subscribe(
      (res) => {
        console.log("Data saved successfully");
      },
      (err) => {
        console.log("Error while saving the data ", err);
      }
    )
  }

}
