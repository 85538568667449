import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { GlobalDataService } from '../../../shared/services/global-data.service';

@Component({
  selector: 'app-survey-completed',
  templateUrl: './survey-completed.component.html',
  styleUrls: ['./survey-completed.component.scss']
})
export class SurveyCompletedComponent implements OnInit {
  constructor(private globalData: GlobalDataService, private activatedRoute: ActivatedRoute, private router: Router) {

  }

  onSaved(): void {
    //this.router.navigate(['./survey-completed']);
  }

  ngOnInit(): void {

  }

}
