<!--*ngIf="ToggleForm" set form toggle after form completion-->
<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <div id="fullCalendar" *ngIf="showEvents">
    <h2 class="mdc-typography--headline4">Wellness event {{bookingStage == 'manage' ? "execution" : "management"}}</h2>
    <full-calendar [options]="calendarOptions" #calendarEl
      [customButtons]="bookingStage == 'manage' ? calendarOptions.customButtons : null" style="padding-top:10px">
    </full-calendar>
  </div>
  <div id="eventForm">
    <article class="grid">
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <mat-accordion>
            <form [formGroup]="eventFormMain" (ngSubmit)="onSubmit()">

              <h1 class="mdc-typography--headline2">Wellness event booking form</h1>
              <app-wellness-event-header [setUpTime]="eventSetUpTimes" [strikeDownTime]="eventStrikeDownTimes"
                [eventDate]="eventDates" [startTime]="eventStartTime" [endTime]="eventEndTime"
                [eventStatusId]="eventStatusId" [company]="company">
              </app-wellness-event-header>

              <mat-expansion-panel #eventPlanningPanel *ngIf="eventFormMain.get('id').value !== null"
                (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left"><strong>
                      Event planning</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div formGroupName="eventPlanning">
                  <div>
                    <div class="formgroup">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Please enter the company debtor code</mat-label>
                        <input matInput type="text" formControlName="companyDebtorCode">
                        <mat-error
                          *ngIf="eventFormMain.get('eventPlanning.companyDebtorCode').invalid && eventFormMain.get('eventPlanning.companyDebtorCode').touched">
                          Company debtor code is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please select a service provider</mat-label>
                      <mat-select formControlName="serviceProviderId" #selectedServiceProvider>
                        <mat-option *ngFor="let serviceProvider of serviceProviders"
                          [value]="serviceProvider.id">{{serviceProvider.workflowName}}</mat-option>
                      </mat-select>
                      <!-- <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.serviceProviderId').invalid && eventFormMain.get('eventPlanning.serviceProviderId').touched">
                        Service provider is required.
                      </mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Type of health practitioner required</mat-label>
                      <mat-select formControlName="healthPractitionerTypeId" multiple>
                        <mat-option *ngFor="let hpType of healthPractitionerTypes"
                          [value]="hpType.id">{{hpType.description}}</mat-option>
                      </mat-select>
                      <!-- <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.healthPractitionerTypeId').invalid && eventFormMain.get('eventPlanning.healthPractitionerTypeId').touched">
                        Type of health practitioner is required.
                      </mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Number of health practitioners required</mat-label>
                      <input matInput type="number" formControlName="numberOfHealthPractitioners"
                        placeholder="Recommended 1 health practitioner for every 30 members" min="1">
                      <!-- <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.numberOfHealthPractitioners').invalid && eventFormMain.get('eventPlanning.numberOfHealthPractitioners').touched">
                        Number of health practitioners is required.
                      </mat-error> -->
                      <!-- <mat-error *ngIf="eventFormMain.get('eventPlanning.numberOfHealthPractitioners').errors?.min">
                        Minimum number of health practitioners required is 1.
                      </mat-error> -->
                    </mat-form-field>
                  </div>

                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Marketing Campaign Date</mat-label>
                      <input matInput type="date" formControlName="marketingCampaignDate" required
                        [max]="maxMarketingCampaignDate">
                      <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.marketingCampaignDate').invalid && eventFormMain.get('eventPlanning.marketingCampaignDate').touched">
                        Marketing Campaign Date is required.
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="grid__inner grid__inner--layout-66">
                    <div class="grid__cell">
                      <div class="form-group">
                        <mat-form-field appearance="legacy"
                          class="whitneyFontClass motion-form-field unsetMarginPadding">
                          <mat-label>Set up time needed</mat-label>
                          <input matInput type="text" formControlName="setUpHours" #suHours appCustomNumberFormat
                            required min="00" max="23" class="timeDigits">
                          <span>:</span>
                          <input matInput type="text" formControlName="setUpMinutes" #suMinutes appCustomNumberFormat
                            required min="00" max="59" class="timeDigits">
                          <input type="hidden" formControlName="setUpTimes" #eventSelectedSetupTimes
                            [value]="suHours.value + ':' + suMinutes.value">
                          <!-- <mat-error
                            *ngIf="eventFormMain.get('eventPlanning.setUpTimes').invalid && eventFormMain.get('eventPlanning.setUpTimes').touched">
                            Event set up time needed is required.
                          </mat-error> -->
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="grid__cell">
                      <div class="form-group" *ngIf="eventSelectedSetupTimes.value !== null">
                        <mat-form-field appearance="legacy"
                          class="whitneyFontClass motion-form-field unsetMarginPadding">
                          <mat-label>Strike down time needed</mat-label>
                          <input matInput type="text" formControlName="strikeDownHours" #sdHours appCustomNumberFormat
                            required min="00" max="23" class="timeDigits">
                          <span>:</span>
                          <input matInput type="text" formControlName="strikeDownMinutes" #sdMinutes
                            appCustomNumberFormat required min="00" max="59" class="timeDigits">
                          <input type="hidden" formControlName="strikeDownTimes" #eventSelectedStrikeDownTimes
                            [value]="sdHours.value + ':' + sdMinutes.value">
                          <!-- <mat-error
                            *ngIf="eventFormMain.get('eventPlanning.strikeDownTimes').invalid && eventFormMain.get('eventPlanning.setUpTimes').touched">
                            Event strike down time needed is required.
                          </mat-error> -->

                        </mat-form-field>
                      </div>
                    </div>

                  </div>

                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Allocate equipment and stock to the event</mat-label>
                      <mat-select formControlName="allocatedEquipment" multiple>
                        <mat-option *ngFor="let allocated of allocatedEquipment"
                          [value]="allocated.code">{{allocated.description}}</mat-option>
                        <!-- <mat-option value="accutrendTestStrips">Accutrend Test Strips</mat-option>
                        <mat-option value="scale">Scale</mat-option>
                        <mat-option value="tabletMobileElectronicDevice">Tablet (Mobile Electronic Device)</mat-option>
                        <mat-option value="table">Table</mat-option> -->
                      </mat-select>
                      <mat-error
                        *ngIf="eventFormMain.get('eventPlanning.allocatedEquipment').invalid && eventFormMain.get('eventPlanning.allocatedEquipment').touched">
                        Please select at least one item to allocate.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #companyInformation (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left">
                    <strong>Company information</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>


                <div formGroupName="company">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter the company name</mat-label>
                      <input matInput type="text" formControlName="companyName" required>
                      <mat-error
                        *ngIf="eventFormMain.get('company.companyName').invalid && eventFormMain.get('company.companyName').touched">
                        Company name is required.
                      </mat-error>
                    </mat-form-field>

                  </div>
                  <div class="form-group">
                    <!-- <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter the company debtor code</mat-label>
                      <input matInput type="text" formControlName="companyDebtorCode" required>
                      <mat-error
                        *ngIf="eventFormMain.get('company.companyDebtorCode').invalid && eventFormMain.get('company.companyDebtorCode').touched">
                        Company debtor code is required.
                      </mat-error>
                    </mat-form-field> -->
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter account executive's first name</mat-label>
                      <input matInput type="text" formControlName="companyAccountExecutiveName">
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter account executive's surname</mat-label>
                      <input matInput type="text" formControlName="companyAccountExecutiveSurname">
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter account executive's contact number</mat-label>
                      <input matInput type="text" formControlName="companyAccountExecutiveCellphone">
                      <mat-error *ngIf="eventFormMain.get('company.companyAccountExecutiveCellphone').invalid">
                        Company account executive cellphone number is in the incorrect format.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter account executive's email address </mat-label>
                      <input matInput type="text" formControlName="companyAccountExecutiveEmail">
                      <mat-error *ngIf="eventFormMain.get('company.companyAccountExecutiveEmail').invalid">
                        Account executive's email address is in the incorrect format.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter facilities manager's first name</mat-label>
                      <input matInput type="text" formControlName="companyFacilityManagerName">
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter facilities manager's surname</mat-label>
                      <input matInput type="text" formControlName="companyFacilityManagerSurname">
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter facilities manager's contact number</mat-label>
                      <input matInput type="text" formControlName="companyFacilityManagerCellphone">
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter facilities manager's email address</mat-label>
                      <input matInput type="text" formControlName="companyFacilityManagerEmail">
                      <mat-error *ngIf="eventFormMain.get('company.companyFacilityManagerEmail').invalid">
                        Facilities manager's email address is in the incorrect format.
                      </mat-error>
                    </mat-form-field>
                    <div class="form-group" style="display: none">
                      <mat-label>Group product</mat-label>
                      <br>
                      <mat-radio-group>
                        <mat-radio-button [value]="'none'" [checked]="true">None</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="'cs'">Closed schemes</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="'faw'">FundsAtWork</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="'mh4m'">Mommentum Health4Me</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="'mms'">Momentum medical scheme</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="'mu'">Multiply</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="'mfc'">Multiply for corporates</mat-radio-button>
                        <br>
                        <mat-radio-button [value]="'my'">Myriad</mat-radio-button>
                      </mat-radio-group>
                    </div>

                  </div>
                  <div class="form-group" style="display: none;">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Acount Executive</mat-label>
                      <input matInput type="text" formControlName="accountExecutive">
                    </mat-form-field>

                  </div>
                </div>
                <!-- <div class="form-group">
                                  <mat-label>Event status: </mat-label>
                                  <mat-label></mat-label>
                              </div> -->

              </mat-expansion-panel>
              <mat-expansion-panel #eventDetailsPanel (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left">
                    <strong>Event details</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>


                <!-- <div class="form-group">
                    <mat-label>Event status: </mat-label>
                    <mat-label></mat-label>
                </div> -->
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass  motion-form-field unsetMarginPadding">
                    <mat-label>Payment options for the wellness event</mat-label>
                    <mat-select multiple formControlName="paymentOptions">
                      <mat-option *ngFor="let service of paymentOptions" [value]="service.id">
                        {{service.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Event date</mat-label>
                    <input matInput type="date" #eventDate formControlName="date" required>
                    <mat-error *ngIf="eventFormMain.get('date').invalid && eventFormMain.get('date').touched">
                      Event date is required.
                    </mat-error>
                  </mat-form-field>
                </div>

                <div formGroupName="serviceType">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please select a service type</mat-label>
                      <mat-select required formControlName="serviceTypeId">
                        <!-- <mat-option value="" selected disabled>e.g. wellness
                          events</mat-option> -->
                        <mat-option *ngFor="let service of serviceTypes"
                          [value]="service.id">{{service.description}}</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="eventFormMain.get('serviceType.serviceTypeId').invalid && eventFormMain.get('serviceType.serviceTypeId').touched">
                        Event service type is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="grid__inner grid__inner--layout-66">
                  <div class="grid__cell">
                    <div class="form-group">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Event Start Time</mat-label>
                        <input matInput type="time" formControlName="eventStartTime" required #eventSelectedStartTime
                          (input)="timeValidation(eventSelectedStartTime.value,eventFormMain.get('eventEndTime').value,eventTimeSelection)">
                        <mat-error *ngIf="eventFormMain.get('eventStartTime').invalid &&
                                                    eventFormMain.get('eventStartTime').touched">
                          Event start time is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="grid__cell">
                    <div class="form-group" *ngIf="eventFormMain.get('eventStartTime').value !== null">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Event End Time</mat-label>
                        <input matInput type="time" formControlName="eventEndTime" required #eventSelectedEndTime
                          (input)="timeValidation(eventSelectedStartTime.value,eventSelectedEndTime.value,eventTimeSelection)">
                        <mat-error *ngIf="eventFormMain.get('eventEndTime').touched">
                          Event end time is required.
                        </mat-error>
                        <mat-error *ngIf="eventFormMain.get('eventEndTime').invalid">
                          Invalid time entries entered.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>




                  <div class="grid__cell">
                    <div class="form-group">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>Shift Worker Start Time</mat-label>
                        <input matInput type="time" formControlName="shiftWorkersStartTime"
                          #shiftWorkerSelectedStartTime (input)="onShiftWorkerTimeSelection()">
                        <mat-error
                          *ngIf="(eventFormMain.get('shiftWorkersStartTime').invalid && eventFormMain.get('shiftWorkersStartTime').touched)">
                          Shift worker start time is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="grid__cell">
                    <div class="form-group" *ngIf="eventFormMain.get('shiftWorkersStartTime').value !== null">
                      <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                        <mat-label>ShiftWorker End Time</mat-label>
                        <input matInput type="time" formControlName="shiftWorkersEndTime" #shiftWorkerSelectedEndTime
                          (input)="timeValidation(shiftWorkerSelectedStartTime.value,shiftWorkerSelectedEndTime.value,shiftWorkerTimeSelection)">
                        <mat-error *ngIf="eventFormMain.get('shiftWorkersEndTime').touched">
                          Shift worker end time is required.
                        </mat-error>
                        <mat-error *ngIf="eventFormMain.get('shiftWorkersEndTime').invalid">
                          Invalid time entries entered.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <button *ngIf="eventFormMain.get('shiftWorkersStartTime').value !== null" mat-raised-button
                  class="button clear-button" (click)="clearShiftWorkerTimeEntries()">
                  Clear shift entries
                </button>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter event complete address</mat-label>
                    <textarea formControlName="address" matInput (change)="setAddressValidation('address')"
                      placeholder="e.g. 365 Redington Street Erasmusrand"></textarea>
                    <mat-error *ngIf="eventFormMain.get('address').invalid && eventFormMain.get('address').touched">
                      Address or special landmarks is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter any special landmarks</mat-label>
                    <input matInput type="text" formControlName="specialLandmarks"
                      (input)="setAddressValidation('specialLandmarks')"
                      placeholder="e.g. 500m North on N1 after Kameeldoring junction">
                    <mat-error
                      *ngIf="eventFormMain.get('specialLandmarks').invalid && eventFormMain.get('specialLandmarks').touched">
                      Address or special landmarks is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div formGroupName="employeeDetails">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of employees on site</mat-label>
                      <input type="number" matInput formControlName="amountOfEmployeesOnSite" #numberOfEmployees
                        required placeholder="e.g. 12" min="1" (input)="onInput($event)">
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').invalid && eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').touched">
                        Number of employees on site is required.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').errors?.min">
                        Minimum number of employees on site is 1.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of expected participants</mat-label>
                      <input matInput min="1" formControlName="amountOfExpectedEventParticipants" required
                        placeholder="e.g. 66" (input)="onInput($event)" type="number" #expectedParticipants
                        [max]="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').value">
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').invalid && eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').touched">
                        Number of expected participants cannot exceed the number of employees on site.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedEventParticipants').errors?.min">
                        Minimum number of expected participants is 1.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of expected scheme member participants</mat-label>
                      <input matInput formControlName="amountOfExpectedSchemeParticipants" required
                        placeholder="e.g. 66" (input)="onInput($event)" type="number" #expectedParticipants
                        [max]="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').value">
                      <!-- Add validation to ensure the addition of this field and the one below don't exceed the number of expect employees -->
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedSchemeParticipants').invalid && eventFormMain.get('employeeDetails.amountOfExpectedSchemeParticipants').touched ">
                        Number of expected participants cannot exceed the number of employees on site.
                      </mat-error>
                      <mat-error
                        *ngIf="SumBoolean && eventFormMain.get('employeeDetails.amountOfExpectedNonSchemeParticipants').touched">
                        Number of expected participants cannot exceed the number of employees on site.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedSchemeParticipants').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedSchemeParticipants').errors?.min">
                        Minimum number of expected participants is 1.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of expected non-scheme member participants</mat-label>
                      <input matInput formControlName="amountOfExpectedNonSchemeParticipants" required
                        placeholder="e.g. 66" (input)="onInput($event)" type="number" #expectedParticipants
                        [max]="eventFormMain.get('employeeDetails.amountOfEmployeesOnSite').value">
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedNonSchemeParticipants').invalid && eventFormMain.get('employeeDetails.amountOfExpectedNonSchemeParticipants').touched">
                        Number of expected participants cannot exceed the number of employees on site.
                      </mat-error>
                      <mat-error
                        *ngIf=" SumBoolean && eventFormMain.get('employeeDetails.amountOfExpectedNonSchemeParticipants').touched">
                        Number of expected participants cannot exceed the number of employees on site.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedNonSchemeParticipants').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('employeeDetails.amountOfExpectedNonSchemeParticipants').errors?.min">
                        Minimum number of expected participants is 1.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>


                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Assessment options for the wellness event</mat-label>
                    <mat-select multiple formControlName="assessments">
                      <mat-option *ngFor="let service of wellnessAssessmentOptions"
                        [value]="service.id">{{service.description}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #eventVenuePanel (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left"><strong>
                      Venue details</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please provide client venue detail</mat-label>
                    <input matInput type="text" formControlName="eventVenueDetail" placeholder="">
                  </mat-form-field>
                </div>
                <div formGroupName="eventAtmosphere">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please select a venue type</mat-label>
                      <mat-select required formControlName="atmosphereId" #selectedEventType
                        (selectionChange)="onVenueSelection(selectedEventType.value)">
                        <mat-option value="" disabled selected>e.g. Outside</mat-option>
                        <mat-option *ngFor="let type of eventTypes" [value]="type.id">{{type.description}}</mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="eventFormMain.get('eventAtmosphere.atmosphereId').invalid && eventFormMain.get('eventAtmosphere.atmosphereId').touched">
                        Event type is required.
                      </mat-error>
                      <!-- <mat-hint class="hint">Atmosphere of event venue.</mat-hint> -->
                    </mat-form-field>
                  </div>
                  <div class="form-group"
                    *ngIf="selectedEventType.value == '69d34741-ddf0-4025-b2b8-916cb017b0df' || selectedEventType.value == 'a423a72a-5552-469c-a64b-68cf9bce6e91'">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of private rooms available</mat-label>
                      <input matInput min="1" (input)="onInput($event)" formControlName="privateRoomsAvailable"
                        placeholder="e.g. 6">
                      <mat-error
                        *ngIf="eventFormMain.get('eventAtmosphere.privateRoomsAvailable').invalid && eventFormMain.get('eventAtmosphere.privateRoomsAvailable').touched">
                        Private rooms amount is required.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('eventAtmosphere.privateRoomsAvailable').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('eventAtmosphere.privateRoomsAvailable').errors?.min">
                        Minimum private rooms amount is 1.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group"
                    *ngIf="selectedEventType.value == '69d34741-ddf0-4025-b2b8-916cb017b0df' || selectedEventType.value == 'a423a72a-5552-469c-a64b-68cf9bce6e91'">
                    <mat-label>Is the venue open plan? *</mat-label>
                    <mat-radio-group formControlName="venueOpenPlan">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error
                      *ngIf="eventFormMain.get('eventAtmosphere.venueOpenPlan').invalid && invalidForm && (eventFormMain.get('eventAtmosphere.venueOpenPlan').pristine || eventFormMain.get('eventAtmosphere.venueOpenPlan').touched)">
                      Venue type is required.
                    </mat-error>
                  </div>
                  <div class="form-group"
                    *ngIf="selectedEventType.value == '9a859b37-8f61-497b-b0b0-771ea4f1fac5' || selectedEventType.value == 'a423a72a-5552-469c-a64b-68cf9bce6e91'">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter number of gazebos required</mat-label>
                      <input matInput type="number" formControlName="numberOfGazebosRequired" placeholder="e.g. 5"
                        (input)="onInput($event)">
                      <mat-error
                        *ngIf="eventFormMain.get('eventAtmosphere.numberOfGazebosRequired').invalid && eventFormMain.get('eventAtmosphere.numberOfGazebosRequired').touched">
                        Gazebos amount is required.
                      </mat-error>
                      <mat-error *ngIf="eventFormMain.get('eventAtmosphere.numberOfGazebosRequired').errors?.Special">
                        No special characters are allowed.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter any special requirements</mat-label>
                    <input matInput type="text" formControlName="specialRequirements"
                      placeholder="e.g. 100m after building ruins">
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter any building access and venue restrictions</mat-label>
                    <input matInput type="text" formControlName="venueRestrictions" placeholder="e.g. no power">
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                    <mat-label>Please enter any drop off facility details</mat-label>
                    <input matInput type="text" formControlName="dropOffFacilityDetails"
                      placeholder="e.g. Parking area">
                    <mat-error
                      *ngIf="eventFormMain.get('dropOffFacilityDetails').invalid && eventFormMain.get('dropOffFacilityDetails').touched">
                      Drop off facility details is required.
                    </mat-error>
                    <!-- <mat-hint class="hint">Example: Parking lot.</mat-hint> -->
                  </mat-form-field>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #siteCoordinatorPanel (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left">
                    <strong>Momentum wellness operations</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div formGroupName="siteCoordinator">
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter event coordinator's first name</mat-label>
                      <input matInput type="text" formControlName="siteCoordinatorFirstName" placeholder="e.g Thomas"
                        required="{{siteCoordinatorValidation}}">
                      <mat-error *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorFirstName').errors?.required">
                        event coordinator's first name is required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter event coordinator's surname</mat-label>
                      <input matInput type="text" formControlName="siteCoordinatorSurname" placeholder="e.g Johnsons"
                        required="{{siteCoordinatorValidation}}">
                      <mat-error *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorSurname').errors?.required">
                        event coordinator's surname is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group" style="display: none">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter event coordinator's telephone</mat-label>
                      <input matInput (input)="onInput($event)" formControlName="siteCoordinatorTelephone">
                      <mat-error
                        *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorTelephone').errors?.invalidCellphone">Telephone
                        number is invalid.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter event coordinator's contact number</mat-label>
                      <input matInput formControlName="siteCoordinatorCellphone"
                        required="{{siteCoordinatorValidation}}">
                      <mat-error *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorCellphone').errors?.required">
                        event coordinator's contact number is required.
                      </mat-error>
                      <mat-error
                        *ngIf="eventFormMain.get('siteCoordinator.siteCoordinatorCellphone').errors?.invalidCellphone">Contact
                        number is invalid.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label>Please enter event coordinator's email address</mat-label>
                      <input matInput type="text" formControlName="siteCoordinatorEmail"
                        required="{{siteCoordinatorValidation}}" email="{{siteCoordinatorValidation}}"
                        placeholder="e.g. example@gmail.com">
                      <mat-error *ngIf="(eventFormMain.get('siteCoordinator.siteCoordinatorEmail')).errors?.required">
                        event coordinator's email address is required.
                      </mat-error>
                      <mat-error *ngIf="(eventFormMain.get('siteCoordinator.siteCoordinatorEmail')).errors?.email">
                        Please enter valid email.
                      </mat-error>
                      <!-- <mat-hint class="hint">Only valid email addresses are accepted.</mat-hint> -->
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label> Team Leader's first name</mat-label>
                      <input matInput type="text" formControlName="teamLeaderFirstName" value="Jayshree">
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label> Team Leader's suranme</mat-label>
                      <input matInput type="text" formControlName="teamLeaderSurname" value="Pillay">
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label> Team Leader's contact number</mat-label>
                      <input matInput type="text" formControlName="teamLeaderCellphone" value="0823763502">
                      <mat-error *ngIf="eventFormMain.get('siteCoordinator.teamLeaderCellphone').invalid">
                        Team Leader's contact is required or is invalid.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                      <mat-label> Team Leader's email address</mat-label>
                      <input matInput type="text" formControlName="teamLeaderEmail"
                        value="jayshree.pillay@momentum.co.za">
                      <mat-error *ngIf="eventFormMain.get('siteCoordinator.teamLeaderEmail').invalid">
                        Team leader email incorrect or required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel #activityPanel *ngIf="eventFormMain.get('id').value !== null"
                (opened)="scrollToTop()" (closed)="scrollToTop()">
                <mat-expansion-panel-header>
                  <mat-panel-title class="mdc-list-item text--align-left"><strong>
                      Booking activity</strong>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <app-adhoc-event-activity [eventId]="eventFormMain.get('id').value"></app-adhoc-event-activity>
              </mat-expansion-panel>
              <button *ngIf="cancellable" type="button" class="button cancelButton" (click)="cancelBooking()"
                [disabled]="this.eventFormMain.dirty">
                <div>Cancel booking</div>
              </button>
              <button mat-raised-button type="button" (click)="backToCalendarAttempt()">Back</button>
              <button *ngIf="!editMode" mat-raised-button class="button" type="submit"
                [disabled]="eventFormMain.invalid">Submit</button>
              <button *ngIf="editMode" mat-raised-button class="button" type="submit"
                [disabled]="eventFormMain.invalid">Save</button>
              <mat-error *ngIf="invalidForm">Incomplete form submitted. Please fill in all required fields.</mat-error>
            </form>
          </mat-accordion>
        </div>
      </div>
    </article>
  </div>
</div>