import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

import { ClinicianService } from './shared/services/clinician.service';
import { GlobalDataService } from './shared/services/global-data.service';
import { relative } from 'path';

//TODO: Add Token service here.





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'perigon-ui';
  public currentRoute: string;
  private hideCalendarButton: boolean = false;

  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  public reviewCount: number = 10;
  public loggedInKeycloakId: string = "";
  userId: string;
  menuOpen: boolean = false;
  eapClientSurveyLink: string;
  crmSatisfactionSurveyLink: string;
  sapsWorkplaceWellnessSurveyLink: string;
  sapsWellnessDaySurveyLink: string;

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  //toggles when user press Esc and/or gray area
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {

    document.addEventListener('click', (event: MouseEvent) => {
      if ((event.target as HTMLElement).classList.contains('mdc-drawer-scrim')) {
        this.toggleMenu();
      }
    });

  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKey(event: KeyboardEvent): void {
    // Check if the Escape key is pressed and the menu is open
    if (event.key === 'Escape' && this.menuOpen) {
      this.toggleMenu();
    }
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private readonly keycloak: KeycloakService,
    private clinician: ClinicianService,
    private globalData: GlobalDataService,
    private renderer: Renderer2,
    private el: ElementRef) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.hideCalendarButton = event.url.includes('sp-landing') ||
          event.url.includes('wellness-event') ||
          event.url.includes('wellness-event-booking') ||
          event.url.includes('login') ||
          event.url.includes('signup') ||
          event.url.includes("landing");
        this.menuOpen = false;
      }
    })

    const keycloakAuth = keycloak.getKeycloakInstance();
    const refreshMinutes = 720;  // 12 Hours

    keycloakAuth.onTokenExpired = () => {
      console.log('expired ' + new Date());
      keycloakAuth.updateToken(60 * refreshMinutes).then((refreshed) => {
        if (refreshed) {
        } else {
          console.log('token not refreshed' + new Date());
        }
      }).catch(() => {
        console.error('Failed to refresh token ' + new Date());
      });
      this.globalData.userIdVal.subscribe(val => this.userId = val);
    }
  }

  generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  public async ngOnInit() {

    // TWo ways to authenticate:

    //Online: this.keycloak.isLoggedIn()
    //Token: !this.keycloak.isTokenExpired)
    //debugger;

    this.eapClientSurveyLink = '/survey/7391B8AF-438D-4823-B2F1-E0BDD8CB58E2/' + this.generateGUID();
    this.crmSatisfactionSurveyLink = '/survey/287DCF35-7B57-451F-A958-6138ADBF01F6/' + this.generateGUID();
    this.sapsWorkplaceWellnessSurveyLink = '/survey/ECD40E2E-EB25-4251-BF2D-E0058FCDE433/' + this.generateGUID();
    this.sapsWellnessDaySurveyLink = '/survey/0E695EEB-70BF-4D0A-B002-9B962EB45A3D/' + this.generateGUID();
    this.isLoggedIn = document.location.href.indexOf('/survey/') > -1 || await this.keycloak.isLoggedIn();
    //this.isLoggedIn = await this.keycloak.isLoggedIn();
    //console.log("Logged is:" + this.isLoggedIn);
    if (this.currentRoute == undefined) {
      console.log(this.currentRoute);
      this.currentRoute = this.globalData.appDefaultPath();
    }
    console.log("Route: " + this.currentRoute);
    if (this.isLoggedIn) {
      console.log('ALREADY LOGGED IN!');
      this.keycloak.loadUserProfile().then((val) => {
        this.userProfile = val;
        this.globalData.updateUserProfile(val);
        this.loggedInKeycloakId = val.id;


        this.globalData.updateUserFullName(val.firstName + " " + val.lastName);

      });
    } else {
      console.log('Logging in!');
      this.login();
    }
    // if (window.location.href.indexOf("wcg") > -1 || window.location.href.indexOf('wellness') > -1) {
    //   this.isLoggedIn = true;


    // }
    // else {
    //   this.isLoggedIn = await this.auth.isLoggedIn();
    //   //console.log("Logged is:" + this.isLoggedIn);
    //   if (this.currentRoute == undefined) {
    //     this.currentRoute = '/oh-landing'
    //   }
    //  // console.log("Route: " + this.currentRoute);
    //   if (this.isLoggedIn) {
    //     this.auth.loadUserProfile().then((val) => {
    //       this.userProfile = val;
    //       this.globalData.updateUserProfile(val);
    //     });
    //   }
    // }

  }
  //TODO: Add login + logout functions here
  public login() {
    this.keycloak.login();
    //this.isLoggedIn = true;
  }

  public logout() {
    if (window.location.origin.indexOf('localhost') > 0) {
      this.keycloak.logout(window.location.origin + this.globalData.appDefaultPath());
    }
    else {
      this.keycloak.logout(window.location.origin + "/perigon/#" + this.globalData.appDefaultPath());
    }
    this.isLoggedIn = false;
  }


  redirectToEvent() {
    let routes = null;
    ["corporate", "saps", "adhoc"].forEach(section => {
      if (this.router.url.includes(section) && !routes)
        routes = section
    })

    //this.router.getCurrentNavigation().extras.state.mode = "create";
    this.router.navigate(['/wellness-event/' + routes + '/booking'], {
      state: { mode: 'create' }, relativeTo: this.route
    });

  }

}
