// Sort the imports in order
// 1. Angular imports
// 2. Third party imports
// 3. Application imports

import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { AuthGuard } from './auth.guard'
import { CustomAuthGuard } from './auth/auth.guard'
import { SurveyCompletedComponent } from './components/adhoc/survey-completed/survey-completed.component'
import { SurveyComponent } from './components/adhoc/survey.component'
import { EapaComponent } from './components/eapa/eapa.component'
import { RegistrationComponent } from './components/eapa/registration/registration.component'
import { LandingPageComponent } from './components/landing-page/landing-page.component'
import { ClientProfileDetailComponent } from './components/oh/client-profile-detail/client-profile-detail.component'
import { ClientProfileComponent } from './components/oh/client-profile/client-profile.component'
import { CloseOutComponent } from './components/oh/close-out/close-out.component'
import { COFComponent } from './components/oh/cof/cof.component'
import { OHConsentComponent } from './components/oh/consent/consent.component'
import { OHConsultationSummaryComponent } from './components/oh/consultation-summary/consultation-summary.component'
import { EAPCloseOutComponent } from './components/oh/eap-close-out/eap-close-out.component'
import { EAPIntakeComponent } from './components/oh/eap-intake/eap-intake.component'
import { EAPComponent } from './components/oh/eap/eap.component'
import { FilteredAppointmentComponent } from './components/oh/filtered-appointment/filtered-appointment.component'
import { FinalCheckTestComponent } from './components/oh/final-check-test/final-check-test.component'
import { FinalCheckComponent } from './components/oh/final-check/final-check.component'
import { OHLandingPageComponent } from './components/oh/landing-page/landing-page.component'
import { NurseInterventionComponent } from './components/oh/nurse-intervention/nurse-intervention.component'
import { ReviewQuestionnaireComponent } from './components/oh/review-questionnaire/review-questionnaire.component'
import { ReviewVaccineComponent } from './components/oh/review-vaccine/review-vaccine.component'
import { OHReviewComponent } from './components/oh/review/review.component'
import { StepperOverviewComponent } from './components/oh/stepper-overview/stepper-overview.component'
import { VaccinesComponent } from './components/oh/vaccines/vaccines.component'
import { PreviewComponent } from './components/preview/preview.component'
import { ReportingComponent } from './components/reporting/reporting.component'
import { SPAccessManagementComponent } from './components/service-provider/access-management/access-management.component'
import { CreateAdminAssistantComponent } from './components/service-provider/create-admin-assistant/create-admin-assistant.component'
import { CreateHealthPractitionerComponent } from './components/service-provider/create-health-practitioner/create-health-practitioner.component'
import { SPLandingPageComponent } from './components/service-provider/landing-page.component/landing-page.component'
import { AdhocWellnessBookingComponent } from './components/wellness-adhoc/booking/booking.component'
import { AdhocWellnessConsentComponent } from './components/wellness-adhoc/consent/consent.component'
import { AdhocWellnessConsultationSummaryComponent } from './components/wellness-adhoc/consultation-summary/consultation-summary.component'
import { AdhocWellnessHIVConsentComponent } from './components/wellness-adhoc/hiv-consent/hiv-consent.component'
import { AdhocWellnessLandingPageComponent } from './components/wellness-adhoc/landing-page/landing-page.component'
import { AdhocWellnessRegistrationComponent } from './components/wellness-adhoc/registration/registration.component'
import { AdhocWDEEventSummaryComponent } from './components/wellness-adhoc/reports/wde-event-summary/wde-event-summary.component'
import { AdhocWellnessReviewComponent } from './components/wellness-adhoc/review/review.component'
import { CorporateWellnessBookingComponent } from './components/wellness-corporate/booking/booking.component'
import { CorporateWellnessConsentComponent } from './components/wellness-corporate/consent/consent.component'
import { CorporateWellnessConsultationSummaryComponent } from './components/wellness-corporate/consultation-summary/consultation-summary.component'
import { FinancialComponent } from './components/wellness-corporate/financial/financial.component'
import { CorporateWellnessFLUConsentComponent } from './components/wellness-corporate/flu-consent/flu-consent.component'
import { CorporateWellnessHIVConsentComponent } from './components/wellness-corporate/hiv-consent/hiv-consent.component'
import { CorporateWellnessLandingPageComponent } from './components/wellness-corporate/landing-page/landing-page.component'
import { CorporateWellnessPSAConsentComponent } from './components/wellness-corporate/psa-consent/psa-consent.component'
import { CorporateWellnessRegistrationComponent } from './components/wellness-corporate/registration/registration.component'
import { CorporateWDEEventSummaryComponent } from './components/wellness-corporate/reports/wde-event-summary/wde-event-summary.component'
import { CorporateWellnessReviewComponent } from './components/wellness-corporate/review/review.component'
import { TransationalReportComponent } from './components/wellness-corporate/transational-report/transational-report.component'
import { WellnessBookingComponent } from './components/wellness/booking/booking.component'
import { WellnessConsentComponent } from './components/wellness/consent/consent.component'
import { WellnessConsultationSummaryComponent } from './components/wellness/consultation-summary/consultation-summary.component'
import { WellnessHIVConsentComponent } from './components/wellness/hiv-consent/hiv-consent.component'
import { WellnessLandingPageComponent } from './components/wellness/landing-page/landing-page.component'
import { WellnessRegistrationComponent } from './components/wellness/registration/registration.component'
import { WDEEventSummaryComponent } from './components/wellness/reports/wde-event-summary/wde-event-summary.component'
import { WellnessReviewComponent } from './components/wellness/review/review.component'
import { ReferralComponent } from './shared/components/referral/referral.component'
import { ForgotPassowrdComponent } from './shared/components/user/forgot-passowrd/forgot-passowrd.component'
import { LoginComponent } from './shared/components/user/login/login.component'
import { SignupComponent } from './shared/components/user/signup/signup.component'
import { ROLES } from './shared/enums'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'wellness-event/corporate/landing',
    pathMatch: 'full',
  },

  {
    path: 'app-eapa',
    component: EapaComponent
  },
  {
    path: 'app-registration', component: RegistrationComponent
  },

  { path: 'appointment', component: FilteredAppointmentComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Reception', 'Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager', 'Perigon_Coordinator',], }, },
  { path: 'app-final-check-test', component: FinalCheckTestComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager'] } },
  { path: 'close-out', component: CloseOutComponent, canActivate: [CustomAuthGuard], data: { roles: 'Perigon_Generic' } },
  { path: 'client-profile', component: ClientProfileComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Reception', 'Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager', 'Perigon_Coordinator',], }, },
  { path: 'client-profile-detail', component: ClientProfileDetailComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Reception', 'Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager', 'Perigon_Coordinator',], }, },
  { path: 'cof', component: COFComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager', 'Perigon_Coordinator',], }, },
  { path: 'eap', component: EAPComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Generic'] } },
  { path: 'eap-close-out', component: EAPCloseOutComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Generic'] } },
  { path: 'eap-intake', component: EAPIntakeComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Generic'] } },
  { path: 'final-check', component: FinalCheckComponent, canActivate: [CustomAuthGuard], data: { roles: 'Perigon_Generic' } },
  { path: 'landing', component: LandingPageComponent, canActivate: [CustomAuthGuard], data: { roles: 'Perigon_Generic' } },
  { path: 'nurse-intervention', component: NurseInterventionComponent, canActivate: [CustomAuthGuard], data: { roles: 'Perigon_Generic' } },

  { path: 'referral', component: ReferralComponent, canActivate: [CustomAuthGuard], data: { roles: 'Perigon_Generic' } },
  { path: 'reports', component: ReportingComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Manager', 'Perigon_Coordinator'] } },
  { path: 'review-questionnaire', component: ReviewQuestionnaireComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager'] } },
  { path: 'review-vaccine', component: ReviewVaccineComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Generic'] } },
  { path: 'stepper-overview', component: StepperOverviewComponent, canActivate: [CustomAuthGuard], data: { roles: 'Perigon_Generic' } },
  { path: 'survey-completed', component: SurveyCompletedComponent, canActivate: [CustomAuthGuard], data: {} },
  { path: 'vaccines', component: VaccinesComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager'] } },

  { path: 'oh-consultation-summary', component: OHConsultationSummaryComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Reception', 'Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager', 'Perigon_Coordinator',], }, },
  { path: 'oh-consent', component: OHConsentComponent, canActivate: [CustomAuthGuard], data: { roles: 'Perigon_Generic' } },
  { path: 'oh-landing', component: OHLandingPageComponent, canActivate: [AuthGuard], data: { roles: 'Perigon_Generic' } },
  { path: 'oh-questionnaire', component: OHReviewComponent, canActivate: [CustomAuthGuard], data: { roles: ['Perigon_Technician', 'Perigon_Clinician', 'Perigon_Manager'] } },

  { path: 'survey/:questionnaireId/:id', component: SurveyComponent },

  { path: 'wellness-consultation-summary', component: WellnessConsultationSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
  { path: 'wellness-consent', component: WellnessConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
  { path: 'wellness-event-booking', component: WellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.COORDINATOR] } },
  { path: 'wellness-event-consultation', component: WellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
  { path: 'wellness-hiv-consent', component: WellnessHIVConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
  { path: 'wellness-landing', component: CorporateWellnessLandingPageComponent, canActivate: [AuthGuard], data: { roles: [ROLES.BASIC] } },
  { path: 'wellness-questionnaire', component: WellnessReviewComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
  { path: 'wellness-registration/:eventId/:eventTitle', component: WellnessRegistrationComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
  { path: 'wellness-event-summary', component: WDEEventSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER, ROLES.COORDINATOR, ROLES.HEALTH_PRACTITIONER, ROLES.ADMIN_ASSISTANT] }, },

  { path: 'adhoc-wellness-consultation-summary', component: AdhocWellnessConsultationSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
  { path: 'adhoc-wellness-consent', component: AdhocWellnessConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
  { path: 'adhoc-wellness-event-booking', component: AdhocWellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.COORDINATOR] } },
  { path: 'adhoc-wellness-event-consultation', component: AdhocWellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
  { path: 'adhoc-wellness-hiv-consent', component: AdhocWellnessHIVConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
  { path: 'adhoc-wellness-landing', component: AdhocWellnessLandingPageComponent, canActivate: [CustomAuthGuard], data: { roles: [ROLES.BASIC] } },
  { path: 'adhoc-wellness-questionnaire', component: AdhocWellnessReviewComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
  { path: 'adhoc-wellness-registration/:eventId/:eventTitle', component: AdhocWellnessRegistrationComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
  { path: 'adhoc-wellness-event-summary', component: AdhocWDEEventSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER, ROLES.COORDINATOR, ROLES.HEALTH_PRACTITIONER, ROLES.ADMIN_ASSISTANT] }, },

  // Defining paths with nested objects for better organization
  {
    path: 'wellness-event',
    children:
      [

        {
          path: 'corporate', children: [
            { path: 'booking', component: CorporateWellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.COORDINATOR] } },
            { path: 'execution', component: CorporateWellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER, ROLES.REGISTRAR] } },
            { path: 'consultation', component: CorporateWellnessConsultationSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
            { path: 'hiv-consent', component: CorporateWellnessHIVConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'psa-consent', component: CorporateWellnessPSAConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'flu-consent', component: CorporateWellnessFLUConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'report', component: CorporateWDEEventSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER, ROLES.COORDINATOR, ROLES.HEALTH_PRACTITIONER, ROLES.ADMIN_ASSISTANT] } },
            { path: 'transactional-report', component: TransationalReportComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER, ROLES.COORDINATOR, ROLES.HEALTH_PRACTITIONER, ROLES.ADMIN_ASSISTANT] } },
            { path: 'questionnaire', component: CorporateWellnessReviewComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'consent', component: CorporateWellnessConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'registration/:eventId/:eventTitle', component: CorporateWellnessRegistrationComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
            { path: 'financial', component: FinancialComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
            { path: 'landing', component: CorporateWellnessLandingPageComponent, canActivate: [CustomAuthGuard], data: { roles: [ROLES.BASIC] } }
          ]
        },
        {
          path: 'adhoc', children: [
            { path: 'booking', component: AdhocWellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.COORDINATOR] } },
            { path: 'execution', component: AdhocWellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER, ROLES.REGISTRAR] } },
            { path: 'consultation', component: AdhocWellnessConsultationSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
            { path: 'hiv-consent', component: AdhocWellnessHIVConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'report', component: AdhocWDEEventSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER, ROLES.COORDINATOR, ROLES.HEALTH_PRACTITIONER, ROLES.ADMIN_ASSISTANT] } },
            { path: 'questionnaire', component: AdhocWellnessReviewComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'consent', component: AdhocWellnessConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'registration/:eventId/:eventTitle', component: AdhocWellnessRegistrationComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
            { path: 'landing', component: AdhocWellnessLandingPageComponent, canActivate: [CustomAuthGuard], data: { roles: [ROLES.BASIC] } }
          ]
        },
        {
          path: 'saps', children: [
            { path: 'booking', component: WellnessBookingComponent, canActivate: [AuthGuard], data: { roles: [ROLES.COORDINATOR] } },
            { path: 'consultation', component: WellnessConsultationSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
            { path: 'hiv-consent', component: WellnessHIVConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'report', component: WDEEventSummaryComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER, ROLES.COORDINATOR, ROLES.HEALTH_PRACTITIONER, ROLES.ADMIN_ASSISTANT] } },
            { path: 'questionnaire', component: WellnessReviewComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'consent', component: WellnessConsentComponent, canActivate: [AuthGuard], data: { roles: [ROLES.CAPTURER] } },
            { path: 'registration/:eventId/:eventTitle', component: WellnessRegistrationComponent, canActivate: [AuthGuard], data: { roles: [ROLES.REGISTRAR] } },
            { path: 'landing', component: WellnessLandingPageComponent, canActivate: [CustomAuthGuard], data: { roles: [ROLES.BASIC] } }
          ]
        }
      ]
  },

  { path: 'preview', component: PreviewComponent, canActivate: [AuthGuard], data: { roles: ROLES.ADMINISTRATOR } },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPassowrdComponent },

  { path: 'sp-add-hp', component: CreateHealthPractitionerComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER] } },
  { path: 'sp-add-aa', component: CreateAdminAssistantComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER] } },
  { path: 'sp-access-management', component: SPAccessManagementComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER] } },
  { path: 'sp-landing', component: SPLandingPageComponent, canActivate: [AuthGuard], data: { roles: [ROLES.SERVICE_PROVIDER] } }

]
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

