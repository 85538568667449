import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { ArrayFunctions } from 'src/app/shared/array-functions';

import { SignatureFieldComponent } from '../../../shared/components/signature-field/signature-field.component';
import { CORPWELLNESSCONSENTSTATUS } from '../../../shared/enums';
import { WellnessConsentMapper } from '../../../shared/interfaces/consentMapper';
import { PatientDetailObject, TestRequestedObject } from '../../../shared/interfaces/consultationMapper';
import { ConsultationService } from '../../../shared/services/consultation.service';
import { GlobalDataService } from '../../../shared/services/global-data.service';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-corporate-wellness-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class CorporateWellnessConsentComponent implements OnInit {
  public form: FormGroup;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  @ViewChildren(SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;
  @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  @ViewChildren('sigContainer2') public sigContainer2: QueryList<ElementRef>;

  sig1Border = false;
  sig2Border = false;
  userId: string = null;

  descriptions: Array<string> = [
    "Health risk assessment",
    "TB questionnaire",
    "HIV Status",
    "PSA test",
    "Flu vaccine",
    "Stress questionnaire",
    "Mental Wellbeing questionnaire",
    "<b>I consent that</b><br/>my results will be disclosed to Momentum Multiply (Pty) Ltd (“Multiply”), and my associated medical scheme, my medical aid administrator, my employer and the Occupational Health staff at my employer.<br/><br/><b>I understand the purpose and benefit of such a test</b><br/>is to enable Multiply to allocate points to me that will determine my Healthy Heart Score and fitness level, and my associated medical scheme and its administrator will store the data on their database to assess my health risk. Additionally, this data will be shared with my employer and the Occupation Health staff at my employer to help in programmes to understand my health status and improve it. These entities will keep the results confidential and will not disclose results to third parties without my consent and will implement security measures against unauthorised processing by any third party.<br/><br/><b>Indemnity</b><br/>I understand that Momentum Metropolitan Holdings Limited, its directors and its employees will not accept any responsibility and shall not be liable for any injury, death, illness, loss or other damages of any nature (direct or indirect, special or consequential) suffered or incurred during or resulting from my participation in the aforementioned tests and the use of the results thereof. I have read and understood the above consent, purpose and indemnity."
  ];
  userFullName: string;
  consents = Object.keys(CORPWELLNESSCONSENTSTATUS)
    .filter(Number)
    .map((k, i) => ({
      key: k,
      label: CORPWELLNESSCONSENTSTATUS[k],
      description: this.descriptions[i],
      visible: true
    }));

  sectionOne: FormGroup;

  showValidationErrors = false;
  constructor(private fb: FormBuilder, private snackBar: MatSnackBar, private consultationService: ConsultationService, private globalData: GlobalDataService, private router: Router, private questionnaireService: QuestionnaireService, private route: ActivatedRoute) {
    this.form = fb.group({
      signatureField1: ['', Validators.required],
      signatureField2: ['', Validators.required]
    });
    this.sectionOne = this.fb.group({
      consent: this.fb.array(this.consents.map(c => this.fb.control(null)))
    });
  }

  get consentValues() {
    return this.formValueToBit(CORPWELLNESSCONSENTSTATUS, this.sectionOne.value.consent);
  }

  private formValueToBit(enumeration: object, selection: boolean[]) {
    const bits = this.enumToBitValues(enumeration);
    return selection
      .reduce((total, selected, i) => total + (selected ? bits[i] : 0), 0);
  }

  get incompleteConsents() {
    return this.consentValues !== 127;
  }

  dataConsent: boolean = false;
  get incompleteDataConsents() {
    if (this.consentValues == 128) {
      return true;
    }
  }

  private bitToFormValue(enumeration: object, bit: number) {
    const bits = this.enumToBitValues(enumeration);
    return bits.map(b => (bit & b) === b);
  }

  private enumToBitValues(enumeration: object) {
    return Object.keys(enumeration)
      .map(Number)
      .filter(Boolean);
  }
  // set the dimensions of the signature pad canvas
  public beResponsive() {
    console.log('Resizing signature pad canvas to suit container size');
    this.size(this.sigContainer1.first, this.sigs.first);
    this.size(this.sigContainer2.first, this.sigs.last);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
    sig.signaturePad.set('canvasHeight', container.nativeElement.clientHeight);
  }

  public setOptions() {
    this.sigs.first.signaturePad.set('penColor', 'rgb(255, 0, 0)');
    this.sigs.last.signaturePad.set('penColor', 'rgb(255, 255, 0)');
    this.sigs.last.signaturePad.set('backgroundColor', 'rgb(0, 0, 255)');
    this.sigs.last.signaturePad.clear();
    // clearing is needed to set the background colour
  }

  public clear() {
    this.sigs.first.clear();
    this.sigs.last.clear();
  }

  get idOrPassport() {
    if (this.patientDetail) {
      return this.patientDetail.rsaidnumber ? "ID number" : "Passport number";
    }
    return "";
  }

  signatureImg: string;
  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 250,
    canvasHeight: 200
  };

  agreeList = [
    { name: 'Yes', id: 0, value: true },
    { name: 'No', id: 1, value: false }
  ];


  isLoading = false;
  showError = false;
  clientID = '';
  userRoles: any[] = [];
  userRole = '';
  consentMapper: WellnessConsentMapper;
  testRequested: TestRequestedObject;
  testPerformed: any;
  urVal = {
    Clinician: 1,
    Technician: 2,
    Manager: 3,
    Reception: 4,
    Generic: 5
  };
  showDownload = false;
  downloadButton = null;
  href = "";
  download = "";

  patientDetail: PatientDetailObject = null;

  ngOnInit(): void {
    this.globalData.userRolesVal.subscribe(result => {
      this.userRoles = result as any[];
      this.userRole = this.userRoles.filter(r => r.indexOf("Perigon_") > -1).map(r => r.replace("Perigon_", "")).sort((a, b) => this.urVal[a] === this.urVal[b] ? 0 : (this.urVal[a] < this.urVal[b] ? -1 : 1))[0];
    });
    this.globalData.userIdVal.subscribe(result => this.userId = result);
    this.globalData.updateBackRoute('/wellness-event/corporate/consultation');
    this.globalData.selectedClientVal.subscribe(result => this.clientID = result);
    this.globalData.userFullNameVal.subscribe(result => this.userFullName = result);
    this.globalData.questionnaireClientDataVal.subscribe(tests => { this.testRequested = tests; });

    this.isLoading = true;
    this.consultationService.getConsultationDetail(this.clientID).subscribe(result => {
      console.log(result);
      this.patientDetail = result.patientDetail;

      if (result) {
        this.consentMapper = {
          member_FullName: `${(result.patientDetail.fullNames || '')} ${(result.patientDetail.surname || '')}`,
          member_IdNumber: result.patientDetail.rsaidnumber || result.patientDetail.passportNo,
          consultationDetailId: this.clientID,
          member_Signature: undefined,
          member_SignatureDate: undefined,
          examiner_Signature: undefined,
          examiner_SignatureDate: undefined,
          consents: 0,

        };
      }
      this.isLoading = false;
    });
  }

  drawComplete(sig): void {
    if (sig.includes('emp1')) {
      this.consentMapper.examiner_Signature = this.signaturePad.toDataURL();
      this.consentMapper.examiner_SignatureDate = new Date();

    } else if (sig.includes('client')) {
      this.consentMapper.member_Signature = this.signaturePad.toDataURL();
      this.consentMapper.member_SignatureDate = new Date();
    }
  }

  drawStart(): void {
    console.log('begin drawing');
  }

  clearSignature(): void {
    this.signaturePad.clear();
  }

  savePad(): void {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }

  confirmBox(sectionOne, sectionTwo) {

    this.sig1Border = false;
    this.sig2Border = false;

    this.showValidationErrors = true;

    this.checkSignature();

    let textMsg;
    let arr = ArrayFunctions.any(d => d == null, sectionOne.controls.consent.value);


    if (!arr //for any other value > 0
      && !this.sigs.first.signaturePad.isEmpty()
      && !this.sigs.last.signaturePad.isEmpty()) {

      let isSubmit = false;

      if (this.dataConsent && !this.isConsent) {
        textMsg = "Refused tests will not be included in the consultation.<br/>Refused data consent will cancel this consultation.";
        isSubmit = true;
      }
      else if (!this.isConsent) {
        textMsg = "Refused tests will not be included in the consultation.";
        isSubmit = true;
      }
      else if (this.dataConsent) {
        textMsg = "Refused data consent will cancel this consultation.";
        isSubmit = true;
      }
      else {
        textMsg = "Consent";
      }

      if (isSubmit) {
        Swal.fire({
          title: `Consent confirmation`,
          html: textMsg,
          icon: 'warning',
          iconColor: 'red',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
          preConfirm: () => {
            // This function will be executed when the OK button is clicked
            // You can perform actions here
            //console.log('OK button clicked');
            this.submit(sectionOne, sectionTwo);
            // Return a promise or value if needed
            // For example, you might return a promise here
            // return someAsyncOperation();
          }
        })
      }
      else {
        this.submit(sectionOne, sectionTwo);
        //console.log("All clear")
      }
    }
    else {
      this.showError = true;

      this.sig1Border = this.sigs.first.signaturePad.isEmpty();
      this.sig2Border = this.sigs.last.signaturePad.isEmpty();
    }
  }

  submit(sectionOne, sectionTwo): void {

    this.sig1Border = false;
    this.sig2Border = false;

    this.showValidationErrors = true;

    this.checkSignature();

    if (this.consentValues === 0 && !this.sigs.first.signaturePad.isEmpty()
      && !this.sigs.last.signaturePad.isEmpty()) {
      this.isLoading = true;

      this.savingConsentData();
      this.consentMapper.consents = 1;

      this.checkSignature();

      const clinicianId = this.userId;
      this.testPerformed = {
        active: true,
        clinicianId: clinicianId,
        completed: true,
        consultationId: this.clientID,
        createDate: new Date(),
        createName: this.userFullName,
        notes: '',
        result: this.consentMapper.consents,
        reviewId: '00000000-0000-0000-0000-000000000000',
        testMappingId: this.testRequested.testMappingId,
        testRequestedId: this.testRequested.id,
        changeDate: null,
        changeName: null,
        subTestMappingId: null
      };
      this.questionnaireService.postTestPerformed(this.testPerformed).subscribe(postResult => {
        this.testRequested.status = 3;
        this.testRequested.changeDate = new Date();
        this.testRequested.changeName = this.userFullName;
        this.questionnaireService.putTestStatus(this.testRequested).subscribe(updateStatus => {

          this.consultationService.postCorproateWellnessConsent(this.consentMapper).subscribe(postResult => {

            this.router.navigate(['../consultation'], { relativeTo: this.route });
          });
        });
      });

    } else if (!ArrayFunctions.any(d => d == null, sectionOne.controls.consent.value) //for any other value > 0
      && !this.sigs.first.signaturePad.isEmpty()
      && !this.sigs.last.signaturePad.isEmpty()) {

      this.savingConsentData();

      this.consentMapper.consents = this.consentValues;

      this.checkSignature();

      const clinicianId = this.userId;
      this.testPerformed = {
        active: true,
        clinicianId: clinicianId,
        completed: true,
        consultationId: this.clientID,
        createDate: new Date(),
        createName: this.userFullName,
        notes: '',
        result: this.consentValues,
        reviewId: '00000000-0000-0000-0000-000000000000',
        testMappingId: this.testRequested.testMappingId,
        testRequestedId: this.testRequested.id,
        changeDate: null,
        changeName: null,
        subTestMappingId: null
      };
      this.isLoading = true;
      this.questionnaireService.postTestPerformed(this.testPerformed).subscribe(postResult => {
        this.testRequested.status = 3;
        this.testRequested.changeDate = new Date();
        this.testRequested.changeName = this.userFullName;
        this.questionnaireService.putTestStatus(this.testRequested).subscribe(updateStatus => {
          console.log(updateStatus)
          this.consultationService.postCorproateWellnessConsent(this.consentMapper).subscribe(postResult => {

            this.router.navigate(['../consultation'], { relativeTo: this.route });

          });
        });
      });
    } else {
      this.showError = true;

      this.sig1Border = this.sigs.first.signaturePad.isEmpty();
      this.sig2Border = this.sigs.last.signaturePad.isEmpty();
    }
  }

  savingConsentData() {
    this.showError = false;
    let options: MatSnackBarConfig = { duration: 2500 };
    let snackBarRef = this.snackBar.open('Saving consent...', null, options);
    this.consentMapper.consultationDetailId = this.clientID;
  }

  checkSignature() {

    this.consentMapper.examiner_SignatureDate = this.consentMapper.examiner_SignatureDate || new Date();
    this.consentMapper.member_SignatureDate = this.consentMapper.member_SignatureDate || new Date();
    this.consentMapper.member_Signature = this.sigs.first.signaturePad.toDataURL();
    this.consentMapper.examiner_Signature = this.sigs.last.signaturePad.toDataURL();

  }

  downloadPdf(base64String, fileName): void {

    var data = atob(base64String);
    var asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    var blob = new Blob([asArray.buffer], { type: "application/pdf" });
    var myimgURL = URL.createObjectURL(blob);
    /// End Patch

    var link = document.createElement('a');
    link.href = myimgURL;
    link.download = fileName;
    link.click();
  }

  isConsent: boolean = true;
  updateConsent(index, value, data): void {
    this.sectionOne.controls.consent.value[index] = value;
    if (index == 7) {
      this.dataConsent = data.value ? false : true;
    }
    else {
      for (let i = 0; i <= 6; i++) {
        if (this.sectionOne.controls.consent.value[i] == false) { // Example condition to check
          this.isConsent = false;
          break; // Exit loop early if condition is met
        }
        else {
          this.isConsent = true;
        }
      }
    }
  }

  back(): void {
    this.router.navigate(['/wellness-event/adhoc/consultation']);
  }
}
