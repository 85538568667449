<div class="container">
  <article class="grid">
    <section class="grid__inner grid__inner--layout-b8b text--align-center">
      <div class="grid__cell mdc-typography--body2">
        <h2 class="mdc-typography--headline2">We appreciate your feedback</h2>
        Thank you for completing this satisfaction survey.
      </div>
    </section>
  </article>
</div>
